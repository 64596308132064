import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { ISendClientInvite } from '../invite.types';
import { isValidEmail } from '../../../../utils/validation.helpers';

interface ICreateInviteUserFormProps extends IRIFormProps<ISendClientInvite> {
  email: string;
}

export const CreateInviteUserForm = ({ email, onBack, onSubmit }: ICreateInviteUserFormProps) => {
  const validateForm = (values: ISendClientInvite) => {
    const errors: FormikErrors<ISendClientInvite> = {};
    if (!isValidEmail(values.email)) errors.email = 'Must be valid email';
    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{ email, name: '' } as ISendClientInvite}
        onSubmit={onSubmit}
        validate={validateForm}
        validateOnBlur={false}
      >
        {({ isSubmitting }: FormikProps<ISendClientInvite>) => (
          <Form action="#" method="POST" className="sm:max-w-lg mx-auto space-y-4 mt-10">
            <FormInputWithLabel name="email" placeholder="Email" type="email" label="Email" />
            <FormInputWithLabel name="name" type="text" label="Name" placeholder="Name" />
            <FormActionsRow>
              {!!onBack && <FormButton size="medium" onPress={onBack} text="Back" type="button" variant="secondary" />}
              <FormButton size="medium" loading={isSubmitting} text="Submit" />
            </FormActionsRow>
          </Form>
        )}
      </Formik>
    </>
  );
};
