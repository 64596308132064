import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { inputClassesLineHeightStr } from '../editor.const';
import { ILabelBlockData, LabelBlockTool, RenderWithLabelParams } from '../label-block';
import { ICreateTemplateBlockConfig } from '../create-template-block';
import { TITLE_BLOCK_TYPES } from './local-title-block.const';
import { determineLocalTitle } from './local-title-block.utils';

export const LOCAL_TITLE_CLASS = 'local-title';
export const LOCAL_TITLE_SUFFIX = ')';

export class LocalTitleBlockTool<
  T extends ILabelBlockData,
  U extends ICreateTemplateBlockConfig
> extends LabelBlockTool<T, U> {
  protected nestedDepth: number;
  protected currTitle: string;
  private titleId: string;

  constructor(props: BlockToolConstructorOptions<T>) {
    super({ ...props, data: { ...props.data, localTitle: props.data.localTitle ?? '' } });

    this.nestedDepth = 0;
    this.currTitle = '';
    this.titleId = 'local-title-' + this.uid;
  }

  protected renderWithLocalTitle(params?: RenderWithLabelParams): HTMLDivElement {
    this.renderWithLabel(params);
    if (!this.wrapper) this.wrapper = document.createElement('div');

    const title = document.createElement('b');
    title.id = this.titleId;
    title.classList.add(...inputClassesLineHeightStr.split(' '), 'w-full', 'max-w-4', LOCAL_TITLE_CLASS);

    this.wrapper.prepend(title);

    return this.wrapper;
  }

  /**
   * Lifecycle hooks
   */
  private getNestedDepth(element: Element, depth = 0): number {
    const parentEditor = element.parentElement?.closest('.editor-container');
    if (parentEditor) return this.getNestedDepth(parentEditor, depth + 1);
    return depth;
  }

  determineLocalTitle() {
    if (this.block?.id) {
      const blockTypes = this.api.blocks.getBlockTypes().filter(({ name }) => TITLE_BLOCK_TYPES.includes(name));
      const localTitleIndex = blockTypes.findIndex(({ id }) => this.block !== undefined && id === this.block.id);

      if (localTitleIndex > -1) {
        const titleElement = document.getElementById(this.titleId);
        if (titleElement) {
          this.nestedDepth = this.getNestedDepth(titleElement);
          const localTitle = determineLocalTitle(this.nestedDepth, localTitleIndex);

          return { blockTypes, localTitle, localTitleIndex, titleElement };
        }
      }

      return { blockTypes, localTitleIndex };
    }

    return null;
  }

  protected updateLocalTitle(updateOtherBlocks = false) {
    const result = this.determineLocalTitle();
    if (!result) {
      console.warn('Skipping local title update because block ID is not present', this.uid);
      return;
    }

    const { blockTypes, localTitle, localTitleIndex, titleElement } = result;

    if (localTitle) {
      // Skip dom update unless title changed
      const newTitle = localTitle + LOCAL_TITLE_SUFFIX;
      if (titleElement.textContent !== newTitle) {
        this.currTitle = newTitle;
        titleElement.textContent = newTitle;
      }
    }

    // Update other blocks in editor
    if (updateOtherBlocks) {
      blockTypes.forEach((block, index) => {
        if (index !== localTitleIndex) this.api.blocks.getById(block.id)?.call('updated');
      });
    }
  }

  /**
   * Called after block content added to the page
   */
  rendered() {
    this.updateLocalTitle();
  }

  /**
   * Called each time block content is updated
   */
  updated() {
    this.updateLocalTitle();
  }

  /**
   * Called after block removed from the page but before instance is deleted
   */
  removed() {
    this.updateLocalTitle(true);
  }

  /**
   * Called after block was moved
   */
  moved() {
    this.updateLocalTitle(true);
  }

  async isReady() {
    // console.log({ localTitle: this.currTitle, id: this.uid }, ` - ${this.name} is ready`, { label: this.data.label });
    return true;
  }
}
