import { useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { IRequestGroup, IRequestGroupUnauthenticatedOverview, Identifiable } from '../../../lib/types';
import { mergeCacheLists, showError } from '../../../lib/utils';
import {
  ApplyToChildren,
  ICreateRequestGroupCallParams,
  IGetRequestGroupsResults,
  IRequestGroupResult,
  ISendRequestGroupParams,
  IUpdateRequestGroupCallParams
} from './request-group.types';
import {
  CREATE_REQUEST_GROUP,
  DELETE_REQUEST_GROUP,
  DELIVER_REQUEST_GROUP,
  GET_REQUEST_GROUP,
  GET_REQUEST_GROUP_OVERVIEW,
  GET_REQUEST_GROUPS,
  REDELIVER_REQUEST_GROUP,
  RESTORE_REQUEST_GROUP,
  SELF_SERVE_REQUEST_GROUP,
  UPDATE_REQUEST_GROUP
} from './request-group.queries';
import { IArchivableProps } from '../../../lib/query.types';
import { readRequestGroupsCache, writeRequestGroupsCache } from './request-group.utils';
import toast from 'react-hot-toast';
import { activeCompanyLoadingVar } from '../companies/company.service';
import { useConfirm } from '../../_core/confirm/confirm.utils';
import { readRequestsCache, writeRequestsCache } from '../request/request.utils';

export const useLazyRequestGroup = (variables: Identifiable) => {
  const [getRequestGroup, data] = useLazyQuery<IRequestGroupResult, Identifiable>(GET_REQUEST_GROUP, {
    fetchPolicy: 'no-cache',
    variables
  });

  return { ...data, getRequestGroup };
};

export const useRequestGroup = (variables: Identifiable) => {
  const { data, ...rest } = useQuery<IRequestGroupResult, Identifiable>(GET_REQUEST_GROUP, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  return { ...rest, requestGroup: data?.requestGroup };
};

export const useRequestGroupOverview = (variables: Identifiable) => {
  const { data, ...rest } = useQuery<{ requestGroupOverview: IRequestGroupUnauthenticatedOverview }, Identifiable>(
    GET_REQUEST_GROUP_OVERVIEW,
    { fetchPolicy: 'cache-and-network', variables }
  );

  return { ...rest, requestGroup: data?.requestGroupOverview };
};

export const useRequestGroups = (variables: IArchivableProps = { includeArchived: true }) => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<IGetRequestGroupsResults, IArchivableProps>(GET_REQUEST_GROUPS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60 * 1000,
    variables
  });

  return { ...rest, requestGroups: data?.requestGroups, loading: rest.loading || activeCompanyLoading };
};

export const useCreateRequestGroup = () => {
  const [mutation, rest] = useMutation<{ createRequestGroup: IRequestGroup }>(CREATE_REQUEST_GROUP, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.createRequestGroup) {
        cache.writeQuery({
          query: GET_REQUEST_GROUP,
          data: { requestGroup: data.createRequestGroup } as IRequestGroupResult,
          overwrite: true
        });

        const requestGroups = readRequestGroupsCache({ cache });
        if (requestGroups)
          writeRequestGroupsCache({
            cache,
            requestGroups: [...requestGroups, data.createRequestGroup]
          });
      }
    }
  });

  const createRequestGroup = useCallback(
    (variables: ICreateRequestGroupCallParams) => mutation({ variables }),
    [mutation]
  );

  return { createRequestGroup, ...rest };
};

export const useUpdateRequestGroup = ({ _id }: Identifiable) => {
  const { ConfirmationDialog, confirm } = useConfirm({
    title: 'Do you want to apply this change to all requests in the bulk request?'
  });

  const [mutation, rest] = useMutation<
    { updateRequestGroup: IRequestGroup },
    IUpdateRequestGroupCallParams & ApplyToChildren & Identifiable
  >(UPDATE_REQUEST_GROUP, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateRequestGroup) {
        cache.writeQuery({
          query: GET_REQUEST_GROUP,
          data: { requestGroup: data.updateRequestGroup } as IRequestGroupResult,
          variables: { _id },
          overwrite: true
        });

        const requestGroups = readRequestGroupsCache({ cache });
        if (requestGroups)
          writeRequestGroupsCache({
            cache,
            requestGroups: mergeCacheLists(requestGroups, [data.updateRequestGroup])
          });
      }
    },
    onError: (error) => showError(`Failed to update bulk request`, error)
  });

  const updateRequestGroup = useCallback(
    (variables: IUpdateRequestGroupCallParams) => {
      if (variables.status)
        return confirm().then((applyToChildren) => mutation({ variables: { ...variables, _id, applyToChildren } }));
      else return mutation({ variables: { ...variables, _id } });
    },
    [_id, confirm, mutation]
  );

  return { updateRequestGroup, ConfirmationDialog, ...rest };
};

export const useDeleteRequestGroup = () => {
  const { ConfirmationDialog, confirm } = useConfirm({
    title: 'Do you want to apply this change to all requests in the bulk request?'
  });

  const [mutation, rest] = useMutation<{ deleteRequestGroup: IRequestGroup }, Identifiable & ApplyToChildren>(
    DELETE_REQUEST_GROUP,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data) {
          const requestGroups = readRequestGroupsCache({ cache });
          if (requestGroups)
            writeRequestGroupsCache({
              cache,
              requestGroups: mergeCacheLists(requestGroups, [data.deleteRequestGroup])
            });
        }
      }
    }
  );

  const deleteRequestGroup = useCallback(
    (variables: Identifiable) =>
      confirm().then((applyToChildren) => mutation({ variables: { ...variables, applyToChildren } })),
    [confirm, mutation]
  );

  return { deleteRequestGroup, ConfirmationDialog, ...rest };
};

export const useRestoreRequestGroup = () => {
  const { ConfirmationDialog, confirm } = useConfirm({
    title: 'Do you want to apply this change to all requests in the bulk request?'
  });

  const [mutation, rest] = useMutation<{ restoreRequestGroup: IRequestGroup }, Identifiable & ApplyToChildren>(
    RESTORE_REQUEST_GROUP,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data) {
          const requestGroups = readRequestGroupsCache({ cache });
          if (requestGroups)
            writeRequestGroupsCache({
              cache,
              requestGroups: mergeCacheLists(requestGroups, [data.restoreRequestGroup])
            });
        }
      }
    }
  );

  const restoreRequestGroup = useCallback(
    (variables: Identifiable) =>
      confirm().then((applyToChildren) => mutation({ variables: { ...variables, applyToChildren } })),
    [confirm, mutation]
  );

  return { restoreRequestGroup, ConfirmationDialog, ...rest };
};

export const useDeliverRequestGroup = () => {
  const [mutation, rest] = useMutation<{ deliverRequestGroup: IRequestGroup }, ISendRequestGroupParams>(
    DELIVER_REQUEST_GROUP,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }, { variables }) => {
        if (data?.deliverRequestGroup) {
          cache.writeQuery({
            query: GET_REQUEST_GROUP,
            data: { requestGroup: data.deliverRequestGroup } as IRequestGroupResult,
            variables: { _id: variables?._id },
            overwrite: true
          });

          const requestGroups = readRequestGroupsCache({ cache });
          if (requestGroups)
            writeRequestGroupsCache({
              cache,
              requestGroups: mergeCacheLists(requestGroups, [data.deliverRequestGroup])
            });

          const listRequestsAsStaff = readRequestsCache({ cache, requestGroup: variables?._id });
          if (listRequestsAsStaff)
            writeRequestsCache({
              cache,
              listRequestsAsStaff: {
                ...listRequestsAsStaff,
                requests: mergeCacheLists(listRequestsAsStaff?.requests ?? [], data.deliverRequestGroup.requests ?? [])
              },
              requestGroup: variables?._id
            });
        }
      },
      onCompleted: () => toast.success('Bulk request sent successfully!'),
      onError: (error) => {
        const errMsg = error.message.toLowerCase();
        if (errMsg.includes('blocks cannot be empty') || errMsg.includes('missing content'))
          toast.error('Bulk request template must have content prior to sending to contacts');
        else if (errMsg.includes('no new requests to send')) toast.error('No new contacts added to bulk request');
        else showError('Failed to send requests', error);
      }
    }
  );

  const deliverRequestGroup = useCallback(
    (variables: ISendRequestGroupParams & { assignSelf?: boolean }) => mutation({ variables }),
    [mutation]
  );

  return { deliverRequestGroup, ...rest };
};

export const useRedeliverRequestGroup = () => {
  const [mutation, rest] = useMutation<{ redeliverRequestGroup: number }, ISendRequestGroupParams>(
    REDELIVER_REQUEST_GROUP,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      onCompleted: ({ redeliverRequestGroup }) => {
        if (redeliverRequestGroup)
          toast.success(`Bulk request resent to ${redeliverRequestGroup} contacts successfully!`);
        else toast.success('No active requests found to resend');
      },
      onError: (error) => showError('Failed to resend requests', error)
    }
  );

  const redeliverRequestGroup = useCallback(
    (variables: ISendRequestGroupParams) => mutation({ variables }),
    [mutation]
  );

  return { redeliverRequestGroup, ...rest };
};

export const useSelfServeRequest = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ selfServe: boolean }>(SELF_SERVE_REQUEST_GROUP, {
    context: { serializationKey: 'MUTATION', tracked: true }
  });

  const selfServeRequest = useCallback(
    (variables: { name: string; email: string }) => mutation({ variables: { ...variables, _id } }),
    [_id, mutation]
  );

  return { selfServeRequest, ...rest };
};
