import { useCallback, useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { SendRequestMessageForm } from './send-request-message.component';
import { useSendCustomRequestMessage } from '../../request/request.service';
import { IRequest, REQUEST_NOTIFY_OPTION } from '../../../../lib/types';
import { ICloseableDialogProps } from '../../../_core/core.types';
import { useConfirm } from '../../../_core/confirm/confirm.utils';
import { ISendCustomRequestMessageFormParams } from './send-request-message-form-fields';
import { ISendCustomRequestMessageParams } from '../../request/request.types';
import { useFetchCurrUser } from '../../users/user.service';
import { showError, sleep } from '../../../../lib/utils';
import { useTokenUser } from '../../access-token/access-token.service';

interface ISendRequestMessageContainer extends Partial<ICloseableDialogProps> {
  forceSendAll?: boolean;
  isResponse?: boolean;
  onSubmit?: (_: ISendCustomRequestMessageParams) => Promise<unknown>;
  request: IRequest;
  token?: string;
  title?: string;
}

export const SendRequestMessageContainer = ({
  forceSendAll,
  isResponse,
  onSubmit,
  request,
  token = '',
  close,
  title
}: ISendRequestMessageContainer) => {
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { user } = useFetchCurrUser();
  const { tokenUser } = useTokenUser({ requestId: request._id, token });

  const { sendCustomRequestMessage } = useSendCustomRequestMessage({
    isResponse,
    requestId: request._id,
    token
  });

  const { ConfirmationDialog, confirm } = useConfirm({ title: 'Are you sure you want to send email?' });

  const handleSubmit = useCallback(
    async ({
      notify,
      groupsToggle,
      to,
      subject = "You have received a message on one of your Request's",
      ...rest
    }: ISendCustomRequestMessageFormParams) => {
      try {
        const params: ISendCustomRequestMessageParams = {
          ...rest,
          notify: notify?.map(({ value }) => value as REQUEST_NOTIFY_OPTION),
          to: groupsToggle ? undefined : to?.map(({ value }) => value),
          subject
        };

        let canClose = false;
        if (onSubmit) {
          await onSubmit(params);
          canClose = true;
        } else if (isResponse || (await confirm())) {
          await sendCustomRequestMessage(params);
          canClose = true;
        }

        if (close && canClose) {
          await sleep(250);
          close();
        }
      } catch (err) {
        showError('Failed sending message', err as Error);
      }
    },
    [close, confirm, isResponse, onSubmit, sendCustomRequestMessage]
  );

  return (
    <>
      <FormContainer
        error={submitError}
        setError={setSubmitError}
        title={title ?? (forceSendAll ? 'Customize Message for New Request' : 'Send custom message')}
      >
        <SendRequestMessageForm
          clientMode={!!token}
          defaultNotify={forceSendAll ? [REQUEST_NOTIFY_OPTION.CLIENTS, REQUEST_NOTIFY_OPTION.STAFF] : undefined}
          defaultMessage={
            token
              ? isResponse
                ? 'I have completed the request.'
                : 'Please review the latests updates on my request.'
              : forceSendAll
              ? 'You have been sent a new request'
              : 'Please review the latest updates on your request.'
          }
          forceSendAll={forceSendAll}
          isResponse={isResponse}
          request={request}
          onBack={close}
          onSubmit={handleSubmit}
          user={token ? tokenUser : user}
        />
      </FormContainer>
      <ConfirmationDialog />
    </>
  );
};
