import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { localTitleBlockExport, localTitleBlockImport } from '../_core/utils/block.utils';
import { ILabelBlockData } from '../_core/label-block';
import { BlockTitles, BlockType, inputErrorClasses } from '../_core/editor.const';
import { createAnswerInput, validateUrl } from '../_core/utils/question.utils';
import { QuestionType } from '../question/question.types';
import _ from 'lodash';
import { REQUIRED_TUNE } from '../_core/required-block';

interface LinkData extends ILabelBlockData {
  isValid: boolean;
  link: string;
  value: string;
}

export class Link extends LocalTitleBlockTool<LinkData, ICreateTemplateBlockConfig> {
  private answerId: string;
  private error: string;
  private errorId: string;

  constructor(props: BlockToolConstructorOptions<LinkData>) {
    const { data, ...rest } = props;
    const newData = {
      ...data,
      isValid: data.isValid ?? true,
      link: _.unescape(data.link),
      value: _.unescape(data.value)
    };

    super({ ...rest, data: newData });

    this.answerId = 'answer-' + this.uid;
    this.error = '';
    this.errorId = 'error-' + this.uid;

    // Configure settings
    this.settings = this.settings.filter(({ name }) => name !== REQUIRED_TUNE);
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.Link],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" /></svg>'
    };
  }

  render() {
    super.render();
    if (!this.wrapper) this.wrapper = document.createElement('div');
    this.wrapper.classList.add('flex', 'gap-4', 'w-full');

    // Render inputs
    const inputContainer = document.createElement('div');
    inputContainer.classList.add('w-full', 'flex', 'gap-4');

    const readOnly = this.api.readOnly.isEnabled || this.config.readOnly;

    if (!readOnly) {
      const label = createAnswerInput({
        disabled: this.config.disabled,
        onAnswerChange: (t) => this.onAnswerChange(t, 'value'),
        type: QuestionType.TEXTAREA,
        value: this.data.value,
        placeholder: '< Enter label >',
        prefix: 'Label'
      });
      inputContainer.appendChild(label);
    }

    const link = this.renderLink(readOnly);
    if (link) inputContainer.appendChild(link);

    this.wrapper.appendChild(inputContainer);

    // Add required UX components, if element is required
    const indicator = this.renderRequiredIndicator();
    if (indicator) this.wrapper.appendChild(indicator);

    const completedCheckbox = this.renderCompletedCheckbox();
    if (completedCheckbox) this.wrapper.appendChild(completedCheckbox);

    const errorContent = document.createElement('div');
    const errorMessage = document.createElement('span');
    errorMessage.id = this.errorId;
    errorMessage.classList.add('my-1.5', 'text-red-500', 'text-sm', 'hidden');
    errorContent.appendChild(errorMessage);

    const container = document.createElement('div');
    container.appendChild(this.renderWithLocalTitle());
    container.appendChild(errorContent);

    return renderWithBlockFocusWrapper(container);
  }

  private onAnswerChange(target: EventTarget | null, key: string) {
    if (target) {
      const targetE = target as HTMLInputElement;
      this.data[key] = targetE.value;
      if (key === 'link') this.data.filled = !!this.data[key];
      this.validate(this.data);
      if (key === 'link') this.toggleRequiredIndicator({ checked: !!this.data[key] });
    }
  }

  private renderLink(readOnly: boolean) {
    if (readOnly) {
      if (!this.data.isValid || !this.data.link) return null;

      const linkContainer = document.createElement('div');

      const anchor = document.createElement('a');
      anchor.classList.add('text-primary', 'text-sm');
      anchor.href = this.data.link;
      anchor.target = '_blank';
      anchor.text = this.data.value || this.data.link;
      linkContainer.append(anchor);

      return linkContainer;
    }

    return createAnswerInput({
      blockId: this.block?.id,
      disabled: this.config.disabled,
      id: this.answerId,
      onAnswerChange: (t) => this.onAnswerChange(t, 'link'),
      type: QuestionType.URL,
      value: this.data.link,
      placeholder: '< Enter link >',
      prefix: 'Link',
      variant: 'box'
    });
  }

  // TODO: Need to determine this on first render so we can determine if we need to show the error message after new validation is configured on client
  private toggleError() {
    const readOnly = this.api.readOnly.isEnabled || this.config.readOnly;
    if (!readOnly) {
      const answerInput = document.getElementById(this.answerId) as HTMLInputElement | HTMLTextAreaElement | null;
      const errorMessage = document.getElementById(this.errorId) as HTMLSpanElement | null;
      if (!this.data.isValid) {
        if (errorMessage) {
          errorMessage.textContent = this.error;
          errorMessage.classList.remove('hidden');
        }
        answerInput?.classList.add(...inputErrorClasses);
      } else {
        const errorMessage = document.getElementById(this.errorId);
        errorMessage?.classList.add('hidden');
        answerInput?.classList.remove(...inputErrorClasses);
      }
    }
  }

  validate({ link, ...rest }: LinkData) {
    if (!super.validate(rest)) return false;

    const validation = validateUrl(link);

    this.data.isValid = validation.result;
    if (validation.error) this.error = validation.error;
    this.toggleError();
    return true;
  }

  static get conversionConfig() {
    return { export: localTitleBlockExport, import: localTitleBlockImport };
  }
}
