import { useTemplates } from '../../../domains/template/template.service';
import { TemplateTableNestedContainer } from './template-table.nested.container';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { TEMPLATE_TYPE } from '../../../../lib/types';

interface ITemplateTableContainer extends IAdminProps, IPersonalCompanyProps {}

export const TemplateTableContainer: React.FC<ITemplateTableContainer> = (props) => {
  const { templates, loading } = useTemplates({
    types: [TEMPLATE_TYPE.BLOCK, TEMPLATE_TYPE.REQUEST, TEMPLATE_TYPE.SECTION]
  });

  return (
    <TemplateTableNestedContainer
      {...props}
      templates={templates?.filter((t) => t.company || !t.deletedAt)}
      loading={!templates || (!templates.length && loading)}
    />
  );
};
