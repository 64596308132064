import { Outlet } from 'react-router-dom';
import { DefaultPageLayout, IDefaultPageLayoutProps } from './default-page.layout';
import { windowMaxWidth } from './consts';

export const InsetLayout = ({ children, ...rest }: IDefaultPageLayoutProps) => {
  return (
    <DefaultPageLayout {...rest}>
      <div className={'mx-auto w-full flex flex-1 flex-col ' + windowMaxWidth}>
        <Outlet />
        {children}
      </div>
    </DefaultPageLayout>
  );
};
