import { IRequestType } from '../../../lib/types';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { GET_COMPANY_REQUEST_TYPES, GET_DEFAULT_REQUEST_TYPES } from './request-type.queries';
import { ICurrCompanyRequestTypesResults, IDefaultRequestTypesResults } from './request-type.types';
import { mergeCacheLists } from '../../../lib/utils';

export const updateRequestTypeInCache = ({ cache, type }: IApolloCacheParams & { type: IRequestType }) => {
  const currCompanyRequestTypes = cache.readQuery<ICurrCompanyRequestTypesResults>({
    query: GET_COMPANY_REQUEST_TYPES
  })?.companyRequestTypes;

  const currGlobalRequestTypes = cache.readQuery<IDefaultRequestTypesResults>({
    query: GET_DEFAULT_REQUEST_TYPES
  })?.defaultRequestTypes;

  if (type.company) {
    // Update organization level request type cache
    cache.writeQuery({
      query: GET_COMPANY_REQUEST_TYPES,
      data: { companyRequestTypes: mergeCacheLists(currCompanyRequestTypes ?? [], [type]) },
      overwrite: true
    });

    // Remove global request type from company request type if present, would happen on a convert to global
    if (currGlobalRequestTypes?.find((t) => t._id === type._id))
      cache.writeQuery({
        query: GET_DEFAULT_REQUEST_TYPES,
        data: { defaultRequestTypes: currGlobalRequestTypes.filter((t) => t._id !== type._id) },
        overwrite: true
      });
  } else {
    // Update global request type cache
    cache.writeQuery({
      query: GET_DEFAULT_REQUEST_TYPES,
      data: { defaultRequestTypes: mergeCacheLists(currGlobalRequestTypes ?? [], [type]) },
      overwrite: true
    });

    // Remove global request type from company request type if present, would happen on a convert to global
    if (currCompanyRequestTypes?.find((t) => t._id === type._id))
      cache.writeQuery({
        query: GET_COMPANY_REQUEST_TYPES,
        data: { companyRequestTypes: currCompanyRequestTypes.filter((t) => t._id !== type._id) },
        overwrite: true
      });
  }
};
