import { windowMaxWidth } from '../../_layouts/consts';

import './info-page.css';

export default function ContactUsPage() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      <div className={'info-page mx-auto w-full p-4 flex flex-1 flex-col m-6 bg-white rounded-2xl ' + windowMaxWidth}>
        <h1>Contact Us</h1>
        <p className="mt-4">
          Email us at: <a href="mailto:kevin@requestiq.com">Kevin@RequestIQ.com</a>
        </p>
      </div>
    </div>
  );
}
