import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import EditorJs, { OutputData } from '@editorjs/editorjs';
import { ChatBubbleLeftIcon } from '@heroicons/react/20/solid';

import {
  CreateTemplateContainer,
  ICreateTemplateContainerProps
} from '../../domains/template/create/create-template-form.container';
import { IRequest, REQUEST_NOTIFY_OPTION, REQUEST_STATUS, TEMPLATE_TYPE } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { ROUTE_PATHS } from '../../../_routes';
import { EditRequestContainer } from '../../domains/request/edit/edit-request.container';
import { RequestActionButton } from '../../domains/request/request-action-button';
import { showError } from '../../../lib/utils';
import { SendRequestMessageContainer } from '../../domains/conversation/send-request-message/send-request-message.container';
import { useSendRequest } from '../../domains/request/request.service';
import { EditorSaveResult, SaveProps } from '../../_editor/_core/types';
import { RequestHeaderText } from './request-header-text.component';
import { EditorPage } from '../editor-page.component';
import { buildDefaultSection } from '../../_editor/_core/editor.const';
import { OpenCollapseIcon } from '../../_core/icon/icon.components';
import { useConfirm } from '../../_core/confirm/confirm.utils';
import { ISectionData } from '../../_editor/section/section.types';
import { Loader } from '../../_core/loader.component';
import { checkIfCanCompleteAndCountOptional } from '../../_editor/section/section.utils';

interface IRequestPage {
  canSave: boolean;
  editorRef: React.MutableRefObject<EditorJs | undefined>;
  readOnly?: boolean;
  request: IRequest;
  token?: string;
}

export const RequestPage = ({ canSave, readOnly, request, token }: IRequestPage) => {
  const isLocked = request.status === REQUEST_STATUS.CLOSED || request.status === REQUEST_STATUS.LOCKED;
  const isSent = useMemo(() => request.status === REQUEST_STATUS.SENT, [request.status]);
  const showRespond = isSent && !!token;

  const navigate = useNavigate();
  const location = useLocation();

  const saverRef = useRef<{
    onSave: ({ dataToSave, forceFetchLatestData }?: SaveProps) => Promise<EditorSaveResult>;
  }>();

  const [afterRefreshData, setAfterRefreshData] = useState<(() => void) | null>(null);
  // const [canRefreshData, setEditRequest] = useState(false);
  const [editorData, setEditorData] = useState<OutputData | null>(null);
  const [editRequest, setEditRequest] = useState(false);
  const [isResponse, setIsResponse] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [preppingSendRequest, setPreppingSendRequest] = useState(false);
  const [showConversation, setShowConversation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [template, setTemplate] = useState<ICreateTemplateContainerProps | null>(null);
  const [toggledAllOpen, toggleAllOpen] = useState(true);

  const { ConfirmationDialog: MarkAllCompletedConfirmationDialog, confirm: confirmMarkAllCompleted } = useConfirm({
    title: 'Do you want to mark all sections as complete?',
    cancelTitle: 'No',
    confirmTitle: 'Yes'
  });

  const { ConfirmationDialog: PendingOptionalConfirmationDialog, confirm: confirmMarkOptionalComplete } = useConfirm({
    title: 'Do you want to mark all sections as complete?'
  });
  const { sendRequest, loading: sendingRequest } = useSendRequest();

  const onCreateRequestTemplate = ({ data }: EditorSaveResult) => {
    if (data) {
      setMenuOpen(true);
      setTemplate({ data, type: TEMPLATE_TYPE.REQUEST, version: data.version ?? EditorJs.version });
    } else toast.error('Failed to save request and create template');
  };

  useEffect(
    () =>
      setEditorData({
        time: request.time,
        version: request.version,
        blocks: request.blocks ?? [buildDefaultSection()]
      }),
    [request.blocks, request.time, request.version]
  );

  const onClientSubmit = useCallback(() => {
    if (saverRef.current) {
      setSubmitting(true);
      saverRef.current
        .onSave({ forceFetchLatestData: true })
        .then(async ({ data }) => {
          const showSubmitDialog = () => {
            setMenuOpen(true);
            setShowConversation(true);
            setIsResponse(true);
            setSubmitting(false);
          };

          if (
            data &&
            data.blocks.some((b) => !(b.data as ISectionData).completed) &&
            (await confirmMarkAllCompleted())
          ) {
            try {
              const uncompletedOptional = (
                await Promise.all(
                  data.blocks.map((b, i) => checkIfCanCompleteAndCountOptional({ data: b.data, uid: i.toString() }))
                )
              ).reduce((acc, curr) => acc + curr, 0);

              if (
                !uncompletedOptional ||
                (await confirmMarkOptionalComplete(`You have ${uncompletedOptional} empty fields.`))
              ) {
                setEditorData({
                  ...data,
                  blocks: data.blocks.map((b) => ({
                    ...b,
                    data: { ...b.data, completed: true } as ISectionData
                  }))
                });
                setAfterRefreshData(showSubmitDialog);
              }
            } catch (err) {
              setSubmitting(false);
            } finally {
              setSubmitting(false);
            }
          } else showSubmitDialog();
        })
        .catch((err) => {
          showError('Failed to prepare request for submitting', err as Error);
          setSubmitting(false);
        });
    }
  }, [confirmMarkAllCompleted, confirmMarkOptionalComplete]);

  if (!editorData) return <Loader />;
  return (
    <>
      <EditorPage
        canQuickCreate
        afterRefreshData={afterRefreshData}
        canSave={canSave}
        data={editorData}
        disabled={isLocked || !!request.deletedAt}
        isClosed={isLocked}
        isSent={!!request.sentAt}
        menuOpen={menuOpen}
        onBack={() =>
          navigate(location.state && 'backPath' in location.state ? location.state.backPath : ROUTE_PATHS.DASHBOARD)
        }
        onEdit={() => {
          setEditRequest(true);
          setMenuOpen(true);
        }}
        saverRef={saverRef}
        readOnly={readOnly}
        request={request}
        title={request.name ?? ''}
        token={token}
        TitleActions={() => (
          <div className="flex items-center gap-4">
            {request.clientFinishedWorking ? (
              <span className="text-success">Submitted</span>
            ) : token ? (
              <Button hideEndMargin slim variant="success" loading={submitting} onClick={onClientSubmit}>
                Submit
              </Button>
            ) : null}
            <Button
              hideEndMargin
              containerClass="h-7"
              icon={<OpenCollapseIcon open={toggledAllOpen} />}
              variant="secondary"
              onClick={() => {
                document
                  .querySelectorAll(`#editorjs .open-${toggledAllOpen} button`)
                  .forEach((b) => (b as HTMLButtonElement).click());

                toggleAllOpen(!toggledAllOpen);
              }}
            />
          </div>
        )}
        TitleComponent={
          <>
            <RequestHeaderText>
              TO:{' '}
              {request.assignedTo?.name || request.assignedTo?.user.name
                ? (request.assignedTo?.name ?? request.assignedTo?.user.name) + ' '
                : ''}
              {request.assignedTo ? `(${request.assignedTo?.user.email})` : 'None'}
            </RequestHeaderText>
            {!!request.entity && <RequestHeaderText>{request.entity.name}</RequestHeaderText>}
          </>
        }
        ActionButtons={({ afterClear, hasChanged, isReady, onSave, onTogglePreview }) => (
          <>
            {showRespond && (
              <div className="inline-block">
                <Button
                  slim
                  hideEndMargin
                  icon={<ChatBubbleLeftIcon width={24} height={24} className="mr-2" />}
                  variant="secondary"
                  onClick={() => {
                    setMenuOpen(true);
                    setShowConversation(true);
                  }}
                  text="Respond"
                />
              </div>
            )}
            {!readOnly && (
              <>
                <div className={`w-36 inline-block ${showRespond ? 'ml-4' : ''}`}>
                  <RequestActionButton
                    slim
                    disabled={!isReady}
                    loading={sendingRequest}
                    hideViewAction
                    reloadPageState
                    request={request}
                    togglePreview={onTogglePreview}
                    onClearRequest={afterClear}
                    onCreateTemplate={() =>
                      onSave({ forceFetchLatestData: true })
                        .then(onCreateRequestTemplate)
                        .catch((err) => showError('Failed to save request', err))
                    }
                    onSave={onSave}
                    onSendRequest={() => {
                      setMenuOpen(true);
                      setPreppingSendRequest(true);
                      setShowConversation(true);
                    }}
                    onResendRequest={() => {
                      setMenuOpen(true);
                      setShowConversation(true);
                    }}
                    hasChanged={hasChanged}
                  />
                </div>
              </>
            )}
          </>
        )}
      />
      {!!template && (
        <RIDialog
          setOpen={(o) => {
            setTemplate((v) => (o ? v : null));
            if (!o) setMenuOpen(false);
          }}
        >
          <CreateTemplateContainer
            close={() => {
              setTemplate(null);
              setMenuOpen(false);
            }}
            {...template}
          />
        </RIDialog>
      )}
      {!!editRequest && (
        <RIDialog
          setOpen={(o) => {
            setEditRequest(o);
            if (!o) setMenuOpen(false);
          }}
        >
          <EditRequestContainer
            close={() => {
              setEditRequest(false);
              setMenuOpen(false);
            }}
            request={request}
          />
        </RIDialog>
      )}
      {!!showConversation && (
        <RIDialog
          setOpen={(o) => {
            setShowConversation(o);
            if (!o) setMenuOpen(false);
          }}
        >
          <SendRequestMessageContainer
            close={() => {
              setPreppingSendRequest(false);
              setShowConversation(false);
              setMenuOpen(false);
              setIsResponse(false);
            }}
            isResponse={isResponse}
            request={request}
            token={token}
            forceSendAll={preppingSendRequest}
            onSubmit={
              preppingSendRequest
                ? ({ message, notify = [REQUEST_NOTIFY_OPTION.ALL], replyTo, subject }) =>
                    sendRequest({ _id: request._id, customMessage: message, replyTo, notify, subject })
                : undefined
            }
            title={token ? 'Submit Message' : undefined}
          />
        </RIDialog>
      )}
      <MarkAllCompletedConfirmationDialog />
      <PendingOptionalConfirmationDialog />
    </>
  );
};
