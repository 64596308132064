import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { InviteType } from '../get-by-code/get-invite-by-code-form.types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { IInvite, IUser, ROLE_LABELS } from '../../../../lib/types';
import { sortRoles } from '../../../../utils/role.helpers';
import { Loader } from '../../../_core/loader.component';

export interface IAcceptInviteFormValues {
  code: string;
  type: InviteType;
}

interface IAcceptInviteFormProps extends IRIFormProps<IAcceptInviteFormValues> {
  code?: string;
  invite?: IInvite;
  isWrongUser: boolean;
  type?: InviteType;
  user?: IUser;
}

export const AcceptInviteForm = ({
  code = '',
  type = InviteType.STAFF,
  invite,
  isWrongUser,
  onSubmit,
  onBack,
  user
}: IAcceptInviteFormProps) => {
  const validateForm = (values: IAcceptInviteFormValues) => {
    const errors: FormikErrors<IAcceptInviteFormValues> = {};

    if (!values.code.trim()) errors.code = 'Required';
    if (!values.type) errors.type = 'Required';

    return errors;
  };

  if (!invite)
    return (
      <div className="flex justify-center">
        <Loader />
      </div>
    );

  return (
    <Formik
      initialValues={{ code, type }}
      onSubmit={(values) => onSubmit({ code: values.code.trim(), type: values.type })}
      validate={validateForm}
    >
      {(props: FormikProps<IAcceptInviteFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          {type === InviteType.STAFF && !!invite && (
            <div>
              <p className="font-light text-sm mb-4">You have been invited to join an organization.</p>
              <p className="font-light text-sm">
                <b>Name:</b> {invite.company.name}
              </p>
              <p className="font-light text-sm">
                <b>Role:</b> {ROLE_LABELS[invite.roles.sort(sortRoles)[0]]}
              </p>
              <p className="font-light text-sm">
                <b>Email:</b> {invite.email}
              </p>
            </div>
          )}
          {!code && <FormInputWithLabel name="code" label="Invite Code" />}
          {/* <FormInputWithLabel 
            name="type"
            label="Invite Type" 
            type="select"
            selectProps={{ options: [{ value: InviteType.STAFF, label: 'Team Member' }, { value: InviteType.CLIENT }] }}
          /> */}
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
            <FormButton
              loading={props.isSubmitting}
              text={isWrongUser ? 'Switch User and Accept' : user ? 'Accept' : 'Login and Accept'}
            />
          </FormActionsRow>
          {isWrongUser && (
            <>
              <p className="mt-4 text-red-500 text-sm">Unable to accept invite as {user?.email}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
