import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { IGetUserInviteCodeProps } from '../user-invite-code.types';
import { isValidEmail } from '../../../../utils/validation.helpers';

export const FetchUserInviteCodeForm = ({ onBack, onSubmit }: IRIFormProps<IGetUserInviteCodeProps>) => {
  const validateForm = (values: IGetUserInviteCodeProps) => {
    const errors: FormikErrors<IGetUserInviteCodeProps> = {};

    if (!values.code.trim()) errors.code = 'Required';
    if (!values.email) errors.email = 'Required';
    if (!isValidEmail(values.email)) errors.email = 'Must enter valid email';

    return errors;
  };

  return (
    <Formik
      initialValues={{ code: '', email: '' }}
      onSubmit={({ code, email }) => onSubmit({ code: code.trim(), email: email.trim() })}
      validate={validateForm}
    >
      {(props: FormikProps<IGetUserInviteCodeProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel name="email" label="Email" placeholder="Enter email..." />
          <FormInputWithLabel name="code" label="Code" placeholder="Enter code..." />
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Cancel" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text="Submit" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
