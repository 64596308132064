import { useCallback, useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { IRequestGroupOverview, REQUEST_NOTIFY_OPTION } from '../../../../lib/types';
import { ICloseableDialogProps } from '../../../_core/core.types';
import { useFetchCurrUser } from '../../users/user.service';
import { showError, sleep } from '../../../../lib/utils';
import { ISendCustomRequestGroupMessageFormParams } from './send-request-group-message-form-fields';
import { SendRequestGroupMessageForm } from './send-request-group-message.component';
import { ISendCustomRequestGroupMessageParams } from '../../request/request.types';

interface ISendRequestGroupMessageContainer extends Partial<ICloseableDialogProps> {
  forceSendAll?: boolean;
  onSubmit?: (_: ISendCustomRequestGroupMessageParams) => Promise<unknown>;
  group: IRequestGroupOverview;
  token?: string;
}

export const SendRequestGroupMessageContainer = ({
  forceSendAll,
  onSubmit,
  group,
  token = '',
  close
}: ISendRequestGroupMessageContainer) => {
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { user } = useFetchCurrUser();

  const handleSubmit = useCallback(
    async ({
      subject = "You have received a message on one of your Request's",
      ...rest
    }: ISendCustomRequestGroupMessageFormParams) => {
      try {
        const params: ISendCustomRequestGroupMessageParams = {
          ...rest,
          notify: [REQUEST_NOTIFY_OPTION.ALL],
          subject
        };

        let canClose = false;
        if (onSubmit) {
          await onSubmit(params);
          canClose = true;
        }

        if (close && canClose) {
          await sleep(250);
          close();
        }
      } catch (err) {
        showError('Failed sending message', err as Error);
      }
    },
    [close, onSubmit]
  );

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      title={forceSendAll ? 'Customize Message for New Bulk Request' : 'Send custom message to active requests'}
    >
      <SendRequestGroupMessageForm
        clientMode={!!token}
        defaultNotify={forceSendAll ? [REQUEST_NOTIFY_OPTION.CLIENTS, REQUEST_NOTIFY_OPTION.STAFF] : undefined}
        defaultMessage={
          forceSendAll ? 'You have been sent a new request' : 'Please review the latest updates on your request.'
        }
        forceSendAll={forceSendAll}
        group={group}
        onBack={close}
        onSubmit={handleSubmit}
        user={user}
      />
    </FormContainer>
  );
};
