import { EditMemberForm } from './edit-member-form.component';
import { IMemberOverview, Nameable } from '../../../../lib/types';
import { useUpdateMember } from '../member.service';
import { logError, sleep } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { ICloseableDialogProps } from '../../../_core/core.types';

export interface IEditMemberContainerProps {
  member: IMemberOverview;
}

interface IEditMemberNestedContainerProps
  extends IEditMemberContainerProps,
    IFormWithStepperProps,
    ICloseableDialogProps {}

export const EditMemberNestedContainer = ({
  close,
  member,
  onError,
  onSuccess,
  ...rest
}: IEditMemberNestedContainerProps) => {
  const { updateMember } = useUpdateMember(member);

  const handleSubmit = async (values: Nameable) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      await updateMember(values);

      onSuccess?.('Successfully edited member.');
      sleep(250).then(close);
    } catch (err) {
      const errMsg = 'Unable to edit member.';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }
  };

  return <EditMemberForm member={member} onSubmit={handleSubmit} {...rest} />;
};
