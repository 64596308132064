import React, { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import EditorJs, { API, BlockMutationEvent, OutputData } from '@editorjs/editorjs';
import DragDrop from 'editorjs-drag-drop';
import { ICreateTemplateBlockConfig, ICreateBlockTemplateParams } from '../../_editor/_core/create-template-block';

import { FormEditorType, IEditorRefProps } from './form-editor.types';
import { useFocusedBlockTracking } from '../../_editor/_core/utils/editor.utils';

import './form-editor.css';
import '../../_editor/section/section.css';
import { getEditorTools } from './form-editor.utils';
import { Button } from '../../_core/button/button.component';
import { PlusIcon } from '@heroicons/react/20/solid';
import { usePrev } from '../../../lib/utils';
import _, { isNaN } from 'lodash';
import { ISectionData } from '../../_editor/section/section.types';
import { EditorSaveResult, SaveProps } from '../../_editor/_core/types';
import { BlockType } from '../../_editor/_core/editor.const';
import { SectionButtons } from '../../_editor/section/section-buttons';

interface IEditor extends IEditorRefProps {
  activeSection?: number | null;
  canQuickCreate?: boolean;
  canRefreshData?: boolean;
  canShowEditor?: boolean;
  data: OutputData;
  editorDestroyed?: boolean;
  onChange?: (api: API, event: BlockMutationEvent | BlockMutationEvent[]) => void;
  onCreateTemplate?: (_: ICreateBlockTemplateParams) => void;
  onFail?: (_: Error) => void;
  onReady?: () => void;
  onSave: (_?: SaveProps) => Promise<EditorSaveResult>;
  openFirstSection?: boolean; // Open first uncompleted section on first load
  disabled?: boolean;
  nested?: boolean;
  previewing?: boolean;
  readOnly: boolean;
  requestId?: string;
  requestClosed?: boolean;
  requestSent?: boolean;
  setCanRefreshData?: (_: boolean) => void;
  setEditorDestroyed?: Dispatch<SetStateAction<boolean>>;
  setIsReady?: Dispatch<SetStateAction<boolean>>;
  type?: FormEditorType;
  token?: string;
  hidden?: boolean;
}

const Editor: React.FC<IEditor> = ({
  activeSection,
  canQuickCreate,
  canRefreshData,
  canShowEditor = true,
  data,
  editorDestroyed,
  editorRef,
  hidden,
  onChange,
  onCreateTemplate,
  onFail,
  onReady,
  onSave,
  openFirstSection,
  editorblock,
  disabled,
  nested,
  previewing,
  setEditorDestroyed,
  setIsReady,
  readOnly,
  requestId,
  requestClosed,
  requestSent,
  setCanRefreshData,
  type,
  token
}) => {
  // TODO: Why is this re-rendering despite memoized and containing component having no state or non-callbacks? need to troubleshoot with why-did-you-render
  // console.log('Why re-render?');

  const [preparingEditor, setPreparingEditor] = useState(false);

  const prevActiveSection = usePrev(activeSection);
  const prevData = usePrev(data);
  const prevDisabled = usePrev(disabled);
  const prevReadOnly = usePrev(readOnly);

  useFocusedBlockTracking({ editorblock, editorRef });

  const activeSectionData = useMemo(
    () =>
      activeSection !== undefined && activeSection !== null ? (data.blocks[activeSection].data as ISectionData) : null,
    [activeSection, data]
  );

  const activeSectionOutputData = useMemo(() => activeSectionData?.outputData ?? data, [activeSectionData, data]);

  const canCompleteActiveSection = useMemo(
    () => !isNaN(activeSection) && !!requestSent && !requestClosed,
    [activeSection, requestClosed, requestSent]
  );

  const blocksWithOpenSections = useMemo(() => {
    // NOTE: Determine which section should be opened first for client view based on first incomplete section
    let markedOpen = false;

    const newBlocks = activeSectionOutputData.blocks?.map(({ data: blockData, type, ...block }, blockIndex) => {
      const updatedData = { ...blockData, requestSent, requestClosed };
      if (type === 'section') {
        updatedData.blockIndex = blockIndex;
        updatedData.totalBlocks = activeSectionOutputData.blocks?.length ?? 0;
        updatedData.requestClosed = requestClosed;
        updatedData.requestSent = requestSent;
      }

      if (type !== 'section' || markedOpen) return { data: updatedData, type, ...block };

      const forceOpen = !openFirstSection || !(updatedData as ISectionData).completed;
      if (openFirstSection && forceOpen) markedOpen = true;
      return { ...block, type, data: { ...updatedData, forceOpen } };
    });
    return newBlocks;
  }, [activeSectionOutputData.blocks, openFirstSection, requestClosed, requestSent]);

  // Quickly insert new blocks into the document
  const onQuickCreate = useCallback(
    ({
      count = 1,
      currEditor = editorRef.current,
      useCurrentBlock
    }: {
      count: number;
      currEditor?: EditorJs | null;
      useCurrentBlock?: boolean;
    }) => {
      if (currEditor) {
        for (let i = 0; i < count; i++) {
          const blockCount = currEditor.blocks.getBlocksCount();
          const targetBlockIndex = useCurrentBlock ? currEditor.blocks.getCurrentBlockIndex() : blockCount - 1;
          const targetBlock = currEditor.blocks.getBlockByIndex(targetBlockIndex);

          // If there are no blocks or last block is empty, then prompt user with block menu
          if (blockCount <= 1 && targetBlock?.isEmpty) {
            currEditor.focus(true);
            currEditor.toolbar.toggleToolbox(true);
          }
          // Otherwise create a new empty block matching the last one in the editor
          else {
            const newBlockType = useCurrentBlock
              ? type === 'base'
                ? BlockType.Section
                : BlockType.Question
              : targetBlock?.name;

            currEditor.blocks.insert(
              newBlockType,
              {
                totalBlocks: blockCount + 1,
                blockIndex: useCurrentBlock ? targetBlockIndex : blockCount,
                requestClosed,
                requestSent
              },
              useCurrentBlock ? undefined : targetBlock?.config,
              useCurrentBlock ? targetBlockIndex + 1 : blockCount
            );
          }
        }

        currEditor.toolbar.close();
      }
    },
    [editorRef, requestClosed, requestSent, type]
  );

  const disabledChanged = useMemo(
    () => prevDisabled !== undefined && prevDisabled !== disabled,
    [disabled, prevDisabled]
  );

  const readOnlyChanged = useMemo(
    () => prevReadOnly !== undefined && prevReadOnly !== readOnly,
    [prevReadOnly, readOnly]
  );

  const dataChanged = useMemo(
    () => prevData !== undefined && !_.isEqual(prevData, data) && canRefreshData,
    [canRefreshData, data, prevData]
  );

  const activeSectionChanged = useMemo(
    () => prevActiveSection !== undefined && prevActiveSection !== activeSection,
    [activeSection, prevActiveSection]
  );

  const editorShouldBeUpdatedForNewProps = useMemo(
    () => disabledChanged || readOnlyChanged || dataChanged || activeSectionChanged,
    [activeSectionChanged, dataChanged, disabledChanged, readOnlyChanged]
  );

  // Allow creating editor if the editor isn't in the process of being initialized and is allowed to be shown:
  // IF editor hasn't been initialized yet, or new props require an updated editor object, or if editor has been destroyed previously (and is prod?)
  const canCreateEditor = useMemo(() => {
    const canCreate =
      !preparingEditor &&
      !!canShowEditor &&
      ((!!editorDestroyed && import.meta.env.VITE_ENV !== 'dev') || // Only check destroyed editor in prod as this causes unneccesary re-renders in dev
        !editorRef.current ||
        editorShouldBeUpdatedForNewProps);

    return canCreate;
  }, [canShowEditor, editorDestroyed, editorRef, editorShouldBeUpdatedForNewProps, preparingEditor]);

  useMemo(() => {
    // Destroy editor and re-initialize when disabled status changes, so that editor components are re-initialized with disabled config values

    const destroyEditor = () => {
      if (editorRef.current?.destroy && !editorDestroyed) {
        editorRef.current.destroy();
        editorRef.current = null;
        setEditorDestroyed?.(true);
        setIsReady?.(false);
      }
    };

    if (editorRef.current && canShowEditor === false && !preparingEditor) destroyEditor();
    else if (canCreateEditor) {
      if (canRefreshData && setCanRefreshData) setCanRefreshData(false);
      if (editorRef.current?.destroy) destroyEditor();
      else {
        setPreparingEditor(true);
        const config: ICreateTemplateBlockConfig = {
          disabled: !!disabled,
          onCreateTemplate,
          onSave,
          readOnly,
          requestId
        };

        const editor = new EditorJs({
          holder: editorblock,
          tools: getEditorTools(config, type, token),
          defaultBlock: 'paragraph',
          readOnly: readOnly || previewing || disabled,
          data: { ...activeSectionData, blocks: blocksWithOpenSections },
          onChange,
          onFail,
          readyTimeout: 120000,
          onReady: () => {
            new DragDrop(editor);
            onReady?.();

            editorRef.current = editor;
            setPreparingEditor(false);

            // Recommended method for removing tooltips @link: https://github.com/codex-team/editor.js/issues/1436.
            // Not great though, TODO: we should add a built in method for disabling tooltips on editor
            const tooltips = document.querySelectorAll('.ce-toolbar__plus, .ce-toolbar__settings-btn');
            tooltips.forEach((tooltip) => {
              tooltip.addEventListener('mouseenter', (event) => event.stopImmediatePropagation(), true);
              tooltip.addEventListener('mouseleave', (event) => event.stopImmediatePropagation(), true);
            });
          },
          minHeight: readOnly || previewing || disabled ? 0 : 5,
          placeholder: 'Add content here!',
          onQuickCreate: (count) => onQuickCreate({ count, currEditor: this, useCurrentBlock: true })
        });
        editorRef.current = editor;
      }
    }

    //Add a return function to handle cleanup
    return () => destroyEditor();
  }, [
    editorblock,
    canShowEditor,
    preparingEditor,
    canCreateEditor,
    editorRef,
    editorDestroyed,
    setEditorDestroyed,
    setIsReady,
    canRefreshData,
    setCanRefreshData,
    disabled,
    onCreateTemplate,
    onSave,
    readOnly,
    requestId,
    activeSectionData,
    blocksWithOpenSections,
    type,
    token,
    previewing,
    onChange,
    onFail,
    onReady,
    onQuickCreate
  ]);

  const quickCreateIconSize = nested ? 20 : 24;

  return (
    <div className={`${nested ? undefined : 'p-2'} ${hidden ? 'invisible' : ''}`}>
      <div
        id={editorblock}
        className={`editor-container ml-auto ${nested ? '' : 'prose'} ${readOnly ? 'read-only' : ''}`}
      />
      {canCompleteActiveSection && activeSection !== undefined && activeSection !== null && (
        <SectionButtons
          canComplete={canCompleteActiveSection}
          completed={!!(activeSectionData as unknown as ISectionData).completed}
          getData={async (props) => {
            const result = await onSave(props);
            return result.data?.blocks[activeSection].data as unknown as ISectionData;
          }}
          toggleCompleted={(completed) => {
            const updatedData = { ...data };
            updatedData.blocks[activeSection].data.completed = completed;
            onSave({ dataToSave: updatedData });
          }}
          nextBlock={null}
          prevBlock={null}
        />
      )}
      {!disabled && !readOnly && !previewing && !!canQuickCreate && (
        <Button
          icon={
            <PlusIcon
              height={quickCreateIconSize}
              width={quickCreateIconSize}
              className="my-auto rounded-md bg-green-400 hover:bg-green-300 text-white mr-auto"
            />
          }
          className={`ce-toolbar__button !p-0 !text-green-400 hover:bg-opacity-60 max-w-32 !w-full ${
            nested ? 'text-sm ml-1' : ' ml-0.5'
          }`}
          onClick={() => onQuickCreate({ count: 1 })}
          variant="custom"
        />
      )}
    </div>
  );
};

export const FormEditor = memo(Editor);
