import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IMemberOverview, Nameable } from '../../../../lib/types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';

interface IEditMemberFormFieldProps {
  name: string;
}

interface IEditMemberFormProps extends IRIFormProps<Nameable> {
  member: IMemberOverview;
}

export const EditMemberForm = ({ member, onBack, onSubmit }: IEditMemberFormProps) => {
  return (
    <Formik
      initialValues={{ name: member.name ?? member.user.name ?? '' }}
      onSubmit={({ name }) => onSubmit({ name: name.trim() })}
    >
      {(props: FormikProps<IEditMemberFormFieldProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel name="name" label="Name" placeholder="Edit name" />
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Cancel" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text="Update" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
