import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

export const RichTextEditor = ({
  className,
  initialValue,
  onChange,
  readOnly
}: {
  className?: string;
  initialValue?: string;
  onChange: (_: string) => void;
  readOnly: boolean;
}) => {
  const [value, setValue] = useState(initialValue ?? '');

  const handleChange = (newValue: string) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <ReactQuill
      className={className}
      theme={'bubble'}
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme

          ['blockquote', 'code-block'],
          ['link', 'formula'], // TODO: Add ['image', 'video'] options here. It works in the editor, but we need a way to save this data to the database without including image directly in file.

          [{ align: [] }, { indent: '-1' }, { indent: '+1' }],
          [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
          [{ script: 'sub' }, { script: 'super' }],

          ['clean'] // remove formatting button
        ]
      }}
      value={value}
      onChange={handleChange}
      readOnly={readOnly}
    />
  );
};
