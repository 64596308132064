import { useCallback, useEffect, useState } from 'react';
import { Button } from '../../_core/button/button.component';
import { ISectionData } from './section.types';
import { useConfirm } from '../../_core/confirm/confirm.utils';
import { SaveProps } from '../_core/types';
import { requestPollingPausedVar } from '../../domains/request/request.service';
import { checkIfCanComplete } from './section.utils';

interface ISectionButtonsProps {
  canComplete: boolean;
  completed: boolean;
  getData: (_?: SaveProps) => Promise<ISectionData | null>;
  prevBlock: number | null;
  nextBlock: number | null;
  navigateToBlock?: (_: number) => void;
  toggleCompleted: (_: boolean) => void;
  uid?: string;
}

export const SectionButtons = ({
  canComplete,
  getData,
  prevBlock,
  nextBlock,
  navigateToBlock,
  toggleCompleted,
  uid,
  ...rest
}: ISectionButtonsProps) => {
  const [completed, setCompleted] = useState(rest.completed);
  const [completing, setCompleting] = useState(false);

  useEffect(() => setCompleted(rest.completed), [rest.completed]);

  const { ConfirmationDialog, confirm } = useConfirm({ title: 'Are you sure everything is complete?' });

  const onToggleComplete = useCallback(() => {
    setCompleting(true);
    getData({ forceFetchLatestData: true })
      .then(async (latestData) => {
        if (!latestData) return null;

        if (completed) {
          toggleCompleted(false);
          setCompleted(false);
        } else {
          if (await checkIfCanComplete({ confirm, data: latestData, uid })) {
            toggleCompleted(true);
            setCompleted(true);
            // if (nextBlock) navigateToBlock(nextBlock);
          }
        }

        return;
      })
      .finally(() => {
        setCompleting(false);
        requestPollingPausedVar(false);
      });
  }, [completed, confirm, getData, toggleCompleted, uid]);

  return (
    <>
      <div className="w-1/2 flex justify-around mx-auto py-2">
        {prevBlock !== null ? (
          <Button text="Prev" variant="secondary" size="medium" onClick={() => navigateToBlock?.(prevBlock ?? 0)} />
        ) : (
          <div className="w-28" />
        )}
        {canComplete ? (
          <Button
            text={completed ? 'Re-open Section' : 'Mark Section Completed'}
            size="xl"
            onClick={onToggleComplete}
            loading={completing}
          />
        ) : (
          <div className="w-52" />
        )}
        {nextBlock !== null ? (
          <Button text="Next" variant="secondary" size="medium" onClick={() => navigateToBlock?.(nextBlock ?? 0)} />
        ) : (
          <div className="w-28" />
        )}
      </div>
      <ConfirmationDialog />
    </>
  );
};
