import { gql } from '@apollo/client';
import { REQUEST_OVERVIEW_FRAGMENT } from '../request/request.queries';
import { CLIENT_OVERVIEW_FRAGMENT } from '../clients/client.queries';
import { MEMBER_OVERVIEW_FRAGMENT } from '../member/member.queries';
import { COMPANY_OVERVIEW_FRAGMENT } from '../companies/company.queries';

const REQUEST_GROUP_BASE_OVERVIEW_FRAGMENT = gql`
  fragment RequestGroupBaseOverview on RequestGroup {
    _id
    canSelfServe
    createdAt
    createdBy {
      _id
      name
    }
    deadline
    deletedAt
    expiration
    isExpired
    name
    status
    template {
      _id
    }
    updatedAt
  }
`;

const REQUEST_GROUP_UNAUTHENTICATED_FRAGMENT = gql`
  fragment RequestGroupUnauthenticatedOverview on RequestGroup {
    ...RequestGroupBaseOverview
    company {
      _id
      name
    }
  }

  ${REQUEST_GROUP_BASE_OVERVIEW_FRAGMENT}
`;

const REQUEST_GROUP_OVERVIEW_FRAGMENT = gql`
  fragment RequestGroupOverview on RequestGroup {
    ...RequestGroupBaseOverview
    assignedStaff {
      ...MemberOverview
    }
    completion {
      completed
      total
      percentage
    }
    editable
    requestType {
      _id
      type
    }
    sentAt

    active
    archived
    closed
    selfServed
    total
  }

  ${REQUEST_GROUP_BASE_OVERVIEW_FRAGMENT}
  ${MEMBER_OVERVIEW_FRAGMENT}
`;

const REQUEST_GROUP_FRAGMENT = gql`
  fragment RequestGroupDetails on RequestGroup {
    ...RequestGroupOverview
    assignedClients {
      ...ClientOverview
    }
    company {
      ...CompanyOverview
    }
    requests {
      ...RequestOverview
    }
  }

  ${REQUEST_GROUP_OVERVIEW_FRAGMENT}
  ${COMPANY_OVERVIEW_FRAGMENT}
  ${CLIENT_OVERVIEW_FRAGMENT}
  ${REQUEST_OVERVIEW_FRAGMENT}
`;

export const GET_REQUEST_GROUP = gql`
  query GetRequestGroup($_id: ID!) {
    requestGroup(_id: $_id) {
      ...RequestGroupDetails
    }
  }

  ${REQUEST_GROUP_FRAGMENT}
`;

export const GET_REQUEST_GROUP_OVERVIEW = gql`
  query GetRequestGroupOverview($_id: ID!) {
    requestGroupOverview(_id: $_id) {
      ...RequestGroupUnauthenticatedOverview
    }
  }

  ${REQUEST_GROUP_UNAUTHENTICATED_FRAGMENT}
`;

export const GET_REQUEST_GROUPS = gql`
  query GetRequestGroups($includeArchived: Boolean) {
    requestGroups(includeArchived: $includeArchived) {
      ...RequestGroupOverview
    }
  }

  ${REQUEST_GROUP_OVERVIEW_FRAGMENT}
`;

export const CREATE_REQUEST_GROUP = gql`
  mutation CreateRequestGroup(
    $assignedClients: [ID!]!
    $assignedStaff: [ID!]!
    $canSelfServe: Boolean
    $deadline: DateTimeISO
    $expiration: DateTimeISO
    $name: String!
    $notes: String
    $requestType: ID
    $template: ID
  ) {
    createRequestGroup(
      assignedClients: $assignedClients
      assignedStaff: $assignedStaff
      canSelfServe: $canSelfServe
      deadline: $deadline
      expiration: $expiration
      name: $name
      notes: $notes
      requestType: $requestType
      template: $template
    ) {
      ...RequestGroupDetails
    }
  }

  ${REQUEST_GROUP_FRAGMENT}
`;

export const UPDATE_REQUEST_GROUP = gql`
  mutation UpdateRequestGroup(
    $_id: ID!
    $applyToChildren: Boolean
    $assignedClients: [ID!]
    $assignedStaff: [ID!]
    $canSelfServe: Boolean
    $deadline: DateTimeISO
    $expiration: DateTimeISO
    $name: String
    $notes: String
    $requestType: ID
    $status: REQUEST_STATUS
  ) {
    updateRequestGroup(
      _id: $_id
      applyToChildren: $applyToChildren
      assignedClients: $assignedClients
      assignedStaff: $assignedStaff
      canSelfServe: $canSelfServe
      deadline: $deadline
      expiration: $expiration
      name: $name
      notes: $notes
      requestType: $requestType
      status: $status
    ) {
      ...RequestGroupDetails
    }
  }

  ${REQUEST_GROUP_FRAGMENT}
`;

export const DELETE_REQUEST_GROUP = gql`
  mutation DeleteRequestGroup($_id: ID!, $applyToChildren: Boolean) {
    deleteRequestGroup(_id: $_id, applyToChildren: $applyToChildren) {
      ...RequestGroupDetails
    }
  }

  ${REQUEST_GROUP_FRAGMENT}
`;

export const RESTORE_REQUEST_GROUP = gql`
  mutation RestoreRequestGroup($_id: ID!, $applyToChildren: Boolean) {
    restoreRequestGroup(_id: $_id, applyToChildren: $applyToChildren) {
      ...RequestGroupDetails
    }
  }

  ${REQUEST_GROUP_FRAGMENT}
`;

export const DELIVER_REQUEST_GROUP = gql`
  mutation DeliverRequestGroup(
    $_id: ID!
    $assignSelf: Boolean
    $notify: [REQUEST_NOTIFY_OPTION!]
    $customMessage: String
    $replyTo: String
    $subject: String
    $toClients: [ID!]
  ) {
    deliverRequestGroup(
      _id: $_id
      assignSelf: $assignSelf
      notify: $notify
      customMessage: $customMessage
      replyTo: $replyTo
      subject: $subject
      toClients: $toClients
    ) {
      ...RequestGroupDetails
    }
  }

  ${REQUEST_GROUP_FRAGMENT}
`;

export const REDELIVER_REQUEST_GROUP = gql`
  mutation RedeliverRequestGroup($_id: ID!, $customMessage: String, $replyTo: String, $subject: String) {
    redeliverRequestGroup(_id: $_id, customMessage: $customMessage, replyTo: $replyTo, subject: $subject)
  }
`;

export const SELF_SERVE_REQUEST_GROUP = gql`
  mutation SelfServe($_id: ID!, $name: String!, $email: String!) {
    selfServe(_id: $_id, name: $name, email: $email)
  }
`;
