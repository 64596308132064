import { Button } from '../../../_core/button/button.component';
import {
  ACTIVE_STATUS,
  IInviteOverview,
  IMemberOverview,
  INVITE_STATUS,
  IUserOverview,
  ROLE,
  ROLE_LABELS
} from '../../../../lib/types';
import { useMemo, useState } from 'react';
import { activeStatus, inviteStatus } from '../../../../utils/wording.helpers';
import { StaffTable } from './staff-table.component';
import { StaffTableMultiselectActionButton } from './staff-table-multiselect-action-button/staff-table-multiselect-action-button';
import { UserIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '../../../_core/tooltip.component';
import { useCompanyLocations } from '../../../domains/locations/location.service';
import { useCompanyDepartments } from '../../../domains/departments/department.service';
import { IStaffActionButtonProps, StaffActionButton } from './staff-action-button';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { EditRolesContainer } from '../../../domains/staff/edit-roles/edit-roles-form.container';
import { EditDepartmentsContainer } from '../../../domains/departments/edit/edit-departments-form.container';
import { EditLocationsContainer } from '../../../domains/locations/edit/edit-locations-form.container';
import { IAdminProps } from '../../../_core/core.types';
import { nameableListToString, useTableFilterConfig, useTableSelect } from '../../../_core/table/utils/table-utils';
import { ITableRowValues, TableFilterKeys } from '../../../_core/table/table.types';
import { sortRoles } from '../../../../utils/role.helpers';
import { NullableStaff } from './staff-table.types';
import { sortMethodTuple, useTableSortConfig } from '../../../_core/table/utils/table-sort';

export interface IStaffTableContainerProps extends IAdminProps {
  currUserId: string;
  invites?: IInviteOverview[];
  members?: IMemberOverview[];
  loading: boolean;
}

interface IStaffTableRowValues extends ITableRowValues {
  invite?: IInviteOverview;
  member?: IMemberOverview;
}

const rolesToString = (roles?: ROLE[]) => (roles?.length ? ROLE_LABELS[[...roles].sort(sortRoles)[0]] : '');

export const StaffTableContainer: React.FC<IStaffTableContainerProps> = ({
  currUserId,
  invites,
  isAdmin,
  loading,
  members
}) => {
  const [inviteStaff, setInviteStaff] = useState(false);
  const [showEditDepartmentsModal, setShowEditDepartmentsModal] = useState<NullableStaff>(null);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState<NullableStaff>(null);
  const [showEditRolesModal, setShowEditRolesModal] = useState<NullableStaff>(null);

  const [filterConfig, filterFunc] = useTableFilterConfig({
    filters: [
      {
        title: TableFilterKeys.STATUS,
        placeholder: 'Filter by status',
        options: [ACTIVE_STATUS.ACTIVE, INVITE_STATUS.PENDING, 'EXPIRED', ACTIVE_STATUS.INACTIVE],
        value: [ACTIVE_STATUS.ACTIVE, INVITE_STATUS.PENDING],
        matcher: (curr: IMemberOverview | IInviteOverview, filterValue: string[]) => {
          if (!filterValue.length) return true;
          if (curr.deletedAt || ('user' in curr && curr.user.deletedAt))
            return filterValue.includes(ACTIVE_STATUS.INACTIVE);
          else if ('user' in curr) return filterValue.includes(ACTIVE_STATUS.ACTIVE);
          else if (curr.isExpired) return filterValue.includes('EXPIRED');
          return filterValue.includes(INVITE_STATUS.PENDING);
        }
      }
    ]
  });

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const { sortFunc, ...restSort } = useTableSortConfig({
    baseValueExtractor: (r: IStaffTableRowValues) => r.invite ?? r.member,
    sortMethods: {
      deletedAt: sortMethodTuple('string', (deletedAt?: Date) => activeStatus(!deletedAt)),
      user: sortMethodTuple('string', (u: IUserOverview) => u.name),
      department: sortMethodTuple('string', nameableListToString),
      location: sortMethodTuple('string', nameableListToString),
      roles: sortMethodTuple('string', rolesToString)
    },
    defaultSortFunc: (a: IStaffTableRowValues, b) => {
      // Sort by member over invite, status, invite status, member name, and then email
      const aVal = a.invite ? { email: a.invite.email } : { ...a.member?.user };
      const bVal = b.invite ? { email: b.invite.email } : { ...b.member?.user };

      if (!aVal || !bVal) return 0;

      if (aVal.deletedAt && !bVal.deletedAt) return 1;
      if (!aVal.deletedAt && bVal.deletedAt) return -1;

      if (a.invite && b.member?.user.name) return 1;
      if (b.invite && a.member?.user.name) return -1;

      if (a.invite && b.invite) {
        const aStatus = inviteStatus(a.invite) ?? '';
        const bStatus = inviteStatus(b.invite) ?? '';

        if (aStatus === 'Expired' && bStatus !== 'Expired') return 1;
        if (bStatus === 'Expired' && aStatus !== 'Expired') return -1;

        const statusCompare = aStatus.localeCompare(bStatus);
        if (statusCompare) return statusCompare;
      }

      if (aVal.name && !bVal.name) return -1;
      if (!aVal.name && bVal.name) return 1;
      const nameCompare = (aVal.name ?? '').localeCompare(bVal.name ?? '');
      if (nameCompare) return nameCompare;

      if (!aVal.email && bVal.email) return 1;
      if (!bVal.email && aVal.email) return -1;
      return (aVal.email ?? '').localeCompare(bVal.email ?? '');
    }
  });

  // Rows shown in table
  const staff = useMemo(() => {
    if (!members || !invites) return null;

    const actionButtonProps: Omit<IStaffActionButtonProps, 'invite' | 'member'> = {
      currUserId,
      departments,
      isAdmin,
      locations,
      setShowEditDepartmentsModal,
      setShowEditLocationsModal,
      setShowEditRolesModal
    };

    const newStaff: IStaffTableRowValues[] = [];
    members?.filter(filterFunc)?.forEach((member) => {
      const newStaffValues: IStaffTableRowValues = {
        member,
        rowId: member._id,
        values: [
          {
            children:
              currUserId === member.user._id ? (
                <Tooltip text="This is you!" direction="top" distance="sm">
                  <UserIcon className="h-6 mr-2" />
                  {member.name ?? member.user.name}
                </Tooltip>
              ) : (
                member.name ?? member.user.name
              )
          },
          { children: member.user.email },
          { children: rolesToString(member.roles) },
          { children: activeStatus(!member?.deletedAt) },
          { children: nameableListToString(member.department) },
          { children: nameableListToString(member.location) },
          { children: <StaffActionButton {...actionButtonProps} member={member} /> }
        ]
      };
      newStaff.push(newStaffValues);
    });

    invites?.filter(filterFunc)?.forEach((invite) => {
      const newStaffValues: IStaffTableRowValues = {
        invite,
        rowId: invite._id,
        values: [
          { children: invite.name ?? '' },
          { children: invite.email },
          { children: rolesToString(invite.roles) },
          { children: inviteStatus(invite) },
          { children: nameableListToString(invite.department) },
          { children: nameableListToString(invite.location) },
          { children: <StaffActionButton {...actionButtonProps} invite={invite} /> }
        ]
      };

      newStaff.push(newStaffValues);
    });

    return newStaff.sort(sortFunc);
  }, [currUserId, departments, filterFunc, invites, isAdmin, locations, members, sortFunc]);

  const { clearSelected, getSelectedModels, onSelect, onSelectAll, selected } = useTableSelect({
    models: [...(members ?? []), ...(invites ?? [])],
    rows: staff
  });

  return (
    <>
      <StaffTable
        {...restSort}
        inviteStaff={inviteStaff}
        isAdmin={isAdmin}
        members={members}
        selected={selected}
        setInviteStaff={setInviteStaff}
        staff={staff ?? []}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        filterConfig={filterConfig}
        loading={loading}
      >
        <Button onClick={() => setInviteStaff(true)} text="Invite Team Members" size="xl" />
        <StaffTableMultiselectActionButton
          currUserId={currUserId}
          getSelectedModels={getSelectedModels}
          selected={selected ?? []}
          clearSelected={clearSelected}
          staff={staff ?? []}
        />
      </StaffTable>
      {!!departments && (
        <RIDialog open={!!showEditDepartmentsModal} setOpen={(o) => setShowEditDepartmentsModal((s) => (o ? s : null))}>
          <EditDepartmentsContainer
            departments={departments}
            initialDepartments={showEditDepartmentsModal?.department ?? []}
            targetType={showEditDepartmentsModal && 'code' in showEditDepartmentsModal ? 'invite' : 'member'}
            targetId={showEditDepartmentsModal?._id ?? ''}
          />
        </RIDialog>
      )}
      {!!locations && (
        <RIDialog open={!!showEditLocationsModal} setOpen={(o) => setShowEditLocationsModal((s) => (o ? s : null))}>
          <EditLocationsContainer
            locations={locations}
            initialLocations={showEditLocationsModal?.location ?? []}
            targetType={showEditLocationsModal && 'code' in showEditLocationsModal ? 'invite' : 'member'}
            targetId={showEditLocationsModal?._id ?? ''}
          />
        </RIDialog>
      )}
      <RIDialog open={!!showEditRolesModal} setOpen={(o) => setShowEditRolesModal((s) => (o ? s : null))}>
        <EditRolesContainer
          invite={
            showEditRolesModal && 'code' in showEditRolesModal ? (showEditRolesModal as IInviteOverview) : undefined
          }
          member={
            showEditRolesModal && !('code' in showEditRolesModal) ? (showEditRolesModal as IMemberOverview) : undefined
          }
        />
      </RIDialog>
    </>
  );
};
