import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IClient, IEntityOverview } from '../../../../lib/types';
import { useMemo } from 'react';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

export interface IUpdateClientEntitiesFormValues {
  entities: IMultiSelectOption[];
}

interface IUpdateClientEntitiesFormProps extends IRIFormProps<IUpdateClientEntitiesFormValues> {
  client: IClient;
  currEntities: IEntityOverview[];
  entities: IEntityOverview[];
}

export const UpdateClientEntitiesForm = ({ currEntities, entities, onSubmit }: IUpdateClientEntitiesFormProps) => {
  const entityOptions = useMemo(() => entities.map((e) => ({ label: e.name, value: e._id })), [entities]);

  return (
    <Formik
      initialValues={
        { entities: currEntities.map((e) => ({ label: e.name, value: e._id })) } as IUpdateClientEntitiesFormValues
      }
      onSubmit={onSubmit}
    >
      {(props: FormikProps<IUpdateClientEntitiesFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel
            name="entities"
            label="Companies"
            placeholder="Edit contact's associated companies"
            type="multiselect"
            multiSelectProps={{ options: entityOptions }}
          />
          <FormButton loading={props.isSubmitting} text="Update" />
        </Form>
      )}
    </Formik>
  );
};
