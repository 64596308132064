import { FastField, FastFieldProps } from 'formik';
import { FormLabel } from '../form-label.component';
import { FormInput, IFormInputProps } from './form-input.component';

interface IFormInputWithLabelProps extends IFormInputProps {
  autoFocus?: boolean;
  flexDirection?: 'flex-row' | 'flex-col';
  label: string;
}

export const FormInputWithLabel = ({
  label,
  flexDirection = 'flex-col',
  name = '',
  ...inputProps
}: IFormInputWithLabelProps) => {
  let finalDirection = flexDirection;

  let typeClasses = '';
  switch (inputProps.type) {
    case 'checkbox':
      finalDirection = 'flex-row';
      typeClasses = 'items-center text-center';
      break;
    default:
      typeClasses = 'items-start justify-between';
  }

  return (
    <div className={`flex ${finalDirection} ${typeClasses} w-full`}>
      {inputProps.type !== 'checkbox' && <FormLabel label={label} name={name} />}
      <FastField name={name}>{(props: FastFieldProps) => <FormInput {...props} {...inputProps} />}</FastField>
      {inputProps.type === 'checkbox' && (
        <div className="ml-4">
          <FormLabel noPadding label={label} name={name} />
        </div>
      )}
    </div>
  );
};
