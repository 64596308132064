import { useCallback } from 'react';
import { inputClassesLineHeightStr } from '../_core/editor.const';
import { RadioOption } from './radio-option.component';
import { useDrop } from '../../_core/drag-and-drop/droppable.utils';

interface IRadioOptionsList {
  onUpdate: (_: string[]) => void;
  options: string[];
  setOptions: React.Dispatch<React.SetStateAction<string[]>>;
  tagClass: string;
}

export const RadioOptionsList = ({ onUpdate, options, setOptions, tagClass }: IRadioOptionsList) => {
  const handleRemove = useCallback(
    (tag: string) =>
      setOptions((prev) => {
        const newOptions = prev.filter((t) => t !== tag);
        onUpdate(newOptions);
        return newOptions;
      }),
    [onUpdate, setOptions]
  );

  useDrop({ axis: 'horizontal', setData: setOptions, onUpdate });

  return (
    <div className={`absolute top-0 right-0 draggable ${inputClassesLineHeightStr}`}>
      {options.map((tag, i) => (
        <RadioOption key={'tag-' + tag + i} {...{ i, onRemove: () => handleRemove(tag), tag, tagClass }} />
      ))}
    </div>
  );
};
