import { useCallback, useRef, useState } from 'react';
import { useResizeObserver } from 'usehooks-ts';
import { Loader } from '../loader.component';
import { UpDownIcon } from '../icon/icon.components';
import { useOnClickOutside } from '../utils/click';
import { ButtonDropdownOption, IButtonDropdownOptionProps } from './button-dropdown-option-component';
import { Tooltip } from '../tooltip.component';

export type ButtonVariant = 'primary' | 'secondary' | 'outline' | 'success' | 'gray' | 'custom' | 'text'; // Added 'text' variant
export type ButtonSize = 'fill' | 'fit' | 'xl' | 'large' | 'medium';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  containerClass?: string;
  fit?: 'right';
  dropdown?: {
    hideIcon?: boolean;
    hidePadding?: boolean;
    ignoreRelativeContainer?: boolean;
    up?: boolean;
    options: IButtonDropdownOptionProps[];
    className?: string;
  };
  hideEndMargin?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  slim?: boolean;
  subtitle?: string;
  tooltip?: string;
  text?: string;
  textClass?: string;
}

export const Button: React.FC<IButtonProps> = ({
  children,
  className,
  containerClass,
  dropdown,
  fit,
  hideEndMargin,
  icon,
  loading,
  onClick,
  variant = 'primary',
  size = 'fill',
  slim,
  subtitle,
  text,
  textClass = '',
  tooltip,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { height = 0 } = useResizeObserver({ ref });

  const isDropdown = !!dropdown?.options.length;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleClickOutside = useCallback(() => {
    if (isDropdown) {
      setDropdownOpen(false);
    }
  }, [isDropdown]);

  useOnClickOutside(ref, handleClickOutside);

  const isGrayVariant = variant === 'gray';
  const additionalClasses = [
    isGrayVariant
      ? 'bg-zinc-100 border-2 border-solid border-slate-600'
      : variant === 'custom'
      ? ''
      : variant === 'primary'
      ? 'bg-primary text-white'
      : variant === 'secondary'
      ? 'bg-secondary text-white'
      : variant === 'success'
      ? 'bg-success text-white'
      : variant === 'text'
      ? 'bg-transparent text-gray-500 hover:text-opacity-70' // Added logic for 'text' variant
      : ''
  ].join(' ');

  let maxWidth = '';
  switch (size) {
    case 'xl':
      maxWidth = 'max-w-52';
      break;
    case 'large':
      maxWidth = 'max-w-40';
      break;
    case 'medium':
      maxWidth = 'max-w-28';
      break;
  }
  const containerAdditionalClasses = [isDropdown && !dropdown.ignoreRelativeContainer ? 'relative' : '', maxWidth]
    .filter((v) => v)
    .join(' ');

  const hasChildren = !!text || !!children;

  const buttonClasses = hasChildren
    ? `flex w-full items-center justify-center align-middle px-3 leading-6 ${slim ? 'py-0.5' : 'py-1.5'}`
    : `px-1.5 text-center inline-flex items-center  ${slim ? 'py-0.5' : 'py-1.5'} ${hideEndMargin ? '' : 'me-2'}`;

  const buttonContent = (
    <>
      {icon}
      {hasChildren && (
        <span className={`${isGrayVariant ? 'font-semibold' : 'font-normal'} ${textClass}`}>
          {loading ? (
            <div className="flex justify-center">
              <Loader color={isGrayVariant ? 'fill-slate' : 'fill-white'} />
            </div>
          ) : (
            text || children
          )}
        </span>
      )}
      {isDropdown && !dropdown.hideIcon ? <UpDownIcon spacing="smallLeft" open={dropdownOpen} /> : null}
    </>
  );

  const button = (
    <>
      <Tooltip direction="top" singleLine text={tooltip}>
        <button
          {...props}
          onClick={
            isDropdown
              ? (e) => {
                  setDropdownOpen((v) => !v);
                  e.stopPropagation();
                }
              : onClick
          }
          className={`${buttonClasses} ${additionalClasses} ${subtitle ? 'flex-col' : ''} ${
            isGrayVariant ? 'text-slate-600' : ''
          } mx-auto rounded-md text-sm hover:bg-opacity-70 active:bg-gray-200 disabled:bg-gray-400 disabled:bg-opacity-100 ${className}`}
        >
          {subtitle ? (
            <>
              {buttonContent}
              <div className="text-2xs leading-3 font-normal">{subtitle}</div>
            </>
          ) : (
            buttonContent
          )}
        </button>
      </Tooltip>
      {isDropdown && dropdownOpen && (
        <div
          className={`absolute z-50 divide-y divide-gray-100 shadow w-full ${
            dropdown.hidePadding ? '' : slim ? 'my-1' : 'my-2'
          } bg-white border border-gray-100 ${fit === 'right' ? 'right-0' : ''} ${dropdown.className}`}
          style={dropdown.up ? { bottom: height } : undefined}
        >
          <ul
            className={`text-sm text-gray-700 ${dropdown.hidePadding ? 'my-0 list-none p-0' : ''}`}
            aria-labelledby="dropdownDefaultButton"
          >
            {dropdown.options.map((opt) => (
              <ButtonDropdownOption key={'button-opt-' + opt.text} {...opt} setDropdownOpen={setDropdownOpen} />
            ))}
          </ul>
        </div>
      )}
    </>
  );

  const fitClasses = fit === 'right' ? 'ml-auto' : '';

  return (
    <>
      <div
        className={`${size === 'fit' ? 'w-fit' : 'w-full'} ${containerAdditionalClasses} ${fitClasses} ${
          containerClass ?? ''
        }`}
        ref={ref}
      >
        {fit ? <div className={`w-fit ${fitClasses}`}>{button}</div> : button}
      </div>
    </>
  );
};
