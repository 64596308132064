import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OutputData } from '@editorjs/editorjs';

import { ITemplate, TEMPLATE_TYPE } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { ROUTE_PATHS } from '../../../_routes';
import { EditTemplateContainer } from '../../domains/template/edit/edit-template-form.container';
import { buildDefaultSection } from '../../_editor/_core/editor.const';
import { useClearTemplate } from '../../domains/template/template.service';
import { EditorPage } from '../editor-page.component';
import { OpenCollapseIcon } from '../../_core/icon/icon.components';

interface ITemplatePage {
  canSave: boolean;
  template: ITemplate;
}

export const TemplatePage = ({ canSave, template }: ITemplatePage) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [editTemplate, setEditTemplate] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [toggledAllOpen, toggleAllOpen] = useState(true);

  const { clearTemplate, loading: clearingRequest } = useClearTemplate(template);

  const editorData: OutputData = useMemo(
    () => ({ time: Date.now(), version: template.version, blocks: template.blocks ?? [buildDefaultSection()] }),
    [template.blocks, template.version]
  );

  return (
    <>
      <EditorPage
        canQuickCreate={template.type === TEMPLATE_TYPE.BULK || template.type === TEMPLATE_TYPE.REQUEST}
        canSave={canSave}
        data={editorData}
        disabled={!!template.deletedAt}
        defaultEditorType={template.type === TEMPLATE_TYPE.BLOCK ? 'section' : 'base'}
        menuOpen={menuOpen}
        onBack={() =>
          navigate(location.state && 'backPath' in location.state ? location.state.backPath : ROUTE_PATHS.TEMPLATES)
        }
        onEdit={() => {
          setEditTemplate(true);
          setMenuOpen(true);
        }}
        template={template}
        title={template.title}
        TitleActions={() => (
          <div className="flex items-center gap-4">
            <Button
              hideEndMargin
              containerClass="h-7"
              icon={<OpenCollapseIcon open={toggledAllOpen} />}
              variant="secondary"
              onClick={() => {
                document
                  .querySelectorAll(`#editorjs .open-${toggledAllOpen} button`)
                  .forEach((b) => (b as HTMLButtonElement).click());

                toggleAllOpen(!toggledAllOpen);
              }}
            />
          </div>
        )}
        TitleComponent={
          <h2 className="text-center text-xl text-black font-semibold">
            <span className="text-slate-800">Template ({template.type})</span>
          </h2>
        }
        ActionButtons={({ afterClear, confirmClear }) => (
          <div className="inline-block">
            <Button
              slim
              loading={clearingRequest}
              onClick={() =>
                confirmClear().then((clear) => {
                  if (clear) clearTemplate().then(afterClear);
                })
              }
              size="medium"
              text="Clear"
              variant="primary"
            />
          </div>
        )}
      />
      <RIDialog
        open={!!editTemplate}
        setOpen={(o) => {
          setEditTemplate(o);
          setMenuOpen(o);
        }}
      >
        <EditTemplateContainer
          close={() => {
            setEditTemplate(false);
            setMenuOpen(false);
          }}
          template={template}
        />
      </RIDialog>
    </>
  );
};
