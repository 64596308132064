import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IMemberOverview } from '../../../../lib/types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

export interface ITransferOwnershipParams {
  member: IMultiSelectOption[];
}

interface ITransferOwnershipFormProps extends IRIFormProps<ITransferOwnershipParams> {
  members: IMemberOverview[];
}

export const TransferOwnershipForm = ({ members, onBack, onSubmit }: ITransferOwnershipFormProps) => {
  const validateForm = (values: ITransferOwnershipParams) => {
    const errors: FormikErrors<ITransferOwnershipParams> = {};
    if (!values.member.length) errors.member = 'Must select a new owner';
    return errors;
  };

  return (
    <Formik initialValues={{ member: [] } as ITransferOwnershipParams} onSubmit={onSubmit} validate={validateForm}>
      {(props: FormikProps<ITransferOwnershipParams>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel
            name="member"
            label="New Owner"
            type="multiselect"
            placeholder="Select a new owner from current members"
            multiSelectProps={{
              options: members.map(({ _id, user }) => ({
                label: user.name ?? user.email,
                secondaryLabel: user.name ? user.email : undefined,
                value: _id
              })),
              singleSelect: true
            }}
          />
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Cancel" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text={'Transfer'} />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
