import { useMemo, useState } from 'react';
import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';

import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ISendClientInvitesProps, ISendStaffInvitesProps } from '../invite.types';
import { IDepartment, IInvite, ILocation, IMemberOverview, ROLE, ROLE_LABELS } from '../../../../lib/types';
import { BASIC_ROLE_OPTIONS } from '../../../../_configuration';
import { isValidEmail } from '../../../../utils/validation.helpers';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { CreateInviteUserContainer } from '../create-invite-user/create-invite-user-form.container';

interface ISendInvitesFormProps
  extends IRIFormProps<ISendClientInvitesProps | ISendStaffInvitesProps>,
    IPersonalCompanyProps {
  departments: IDepartment[];
  isClientEntityInvite: boolean;
  isClientInvite: boolean;
  locations: ILocation[];
  members?: IMemberOverview[];
  results?: null | IInvite[];
}

export const SendInvitesForm = ({
  departments,
  isClientEntityInvite,
  isClientInvite,
  isPersonalCompany,
  locations,
  members,
  onBack,
  onSkip,
  onSubmit
}: ISendInvitesFormProps) => {
  const [newContactData, setNewContactData] = useState('');

  const validateForm = (values: ISendClientInvitesProps | ISendStaffInvitesProps) => {
    const errors: FormikErrors<ISendClientInvitesProps | ISendStaffInvitesProps> = {};

    if ('emails' in values && 'emails' in errors) {
      if (!values.emails.length) {
        errors.emails = 'At least one value required';
      }

      const invalidEmails = values.emails.filter((email) => !isValidEmail(email.trim()));
      if (invalidEmails.length) {
        errors.emails = 'Invalid email address: ' + invalidEmails.join(', ');
      }
    }

    if (!isClientInvite && !values.roles?.length) {
      errors.roles = 'At least one value required';
    }

    return errors;
  };

  const options = useMemo(
    () => ({
      departments: departments.map((d) => ({ label: d.name, value: d._id })),
      locations: locations.map((l) => ({ label: l.name, value: l._id }))
    }),
    [departments, locations]
  );

  return (
    <Formik
      initialValues={
        isClientInvite
          ? ({ emails: [], department: [], location: [] } as ISendClientInvitesProps)
          : ({
              inviteData: [],
              department: [],
              location: [],
              roles: [{ label: ROLE_LABELS[ROLE.COMPANY_STAFF], value: ROLE.COMPANY_STAFF }]
            } as ISendStaffInvitesProps)
      }
      onSubmit={(values) =>
        onSubmit('emails' in values ? { ...values, emails: values.emails.filter((email) => email.trim()) } : values)
      }
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ISendClientInvitesProps | ISendStaffInvitesProps>) => (
        <>
          <Form action="#" method="POST" className="sm:max-w-xl mx-auto">
            {!isClientInvite && (
              <>
                <p className="font-light text-sm mb-4">Invite your team to collaborate with you on the platform.</p>
                <p className="font-light text-sm mb-4">
                  You can send invites now, or come back and invite them later at any time.
                </p>
              </>
            )}
            {isClientInvite && (
              <>
                {isClientEntityInvite && (
                  <p className="font-light text-sm mb-4">Add some contacts for your contact company below.</p>
                )}
                {!isClientEntityInvite && (
                  <p className="font-light text-sm mb-4">Add some contacts for your organization below.</p>
                )}
                <p className="font-light text-sm mb-4">You can also access the contacts list in your settings.</p>
              </>
            )}

            <div
              className={`flex flex-col${
                options.departments.length > 0 || options.locations.length > 0
                  ? ' border-b border-dashed mb-8 pb-4'
                  : ''
              }`}
            >
              {'emails' in props.values ? (
                <div className="mb-4">
                  <FormInputWithLabel
                    name="emails"
                    label="Emails"
                    placeholder="Add email"
                    type="tags"
                    tagsProps={{ previewLabelPrefix: 'Add email', inputType: 'email' }}
                  />
                </div>
              ) : (
                <FormInputWithLabel
                  name="inviteData"
                  label="Emails"
                  type="multiselect"
                  placeholder="Emails"
                  multiSelectProps={{
                    options:
                      members?.map((m) => ({
                        value: m.user.email,
                        label: m.name ?? m.user.name,
                        secondaryLabel: m.user.email
                      })) ?? [],
                    nullable: true,
                    canAdd: true,
                    onAdd: setNewContactData
                  }}
                />
              )}
              {!isClientInvite && (
                <div className="mb-4">
                  <FormInputWithLabel
                    name="roles"
                    label="Roles"
                    type="multiselect"
                    placeholder="Select Roles"
                    multiSelectProps={{ options: BASIC_ROLE_OPTIONS, singleSelect: true, skipSort: true }}
                  />
                </div>
              )}
            </div>
            {(options.locations.length > 0 || options.departments.length > 0) && !isPersonalCompany && (
              <div className="flex flex-col mb-8">
                <p className="font-light text-sm mb-4">
                  If you want to organize {isClientInvite ? 'you clients' : 'your team'} by{' '}
                  {options.locations.length ? 'office locations' : ''}
                  {options.locations.length || options.departments.length ? ' or ' : ''}
                  {options.departments.length ? 'departments' : ''}, please fill out the fields below.
                </p>
                <div className="mb-4">
                  <FormInputWithLabel
                    name="location"
                    label="Locations"
                    type="multiselect"
                    placeholder="Select Locations"
                    multiSelectProps={{ options: options.locations }}
                  />
                </div>
                <div className="mb-4">
                  <FormInputWithLabel
                    name="department"
                    label="Departments"
                    type="multiselect"
                    placeholder="Select Departments"
                    multiSelectProps={{ options: options.departments }}
                  />
                </div>
              </div>
            )}
            <div>
              <div className="flex-row justify-between mt-4">
                <FormButton
                  loading={props.isSubmitting}
                  disabled={'emails' in props.values ? !props.values.emails.length : !props.values.inviteData.length}
                  text="Send"
                />
                {(!!onBack || !!onSkip) && (
                  <div className="flex justify-between mt-4">
                    {!!onBack && (
                      <div className="w-24 basis-auto">
                        <FormButton
                          icon={<ArrowLongLeftIcon className="w-6 h-6 mr-2" />}
                          onPress={onBack}
                          text="Back"
                          type="button"
                          variant="text"
                        />
                      </div>
                    )}
                    {!!onSkip && (
                      <div className="w-24 basis-auto">
                        <FormButton onPress={onSkip} text="Skip" type="button" variant="text" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Form>
          {'inviteData' in props.values && (
            <RIDialog open={!!newContactData} setOpen={(o) => setNewContactData((d) => (o ? d : ''))} locked>
              <CreateInviteUserContainer
                email={newContactData}
                close={() => setNewContactData('')}
                onSubmit={({ email, name }) => {
                  props.setFieldValue('inviteData', [
                    ...('inviteData' in props.values ? props.values.inviteData : []),
                    { value: email, label: name, secondaryLabel: email }
                  ]);
                  setNewContactData('');
                }}
              />
            </RIDialog>
          )}
        </>
      )}
    </Formik>
  );
};
