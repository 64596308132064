import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../_core/button/button-dropdown-option-component';
import { IRequest, IRequestOverview, REQUEST_STATUS } from '../../../lib/types';
import { ROUTE_PATHS } from '../../../_routes';
import {
  useClearRequest,
  useDeleteRequest,
  useDuplicateRequest,
  useLazyRequest,
  useRestoreRequest,
  useUnsendRequest,
  useUpdateRequest
} from './request.service';
import toast from 'react-hot-toast';
import { showError } from '../../../lib/utils';
import { useConfirm } from '../../_core/confirm/confirm.utils';
import { useDialogOptions } from '../../_core/dialog-options-modal/dialog-options-modal.utils';
import { EditorSaveResult, SaveProps } from '../../_editor/_core/types';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { EditRequestContainer } from './edit/edit-request.container';

export interface IRequestActionProps {
  disabled?: boolean;
  hasChanged?: boolean;
  hideViewAction?: boolean;
  last?: boolean;
  loading?: boolean;
  onClearRequest?: () => void;
  onCreateTemplate?: () => void;
  onSave?: (_?: SaveProps) => Promise<EditorSaveResult>;
  onSendRequest?: () => void;
  onResendRequest?: () => void;
  reloadPageState?: boolean;
  request: IRequestOverview;
  slim?: boolean;
  togglePreview?: (_: boolean) => void;
}

export const RequestActionButton: React.FC<IRequestActionProps> = ({
  disabled,
  hasChanged,
  hideViewAction,
  last,
  loading,
  onClearRequest,
  onCreateTemplate,
  onSave,
  onSendRequest,
  onResendRequest,
  reloadPageState,
  request,
  slim,
  togglePreview
}) => {
  const navigate = useNavigate();

  const [configureRequest, setConfigureRequest] = useState<IRequest | null>(null);

  const { clearRequest, loading: clearingRequest } = useClearRequest(request, () => {
    // TODO: Refreshing the page is a short-term workaround, ideally we find a way for prod editors to be re-rendered succesfully on external data changes without failing in prod
    if (reloadPageState) window.location.reload();
  });

  const { duplicateRequest, loading: duplicatingRequest } = useDuplicateRequest(request);
  const { deleteRequest, loading: deletingRequest } = useDeleteRequest();
  const { getRequest, loading: loadingRequestDetails } = useLazyRequest();
  const { restoreRequest, loading: restoringRequest } = useRestoreRequest();
  const { updateRequest, loading: updatingRequest } = useUpdateRequest(request);
  const { unsendRequest, loading: unsendingRequest } = useUnsendRequest(request);

  const { ConfirmationDialog: ConfirmClearDialog, confirm: confirmClear } = useConfirm({
    title: 'Are you sure you want to clear request?'
  });

  const { Dialog: CopyDialog, open: openCopyDialog } = useDialogOptions({
    title: 'Duplicate Request',
    message: 'Do you want to clear answers from the created copy?',
    buttons: [
      { title: 'No', clickResult: false },
      { title: 'Yes', clickResult: true }
    ]
  });

  const viewAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        navigate(ROUTE_PATHS.REQUEST + '/' + request._id, {
          state: { backPath: window.location.pathname + window.location.search }
        }),
      text: 'View'
    }),
    [navigate, request._id]
  );

  const configureAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        getRequest({ _id: request._id }).then((r) => {
          if (r.data) setConfigureRequest(r.data.requestById);
        }),
      text: 'Configure'
    }),
    [getRequest, request._id]
  );

  const duplicateAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        openCopyDialog().then(async (clear) => {
          if (hasChanged && onSave) await onSave({ forceFetchLatestData: true });
          duplicateRequest(clear).then(({ data }) => {
            // NOTE: Can't use navigate function here because request cache gets messed up and previous requests blocks are maintained on new request (likely due to editor load issue?)
            if (data?.duplicateRequest) window.location.href = ROUTE_PATHS.REQUEST + '/' + data.duplicateRequest._id;
            else toast.error('Failed to duplicate request for unknown reason');
          });
        }),
      text: 'Duplicate'
    }),
    [duplicateRequest, hasChanged, onSave, openCopyDialog]
  );

  const clearAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        confirmClear().then((clear) => {
          if (clear) {
            if (onClearRequest) onClearRequest();
            clearRequest().then(() => {
              // TODO: Refreshing the page is a short-term workaround, ideally we find a way for prod editors to be re-rendered succesfully on external data changes without failing in prod
              if (reloadPageState) window.location.reload();
            });
          }
        }),
      text: 'Clear'
    }),
    [clearRequest, confirmClear, onClearRequest, reloadPageState]
  );

  const createTemplateAction: IButtonDropdownOptionProps | null = useMemo(
    () =>
      onCreateTemplate
        ? ({ onClick: onCreateTemplate, text: 'Save copy as template' } as IButtonDropdownOptionProps)
        : null,
    [onCreateTemplate]
  );

  const lockAction: IButtonDropdownOptionProps = useMemo(() => {
    const isLocked = request.status === REQUEST_STATUS.LOCKED;

    return {
      onClick: () => {
        updateRequest({ status: isLocked ? REQUEST_STATUS.SENT : REQUEST_STATUS.LOCKED }).then(() =>
          togglePreview?.(!isLocked)
        );
      },
      text: isLocked ? 'Unlock' : 'Lock'
    };
  }, [request.status, updateRequest, togglePreview]);

  const updateStatusAction: IButtonDropdownOptionProps = useMemo(() => {
    const isClosed = request.status === REQUEST_STATUS.CLOSED;

    return {
      onClick: () => {
        updateRequest({ status: isClosed ? REQUEST_STATUS.SENT : REQUEST_STATUS.CLOSED }).then(() =>
          togglePreview?.(!isClosed)
        );
      },
      text: isClosed ? 'Re-open' : 'Complete'
    };
  }, [request.status, updateRequest, togglePreview]);

  const onRestoreRequest = useCallback(async () => {
    if (request.deletedAt)
      try {
        await restoreRequest(request);
        toast.success(`Restored Request`);
      } catch (err) {
        showError('Unable to restore request', err as Error);
      }
  }, [request, restoreRequest]);

  const restoreRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreRequest, text: 'Restore' }),
    [onRestoreRequest]
  );

  const onDeleteRequest = useCallback(async () => {
    try {
      await deleteRequest(request);
      toast.success(`Deleted Request`);
    } catch (err) {
      showError('Unable to delete request', err as Error);
    }
  }, [deleteRequest, request]);

  const deleteRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteRequest, text: 'Delete' }),
    [onDeleteRequest]
  );

  const resendAction: IButtonDropdownOptionProps | null = useMemo(
    () => (onResendRequest ? { onClick: onResendRequest, text: 'Re-send' } : null),
    [onResendRequest]
  );

  // Mark client finished working as false
  const unsubmitAction: IButtonDropdownOptionProps | null = useMemo(
    () => ({ onClick: () => updateRequest({ clientFinishedWorking: false }), text: 'Unsubmit' }),
    [updateRequest]
  );

  const sendAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        onSendRequest
          ? request.assignedTo
            ? onSendRequest()
            : toast.error('Must assign a contact to send request')
          : {},
      text: 'Send Request'
    }),
    [onSendRequest, request.assignedTo]
  );

  const unsendAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: unsendRequest, text: 'Unsend' }),
    [unsendRequest]
  );

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] = [];
    if (!hideViewAction) newOptions.push(viewAction);
    if (!request.deletedAt) {
      if (!hideViewAction) newOptions.push(configureAction);
      newOptions.push(clearAction, duplicateAction);
      if (createTemplateAction) newOptions.push(createTemplateAction);
      if (request.status === REQUEST_STATUS.DRAFT && onSendRequest) newOptions.push(sendAction);
      if (request.status === REQUEST_STATUS.SENT) {
        if (resendAction) newOptions.push(resendAction);
        newOptions.push(unsendAction);
      }

      if (
        request.status &&
        [REQUEST_STATUS.SENT, REQUEST_STATUS.LOCKED, REQUEST_STATUS.CLOSED].includes(request.status)
      )
        newOptions.push(lockAction, updateStatusAction);

      if (request.clientFinishedWorking) newOptions.push(unsubmitAction);
    }

    newOptions.push(request.deletedAt ? restoreRequestAction : deleteRequestAction);

    return newOptions;
  }, [
    hideViewAction,
    viewAction,
    request.deletedAt,
    request.status,
    request.clientFinishedWorking,
    restoreRequestAction,
    deleteRequestAction,
    configureAction,
    clearAction,
    duplicateAction,
    createTemplateAction,
    onSendRequest,
    sendAction,
    lockAction,
    updateStatusAction,
    unsubmitAction,
    resendAction,
    unsendAction
  ]);

  return (
    <>
      <Button
        disabled={disabled}
        slim={slim}
        dropdown={{ options, up: last }}
        text="ACTION"
        size="large"
        loading={
          loading ||
          deletingRequest ||
          restoringRequest ||
          updatingRequest ||
          clearingRequest ||
          duplicatingRequest ||
          !!configureRequest ||
          loadingRequestDetails ||
          unsendingRequest
        }
      />
      <ConfirmClearDialog />
      <CopyDialog />
      {!!configureRequest && (
        <RIDialog setOpen={(o) => setConfigureRequest((prev) => (o ? prev : null))}>
          <EditRequestContainer request={configureRequest} close={() => setConfigureRequest(null)} />
        </RIDialog>
      )}
    </>
  );
};
