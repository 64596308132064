import { Form } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { PropsWithChildren } from 'react';
import { ISendCustomRequestMessageParams } from '../../request/request.types';

export interface ISendCustomRequestGroupMessageFormParams
  extends Omit<ISendCustomRequestMessageParams, '_id' | 'notify' | 'to'> {
  assignSelf: boolean;
}

export const SendRequestGroupMessageFormFields = ({
  children,
  forceSendAll
}: PropsWithChildren & { forceSendAll?: boolean }) => {
  return (
    <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
      {!!forceSendAll && <FormInputWithLabel name="assignSelf" label="Assign me to all new requests" type="checkbox" />}
      <FormInputWithLabel name="replyTo" label="Reply To:" type="email" placeholder="Enter email" />
      <FormInputWithLabel
        name="subject"
        label="Subject:"
        type="text"
        placeholder="You have received a new message on one of your Request's"
      />
      <FormInputWithLabel name="message" label="Message:" placeholder="Enter your message here..." type="textarea" />
      {children}
    </Form>
  );
};
