import EditorJs, { OutputData } from '@editorjs/editorjs';
import React, { MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateRequest, useUpdateRequestAsClient } from '../../../domains/request/request.service';
import {
  IBlockPackageUpdateResponse,
  IRequest,
  IRequestBlock,
  IRequestBlockTemplate,
  ITemplate,
  TEMPLATE_TYPE
} from '../../../../lib/types';
import { IEditorRefProps } from '../../../_pages/FormBuilderPage/form-editor.types';
import { showError } from '../../../../lib/utils';
import { createRoot } from 'react-dom/client';
import { Button } from '../../../_core/button/button.component';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useInterval } from 'usehooks-ts';
import _, { isNaN } from 'lodash';
import { EditorSaveResult, SaveProps } from '../types';
import toast from 'react-hot-toast';
import { useUpdateTemplate } from '../../../domains/template/template.service';
import { useSearchParams } from 'react-router-dom';

// Util hooks
export const useFocusedBlockTracking = ({ editorRef, editorblock }: IEditorRefProps) => {
  // TODO: Add focused block tracking for possible side panel

  useEffect(() => {
    const editorElement = document.getElementById(editorblock);
    const onClick = (e: MouseEvent) => {
      // Prevent bubble up of click event to parent section blocks
      e.stopImmediatePropagation();

      if (editorElement && editorRef.current?.blocks && e.type === 'click') {
        const blockIndex = editorRef.current.blocks.getCurrentBlockIndex();
        if (blockIndex >= 0) {
          const block = editorRef.current.blocks.getBlockByIndex(blockIndex);
          document.querySelector('.focused-block')?.classList.remove('focused-block');
          editorElement.querySelector(`div[data-id="${block?.id}"]`)?.classList.add('focused-block');
        }
      }
    };

    if (editorElement) {
      editorElement.addEventListener('click', onClick);
    }

    return () => {
      editorElement?.removeEventListener('click', onClick);
    };
  }, [editorRef, editorblock]);
};

const filterIgnoredEntries = ([key, value]: [string, unknown]) =>
  !['__typename', 'time', 'blockIndex', 'totalBlocks', 'requestClosed', 'requestSent', 'id', 'open'].includes(key) &&
  value !== undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepCompare = (currDepth: number, a?: any, b?: any, maxDepth?: number) => {
  if (maxDepth && currDepth > maxDepth) return true;

  if (!a && !b) return true;
  if (!a || !b) {
    // console.log('One value does not exist', { a, b });
    return false;
  }
  const aEntries = Object.entries(a).filter(filterIgnoredEntries);
  const bEntries = Object.entries(b).filter(filterIgnoredEntries);

  if (aEntries.length !== bEntries.length) {
    // console.log('Keycount mismatch', {
    //   a,
    //   b,
    //   originalDataMissing: aEntries.filter(([ak]) => !bEntries.find(([bk]) => ak === bk)),
    //   latestDataMissing: bEntries.filter(([bk]) => !aEntries.find(([ak]) => ak === bk))
    // });
    return false;
  }

  for (const [key, value] of aEntries) {
    if (Array.isArray(value)) {
      if (!deepCompareArr(currDepth + 1, value, b[key], maxDepth)) {
        // console.log('Deep compare arrays failed', { key, a: value, b: b[key] });
        return false;
      }
    } else if (typeof value === 'object') {
      if (!(key in b)) {
        // console.log('Missing key in b', { key, a, b });
        return false;
      } else if (!deepCompare(currDepth + 1, value, b[key], maxDepth)) {
        // console.log('Deep compare failed', { key, a: value, b: b[key] });
        return false;
      }
    } else if (!_.isEqual(value, b[key])) {
      // console.log('Values unequal', { key, a: value, b: b[key] });
      return false;
    }
  }

  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepCompareArr = (currDepth: number, a?: any[], b?: any[], maxDepth?: number) => {
  if (maxDepth && currDepth > maxDepth) return true;

  // console.log('Deep compare arr', { a, b });
  if (a?.length !== b?.length) return false;

  for (let i = 0; i < (a?.length ?? 0); i++) {
    if (!deepCompare(currDepth + 1, a?.[i], b?.[i], maxDepth)) {
      // console.log('Failed on', { i, a: a?.[i], b: b?.[i] });
      return false;
    }
  }

  return true;
};

export const useSaveEditor = ({
  canSave,
  forceNonSectionEditor,
  mutableData,
  setCanRefreshData,
  setMutableData,
  ref,
  request,
  template,
  token
}: {
  canSave: boolean;
  forceNonSectionEditor?: boolean; // Used to indicte editor is for a single non-section
  mutableData: OutputData;
  ref?: MutableRefObject<EditorJs | undefined>;
  setCanRefreshData: React.Dispatch<SetStateAction<boolean>>;
  setMutableData: React.Dispatch<SetStateAction<OutputData>>;
  request?: IRequest;
  template?: ITemplate;
  token?: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeSection, setActiveSection] = useState(() => {
    const requestedSection =
      searchParams.has('section') && !isNaN(searchParams.get('section'))
        ? parseInt(searchParams.get('section') ?? '0')
        : 0;

    return mutableData.blocks.length > requestedSection ? requestedSection : 0;
  });
  const [showAllSections, setShowAllSections] = useState(!!forceNonSectionEditor);

  const [latestData, setLatestData] = useState<OutputData | null>(null);

  const [editorFailedToReady, setEditorFailedToReady] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [togglingState, setTogglingState] = useState(false);
  const [updatingOrder, setUpdatingOrder] = useState(false);

  const { updateRequestAsClient, loading: updatingAsClient } = useUpdateRequestAsClient({
    lastUpdatedAt: request?.lastUpdate.updatedAt ?? new Date(),
    requestId: request?._id ?? '',
    token: token ?? ''
  });

  const { updateRequest, loading: updatingRequest } = useUpdateRequest({
    _id: request?._id ?? '',
    lastUpdatedAt: request?.lastUpdate.updatedAt ?? new Date()
  });

  const { updateTemplate, loading: updatingTemplate } = useUpdateTemplate({
    _id: template?._id ?? '',
    lastUpdatedAt: template?.lastUpdate.updatedAt ?? new Date(),
    type: template?.type ?? TEMPLATE_TYPE.BLOCK
  });

  const saving = useMemo(
    () => updatingRequest || updatingAsClient || updatingTemplate || updatingOrder,
    [updatingAsClient, updatingRequest, updatingTemplate, updatingOrder]
  );

  const checkIfChanged = useCallback(async (): Promise<EditorSaveResult> => {
    try {
      // const start = new Date();

      // Wait for editor to be ready
      if (!ref?.current?.save || !isReady) return { hasChanged: false };

      const data = await ref.current.save(true);

      const hasActiveSection = activeSection !== undefined && !showAllSections;
      const newBlocks = hasActiveSection ? mutableData.blocks : data.blocks;
      if (hasActiveSection) newBlocks[activeSection].data.outputData.blocks = data.blocks;

      const sourceBlocks = (request || template)?.blocks;
      const hasChanged = !deepCompareArr(0, sourceBlocks, newBlocks);

      return { data: { ...data, blocks: newBlocks }, hasChanged };
    } catch (err) {
      showError('Failed to determine if data has changed', err as Error);
      return {};
    }
  }, [ref, isReady, activeSection, showAllSections, mutableData.blocks, request, template]);

  const callUpdate = useCallback(
    async (blocks: IRequestBlockTemplate[] | IRequestBlock[]) => {
      if (!blocks) throw new Error('Failed to prep save data');
      let succeeded = false;

      if (template) {
        const result = await updateTemplate({ blocks, isGlobal: !template.company });
        if (result?.error) {
          toast.error(
            `Failed to update template.\n${result.error}.\n\nRefreshing data now...\nYour changes may be lost.`,
            { id: 'template-update-' + template._id }
          );
          setCanRefreshData(true);
        } else toast.success('Saved', { id: 'saved-template' });

        succeeded = !!result;
      }

      if (request) {
        let result: IBlockPackageUpdateResponse | undefined;
        if (token) result = await updateRequestAsClient(blocks as IRequestBlock[]);
        else result = await updateRequest({ blocks: blocks as IRequestBlock[] });

        if (result?.error) {
          toast.error(
            `Failed to update request.\n${result.error}.\n\nRefreshing data now...\nYour changes may be lost.`,
            { id: 'request-update-' + request._id }
          );
          setCanRefreshData(true);
        } else toast.success('Saved', { id: 'saved-request' });

        succeeded = !!result;
      }

      if (succeeded) setHasChanged(false);
      else throw new Error('Failed to save');
    },
    [request, setCanRefreshData, template, token, updateRequest, updateRequestAsClient, updateTemplate]
  );

  const onSave = useCallback(
    async ({ dataToSave, forceFetchLatestData }: SaveProps = {}): Promise<EditorSaveResult> => {
      try {
        const fetched = forceFetchLatestData ? await checkIfChanged() : null;
        let finalData = fetched?.hasChanged ? fetched.data : dataToSave;

        if (!saving && ((hasChanged && latestData && mutableData.blocks) || finalData)) {
          if (!finalData && latestData) finalData = latestData;
          else if (!finalData) throw new Error('Missing data to save');

          const latestBlocks = finalData.blocks as IRequestBlockTemplate[];
          await callUpdate(latestBlocks);
        }

        return { data: finalData || fetched?.data || undefined, hasChanged: fetched?.hasChanged || hasChanged };
      } catch (err) {
        showError('Failed to save', err as Error);
      }
      return {};
    },
    [callUpdate, checkIfChanged, hasChanged, latestData, mutableData.blocks, saving]
  );

  const handleSetActiveSection = useCallback(
    async (section: number) => {
      const { data, hasChanged } = await checkIfChanged();
      if (hasChanged) await onSave({ dataToSave: data });

      setActiveSection(section);
      setSearchParams((prev) => {
        prev.set('section', String(section));
        return prev;
      });
    },
    [checkIfChanged, onSave, setSearchParams]
  );

  const updateOrder = useCallback(
    async (updatedOrder: string[]) => {
      if (!updatingOrder)
        try {
          if (activeSection === undefined) throw new Error("Can't force save unless in edit mode");
          setUpdatingOrder(true);
          const { data } = await checkIfChanged();

          const updateBlocks = mutableData.blocks;
          if (data) updateBlocks[activeSection].data.outputData = data;

          const currActiveSectionId = updateBlocks[activeSection].id;
          const updatedSortedBlocks = updatedOrder.map((b) =>
            updateBlocks.find((ub) => ub.id === b)
          ) as IRequestBlockTemplate[];

          setMutableData({ ...mutableData, blocks: updatedSortedBlocks });

          const newIndex = updatedSortedBlocks.findIndex((b) => b.id === currActiveSectionId);

          handleSetActiveSection(newIndex >= 0 ? newIndex : activeSection);
        } catch (err) {
          showError('Failed to save and update order of blocks', err as Error);
        } finally {
          setUpdatingOrder(false);
        }
    },
    [activeSection, checkIfChanged, handleSetActiveSection, mutableData, setMutableData, updatingOrder]
  );

  const handleSetShowAll = useCallback(
    async (showAll: boolean) => {
      await onSave({ forceFetchLatestData: true });

      setShowAllSections(showAll);
    },
    [onSave]
  );

  const onFail = (err: Error) => {
    showError('Failed to make editor ready', err);
    setEditorFailedToReady(true);
  };

  useInterval(() => {
    if (canSave && isReady && !saving) onSave();
  }, 5000);

  useInterval(() => {
    if (!saving && isReady && (canSave || !latestData)) {
      checkIfChanged().then((r) => {
        if (r.hasChanged) setHasChanged(true);

        // Guarantee we populate latest data if it has yet to be set
        setLatestData((prev) => (!prev || r.hasChanged ? r.data : prev) ?? null);
      });
    }
  }, 2500);

  return {
    hasChanged,
    latestData,
    onSave,
    updateOrder,
    saving,
    isReady,
    setIsReady,
    editorFailedToReady,
    onFail,
    togglingState,
    setTogglingState,
    activeSection,
    setActiveSection: handleSetActiveSection,
    setLatestData,
    showAllSections,
    setShowAllSections: handleSetShowAll
  };
};

// Util functions
export const renderWithBlockFocusWrapper = (content: HTMLDivElement): HTMLDivElement => {
  return content;
};

export interface IRenderOpenToggleParams {
  additionalClass?: string;
  append?: boolean;
  dark?: boolean;
  onOpenToggle?: () => void;
  open: boolean;
  openToggleId: string;
  parent?: HTMLDivElement;
  replaceSelector?: string;
}

export const SECTION_OPEN_TOGGLE_CLASS = 'section-open-toggle';

export const renderOpenToggle = ({
  additionalClass,
  append,
  dark,
  onOpenToggle,
  open,
  openToggleId,
  parent,
  replaceSelector
}: IRenderOpenToggleParams) => {
  const openToggle = document.createElement('div');
  openToggle.id = openToggleId;
  openToggle.classList.add(SECTION_OPEN_TOGGLE_CLASS, `open-${open}`);
  if (additionalClass) openToggle.classList.add(additionalClass);

  const openRoot = createRoot(openToggle);
  const iconContainerClass = `m-auto border rounded-md ${dark ? 'border-secondary bg-secondary' : 'border-black'}`;
  const iconSize = 18;
  openRoot.render(
    <Button
      hideEndMargin
      icon={
        open ? (
          <div className={iconContainerClass}>
            <ChevronDownIcon height={iconSize} width={iconSize} color={dark ? 'white' : ''} />
          </div>
        ) : (
          <div className={iconContainerClass}>
            <ChevronRightIcon height={iconSize} width={iconSize} color={dark ? 'white' : ''} />
          </div>
        )
      }
      className="ce-toolbar__button"
      onClick={onOpenToggle ? () => onOpenToggle() : undefined}
      type="button"
      variant="outline"
    />
  );

  if (replaceSelector) {
    const wrapper = document.querySelector(replaceSelector);
    wrapper?.querySelector('#' + openToggleId)?.remove();

    if (append) wrapper?.append(openToggle);
    else wrapper?.prepend(openToggle);
  } else {
    if (append) parent?.append(openToggle);
    else parent?.prepend(openToggle);
  }
};
