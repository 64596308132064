import { Outlet } from 'react-router-dom';
import { IUxVariantProps } from '../_core/core.types';
import { IPageHeaderProps, PageHeader } from '../_core/page-header/page-header.component';
import { BottomPadding } from './bottom-padding.component';
import { Footer } from '../_pages/InfoPages/footer.component';

export interface IDefaultPageLayoutProps extends React.PropsWithChildren, IUxVariantProps, IPageHeaderProps {
  className?: string;
  padded?: boolean;
}

export const DefaultPageLayout = ({ children, className, dark, light, padded, ...rest }: IDefaultPageLayoutProps) => {
  return (
    <div
      id="default-layout"
      className={`flex flex-1 flex-col bg-gray-50 ${dark ? 'bg-secondary' : light ? 'bg-white' : ''} ${
        className ?? ''
      }`}
    >
      <PageHeader {...rest} />
      {children}
      {!!padded && <BottomPadding />}
      <Footer />
    </div>
  );
};

export const DefaultPageLayoutWithOutlet = (props: IDefaultPageLayoutProps) => {
  return (
    <DefaultPageLayout {...props}>
      <Outlet />
    </DefaultPageLayout>
  );
};
