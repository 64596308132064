import { Route } from 'react-router-dom';

// Providers
import { AuthProvider } from './providers/auth.provider';
import { GraphqlProvider } from './providers/graphql.provider';

// Layouts
import { LandingPageLayout } from './components/_layouts/landing-page.layout';
import { InsetLayout } from './components/_layouts/inset.layout';

// Pages
import { AuthRedirectPageContainer } from './components/_pages/AuthRedirect/auth-redirect-page.container';
import ErrorPage from './components/_pages/InfoPages/error.page';
import { ProfilePageContainer } from './components/_pages/ProfilePage/profile-page.container';
import { ToastProvider } from './providers/toast.provider';
import { NoMatchPageLayout } from './components/_layouts/404-page.layout';
import { FirmProfilePageContainer } from './components/_pages/FirmProfilePage/firm-profile-page.container';
import { DashboardPageContainer } from './components/_pages/DashboardPage/dashboard-page.container';
import { JoinWithCodePageContainer } from './components/_pages/JoinWithCodePage/join-with-code-page.container';
import { GetInviteByCodeContainer } from './components/domains/invite/get-by-code/get-invite-by-code-form.container';
import { AuthenticatedLayoutWrapper } from './components/_layouts/authenticated-wrapper.layout';
import { EntityProfilePageContainer } from './components/_pages/EntityProfilePage/entity-profile-page.container';
import { ClientProfilePageContainer } from './components/_pages/ClientProfilePage/client-profile-page.container';

import { DefaultPageLayout, DefaultPageLayoutWithOutlet } from './components/_layouts/default-page.layout';
import { RequestPageAuthorizationWrapper } from './components/_pages/RequestPage/request-page-auth-wrapper/request-page-auth-wrapper';
import { ContactsPage } from './components/_pages/ContactsPage/contacts-page.component';
import { OnboardingPage } from './components/_pages/OnboardingPage/onboarding-page.component';
import { TemplatesPage } from './components/_pages/TemplatesPage/templates-page.component';
import { ViewInvitePageContainer } from './components/_pages/ViewInvitePage/view-invite-page.container';
import ContactUsPage from './components/_pages/InfoPages/contact-us.page';
import PrivacyPolicyPage from './components/_pages/InfoPages/privacy-policy.page';
import TermsAndConditionsPage from './components/_pages/InfoPages/terms-and-conditions.page';
import { LoginPage } from './components/_pages/LoginPage/login-page.component';
import { BulkRequestPageContainer } from './components/_pages/BulkRequestPage/bulk-request-page.container';
import { SelfServePageContainer } from './components/_pages/SelfServePage/self-serve-page.container';
import { NoMatchPageContainer } from './components/_pages/404/404-page.container';
import { TemplatePageContainer } from './components/_pages/TemplatePage/template-page.container';

export enum ROUTE_PATHS {
  LANDING = '/',
  CONTACT_US = '/contact-us',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  DASHBOARD = '/dashboard',
  ONBOARDING = '/onboarding',
  CONTACTS = '/contacts',
  TEMPLATE = '/template',
  TEMPLATES = '/templates',
  LESSONS = '/lessons',
  CONNECT_AUTH0 = '/connect/0auth',
  PROFILE = '/profile',
  CLIENT_PROFILE = '/profile/contact',
  ENTITY_PROFILE = '/profile/company',
  FIRM_PROFILE = '/profile/organization',
  JOIN = '/join',
  JOIN_WITH_CODE = '/join/:code',
  INVITE = '/invite',
  VIEW_INVITE = '/invite/:code',
  REQUEST = '/request',
  REQUEST_GROUP = '/bulk',
  ASSIGNED_REQUEST = '/assigned-request',
  CREATE_REQUEST = '/requests/create',
  LOGIN = '/login',
  SELF_SERVE = '/self-serve'
}

export const ROUTES = [
  <Route element={<ToastProvider />}>
    <Route element={<AuthProvider />}>
      <Route element={<GraphqlProvider />}>
        <Route path={ROUTE_PATHS.LANDING} element={<LandingPageLayout />} />
        <Route
          path={ROUTE_PATHS.CONTACT_US}
          element={
            <DefaultPageLayout unauthenticatedMode fullSizeLogo>
              <ContactUsPage />
            </DefaultPageLayout>
          }
        />
        <Route
          path={ROUTE_PATHS.PRIVACY_POLICY}
          element={
            <DefaultPageLayout unauthenticatedMode fullSizeLogo>
              <PrivacyPolicyPage />
            </DefaultPageLayout>
          }
        />
        <Route
          path={ROUTE_PATHS.TERMS_AND_CONDITIONS}
          element={
            <DefaultPageLayout unauthenticatedMode fullSizeLogo>
              <TermsAndConditionsPage />
            </DefaultPageLayout>
          }
        />
        <Route
          path={ROUTE_PATHS.CONNECT_AUTH0}
          element={<InsetLayout unauthenticatedMode />}
          errorElement={<ErrorPage />}
        >
          <Route index element={<AuthRedirectPageContainer />} />
        </Route>
        <Route element={<AuthenticatedLayoutWrapper />}>
          <Route element={<InsetLayout />}>
            <Route path={ROUTE_PATHS.ONBOARDING} element={<OnboardingPage />} errorElement={<ErrorPage />} />
          </Route>
          <Route element={<InsetLayout padded />}>
            <Route path={ROUTE_PATHS.DASHBOARD} element={<DashboardPageContainer />} errorElement={<ErrorPage />} />
            <Route path={ROUTE_PATHS.CONTACTS} element={<ContactsPage />} errorElement={<ErrorPage />} />
            <Route path={ROUTE_PATHS.TEMPLATES} element={<TemplatesPage />} errorElement={<ErrorPage />} />
          </Route>
          <Route
            path={ROUTE_PATHS.REQUEST_GROUP}
            element={<DefaultPageLayoutWithOutlet light padded />}
            errorElement={<ErrorPage />}
          >
            <Route index element={<NoMatchPageContainer />} />
            <Route path={ROUTE_PATHS.REQUEST_GROUP + '/:groupId'} element={<BulkRequestPageContainer />} />
          </Route>
          <Route
            path={ROUTE_PATHS.REQUEST}
            element={<DefaultPageLayoutWithOutlet dark padded />}
            errorElement={<ErrorPage />}
          >
            <Route index element={<NoMatchPageContainer />} />
            <Route path={ROUTE_PATHS.REQUEST + '/:requestId'} element={<RequestPageAuthorizationWrapper />} />
          </Route>
          <Route
            path={ROUTE_PATHS.TEMPLATE}
            element={<DefaultPageLayoutWithOutlet dark padded />}
            errorElement={<ErrorPage />}
          >
            <Route index element={<NoMatchPageContainer />} />
            <Route path={ROUTE_PATHS.TEMPLATE + '/:templateId'} element={<TemplatePageContainer />} />
          </Route>
          <Route path={ROUTE_PATHS.PROFILE} element={<InsetLayout padded />} errorElement={<ErrorPage />}>
            <Route index element={<ProfilePageContainer />} />
            <Route path={ROUTE_PATHS.CLIENT_PROFILE + '/:clientId'} element={<ClientProfilePageContainer />} />
            <Route path={ROUTE_PATHS.ENTITY_PROFILE + '/:entityId'} element={<EntityProfilePageContainer />} />
            <Route path={ROUTE_PATHS.FIRM_PROFILE} element={<FirmProfilePageContainer />} />
          </Route>
        </Route>
        <Route
          path={ROUTE_PATHS.JOIN_WITH_CODE}
          element={<InsetLayout ignoreCompanyValidation ignoreForceRegister unauthenticatedMode />}
          errorElement={<ErrorPage />}
        >
          <Route index element={<JoinWithCodePageContainer />} />
        </Route>
        <Route
          path={ROUTE_PATHS.VIEW_INVITE}
          element={<InsetLayout ignoreCompanyValidation ignoreForceRegister unauthenticatedMode />}
          errorElement={<ErrorPage />}
        >
          <Route index element={<ViewInvitePageContainer />} />
        </Route>
        <Route
          path={ROUTE_PATHS.JOIN}
          element={<InsetLayout ignoreForceRegister unauthenticatedMode />}
          errorElement={<ErrorPage />}
        >
          <Route index element={<GetInviteByCodeContainer />} />
        </Route>
        <Route
          path={ROUTE_PATHS.ASSIGNED_REQUEST}
          element={<DefaultPageLayoutWithOutlet dark padded hideLogin />}
          errorElement={<ErrorPage />}
        >
          <Route index element={<NoMatchPageContainer />} />
          <Route
            path={ROUTE_PATHS.ASSIGNED_REQUEST + '/:requestId'}
            element={<RequestPageAuthorizationWrapper clientMode />}
          />
        </Route>
        <Route
          path={ROUTE_PATHS.LOGIN}
          element={
            <InsetLayout unauthenticatedMode>
              <LoginPage />
            </InsetLayout>
          }
        />
        <Route
          path={ROUTE_PATHS.SELF_SERVE}
          element={<DefaultPageLayoutWithOutlet padded unauthenticatedMode ignoreCompanyValidation fullSizeLogo />}
          errorElement={<ErrorPage />}
        >
          <Route index element={<NoMatchPageContainer />} />
          <Route path={ROUTE_PATHS.SELF_SERVE + '/:requestGroupId'} element={<SelfServePageContainer />} />
        </Route>
        <Route path="*" element={<NoMatchPageLayout />} />
      </Route>
    </Route>
  </Route>
];
