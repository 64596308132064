import { IEntity, IMemberOverview } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { extractFormOptionValue } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { Loader } from '../../../_core/loader.component';
import { useSendClientInvite } from '../../client-invite/client-invite.service';
import { useCompanyDepartments } from '../../departments/department.service';
import { useCompanyLocations } from '../../locations/location.service';
import { useSendInvites } from '../invite.service';
import { ISendClientInvitesProps, ISendStaffInvitesProps } from '../invite.types';
import { SendInvitesForm } from './send-invites-form.component';

export interface ISendInvitesContainerProps extends IPersonalCompanyProps {
  isClientInvite?: boolean;
  entity?: IEntity;
  members?: IMemberOverview[];
}

interface ISendInvitesNestedContainerProps extends IFormWithStepperProps, ISendInvitesContainerProps {}

export const SendInvitesNestedContainer = ({
  entity,
  isClientInvite,
  isPersonalCompany,
  onError,
  onSuccess,
  ...rest
}: ISendInvitesNestedContainerProps) => {
  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const { sendInvites } = useSendInvites();
  const { sendClientInvite } = useSendClientInvite();

  const handleSubmit = async ({
    roles,
    location,
    department,
    ...rest
  }: ISendClientInvitesProps | ISendStaffInvitesProps) => {
    try {
      if (isClientInvite && 'emails' in rest) {
        const results = await Promise.all(
          rest.emails.map((email) =>
            sendClientInvite({
              email,
              entityId: entity?._id,
              location: location.map(extractFormOptionValue),
              department: department.map(extractFormOptionValue)
            })
          )
        );

        if (results.some((r) => !r.data?.sendClientInvite.sentAt)) {
          onError?.('We were unable to deliver some of the invites');
          return;
        }
      } else if ('inviteData' in rest) {
        const { data } = await sendInvites({
          inviteData: rest.inviteData.map(({ value, label }) => ({ email: value, name: label })),
          roles: roles?.map(extractFormOptionValue) ?? [],
          location: location.map(extractFormOptionValue),
          department: department.map(extractFormOptionValue)
        });

        onSuccess?.(
          !data?.sendInvites.length
            ? 'All recipients are already invited or are already members'
            : data?.sendInvites.length === rest.inviteData.length
            ? 'Successfully sent invites!'
            : `Succeeded! Skipped sending ${
                rest.inviteData.length - (data?.sendInvites.length ?? 0)
              } invites, due to member or invite already existing.`
        );
      }
    } catch (err) {
      logError('Unable to send invites', (err as Error).message);
      onError?.('Unable to send invites');
    }
  };

  if (!departments || !locations) return <Loader />;

  return (
    <SendInvitesForm
      departments={departments}
      locations={locations}
      onSubmit={handleSubmit}
      isClientInvite={!!isClientInvite}
      isClientEntityInvite={!!entity}
      isPersonalCompany={isPersonalCompany}
      {...rest}
    />
  );
};
