import { useCallback, useState } from 'react';
import { inputClasses } from '../_core/editor.const';
import { RadioOptionsList } from './radio-options-list.component';

interface IRadioOptionsInputProps {
  blockId?: string;
  initialOptions?: string[];
  onUpdate: (_: string[]) => void;
  previewLabelPrefix?: string;
  tagClass: string;
}

export const RadioOptionsInput = ({
  blockId,
  initialOptions = [],
  onUpdate,
  previewLabelPrefix = 'Add',
  tagClass
}: IRadioOptionsInputProps) => {
  const [currInputValue, setCurrInputValue] = useState('');
  const [options, setOptions] = useState<string[]>(initialOptions);

  const handleEnter = useCallback(() => {
    if (currInputValue) {
      setOptions((prev) => {
        const newOptions = !prev.includes(currInputValue) ? [...prev, currInputValue] : prev;
        onUpdate(newOptions);
        return newOptions;
      });

      setCurrInputValue('');
    }
  }, [currInputValue, onUpdate]);

  return (
    <>
      <div className="w-full">
        <input
          className={`${inputClasses.join(' ')} w-full ${blockId ? 'input-' + blockId : ''}`}
          onChange={(e) => setCurrInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleEnter();
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          placeholder={'Add options'}
          type="text"
          value={currInputValue}
        />
        <RadioOptionsList options={options} onUpdate={onUpdate} setOptions={setOptions} tagClass={tagClass} />
      </div>
      <div className={currInputValue ? 'block' : 'hidden'}>
        <div className="absolute z-30 mt-2">
          {/* NOTE: No need for a typography usecase here yet, because text is so basic and custom and could be controlled with tailwind font customizations */}
          <div className="py-1 text-sm bg-white rounded shadow-lg border border-gray-300">
            <a
              className="block py-1 px-5 cursor-pointer hover:bg-indigo-600 hover:text-white"
              onClick={() => handleEnter()}
            >
              {!!previewLabelPrefix && previewLabelPrefix} "<span className="font-semibold">{currInputValue}</span>"
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
