import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_USER_INVITE_CODE } from './user-invite-code.queries';
import { Identifiable } from '../../../lib/types';
import { IGetUserInviteCodeProps } from './user-invite-code.types';

export const useLazyUserInviteCode = () => {
  const [query] = useLazyQuery<{ getUserInviteCode: Identifiable }, IGetUserInviteCodeProps>(GET_USER_INVITE_CODE, {
    fetchPolicy: 'no-cache'
  });

  const getUserInviteCode = useCallback((variables: IGetUserInviteCodeProps) => query({ variables }), [query]);

  return { getUserInviteCode };
};
