import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../_core/button/button-dropdown-option-component';
import { IRequestGroup, IRequestGroupOverview, REQUEST_STATUS } from '../../../lib/types';
import {
  useDeleteRequestGroup,
  useLazyRequestGroup,
  useRestoreRequestGroup,
  useUpdateRequestGroup
} from './request-group.service';
import { showError } from '../../../lib/utils';
import { ROUTE_PATHS } from '../../../_routes';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { EditRequestGroupContainer } from './edit/edit-request-group.container';
import { LinkIcon } from '@heroicons/react/20/solid';

export interface IRequestGroupActionProps {
  group: IRequestGroupOverview;
  hideViewAction?: boolean;
  last?: boolean;
  loading?: boolean;
  onResend?: () => void;
  onSend?: () => void;
  slim?: boolean;
}

export const RequestGroupActionButton: React.FC<IRequestGroupActionProps> = ({
  group,
  hideViewAction,
  last,
  loading,
  onResend,
  onSend,
  slim
}) => {
  const navigate = useNavigate();

  const [editGroup, setEditGroup] = useState<IRequestGroup | null>(null);

  const { getRequestGroup, loading: gettingRequestGroup } = useLazyRequestGroup(group);
  const {
    deleteRequestGroup,
    loading: deletingRequestGroup,
    ConfirmationDialog: DeleteConfirmationDialog
  } = useDeleteRequestGroup();

  const {
    restoreRequestGroup,
    loading: restoringRequestGroup,
    ConfirmationDialog: RestoreConfirmationDialog
  } = useRestoreRequestGroup();

  const {
    updateRequestGroup,
    loading: updatingRequestGroup,
    ConfirmationDialog: UpdateConfirmationDialog
  } = useUpdateRequestGroup(group);

  const editGroupAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () => getRequestGroup().then((g) => setEditGroup(g.data?.requestGroup ?? null)),
      text: 'Configure'
    }),
    [getRequestGroup]
  );

  const editTemplateAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        navigate(ROUTE_PATHS.TEMPLATE + '/' + group.template?._id, {
          state: { backPath: window.location.pathname + window.location.search }
        }),
      text: 'Edit Template'
    }),
    [navigate, group.template?._id]
  );

  const viewAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () => navigate(ROUTE_PATHS.REQUEST_GROUP + '/' + group._id),
      text: 'View'
    }),
    [navigate, group._id]
  );

  const updateStatusAction: IButtonDropdownOptionProps = useMemo(() => {
    const isClosed = group.status === REQUEST_STATUS.CLOSED;
    return {
      onClick: () => {
        updateRequestGroup({ status: isClosed ? REQUEST_STATUS.SENT : REQUEST_STATUS.CLOSED });
      },
      text: isClosed ? 'Re-open' : 'Complete'
    };
  }, [group.status, updateRequestGroup]);

  const updateLinkStatusAction: IButtonDropdownOptionProps = useMemo(() => {
    return {
      onClick: () => {
        updateRequestGroup({ canSelfServe: !group.canSelfServe });
      },
      text: group.canSelfServe ? 'Disable Self-Serve Link' : 'Enable Self-Serve Link'
    };
  }, [group.canSelfServe, updateRequestGroup]);

  const onRestoreRequest = useCallback(async () => {
    if (group.deletedAt)
      try {
        await restoreRequestGroup(group);
        toast.success(`Restored Bulk Request`);
      } catch (err) {
        showError('Unable to restore bulk request', err as Error);
      }
  }, [group, restoreRequestGroup]);

  const restoreRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreRequest, text: 'Restore' }),
    [onRestoreRequest]
  );

  const onDeleteRequest = useCallback(async () => {
    try {
      await deleteRequestGroup(group);
      toast.success(`Deleted Bulk Request`);
    } catch (err) {
      showError('Unable to delete bulk request', err as Error);
    }
  }, [deleteRequestGroup, group]);

  const deleteRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteRequest, text: 'Delete' }),
    [onDeleteRequest]
  );

  const sendAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onSend, text: 'Send New Requests' }),
    [onSend]
  );

  const resendAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        getRequestGroup().then(({ data }) => {
          if (data?.requestGroup.requests?.filter((r) => r.status === REQUEST_STATUS.SENT && !r.deletedAt))
            onResend?.();
        }),
      text: 'Resend Active Requests'
    }),
    [getRequestGroup, onResend]
  );

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] = [];
    if (!hideViewAction) {
      newOptions.push(viewAction);
      if (group.editable) newOptions.push(editGroupAction, editTemplateAction);
    }

    if (group.editable) {
      if (!group.deletedAt) {
        if (onSend) newOptions.push(sendAction);
        if (onResend) newOptions.push(resendAction);
        if (group.status && [REQUEST_STATUS.SENT, REQUEST_STATUS.CLOSED].includes(group.status))
          newOptions.push(updateStatusAction);

        newOptions.push(updateLinkStatusAction);
      }

      newOptions.push(group.deletedAt ? restoreRequestAction : deleteRequestAction);
    }

    return newOptions;
  }, [
    hideViewAction,
    group.editable,
    group.deletedAt,
    group.status,
    viewAction,
    editGroupAction,
    editTemplateAction,
    restoreRequestAction,
    deleteRequestAction,
    onSend,
    sendAction,
    onResend,
    resendAction,
    updateStatusAction,
    updateLinkStatusAction
  ]);

  return (
    <div className="flex items-center justify-between">
      {group.canSelfServe ? (
        <Button
          containerClass="max-h-7"
          slim={slim}
          icon={<LinkIcon height={24} width={24} />}
          size="fit"
          onClick={() => {
            navigator.clipboard.writeText(window.location.host + ROUTE_PATHS.SELF_SERVE + '/' + group._id);
            toast.success('Copied self-serve link');
          }}
          variant="secondary"
        />
      ) : (
        <div />
      )}
      <Button
        slim={slim}
        dropdown={{ options, up: last }}
        text="ACTION"
        size="large"
        loading={
          loading ||
          deletingRequestGroup ||
          restoringRequestGroup ||
          updatingRequestGroup ||
          !!editGroup ||
          gettingRequestGroup
        }
      />
      {!!editGroup && (
        <RIDialog setOpen={(o) => setEditGroup((g) => (o ? g : null))}>
          <EditRequestGroupContainer group={editGroup} close={() => setEditGroup(null)} />
        </RIDialog>
      )}
      <DeleteConfirmationDialog />
      <RestoreConfirmationDialog />
      <UpdateConfirmationDialog />
    </div>
  );
};
