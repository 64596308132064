import { useMemo } from 'react';

import { Button } from '../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../_core/button/button-dropdown-option-component';
import { IRequestGroupOverview, IRequestOverview } from '../../../lib/types';
import { useLazyRequestGroup, useUpdateRequestGroup } from '../request-group/request-group.service';

export interface IUndeliveredRequestActionProps {
  disabled?: boolean;
  last?: boolean;
  loading?: boolean;
  onSendRequest?: () => void;
  request: IRequestOverview;
  requestGroup: IRequestGroupOverview;
  slim?: boolean;
}

export const UndeliveredRequestActionButton: React.FC<IUndeliveredRequestActionProps> = ({
  disabled,
  last,
  loading,
  onSendRequest,
  request,
  requestGroup,
  slim
}) => {
  const { getRequestGroup } = useLazyRequestGroup(requestGroup);

  const { updateRequestGroup, loading: updatingRequestGroup } = useUpdateRequestGroup({
    _id: request.requestGroup?._id ?? ''
  });

  const deleteRequestAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      onClick: () =>
        getRequestGroup().then(({ data }) => {
          if (data?.requestGroup)
            return updateRequestGroup({
              assignedClients: data.requestGroup.assignedClients
                .filter((c) => c._id === request.assignedTo?._id)
                .map(({ _id }) => _id)
            });
        }),
      text: 'Delete'
    }),
    [getRequestGroup, request.assignedTo?._id, updateRequestGroup]
  );

  const sendAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onSendRequest, text: 'Send Request' }),
    [onSendRequest]
  );

  return (
    <Button
      disabled={disabled}
      slim={slim}
      dropdown={{ options: [sendAction, deleteRequestAction], up: last }}
      text="ACTION"
      size="large"
      loading={loading || updatingRequestGroup}
    />
  );
};
