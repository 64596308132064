import { windowMaxWidth } from '../../_layouts/consts';

import './info-page.css';

export default function PrivacyPolicyPage() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center self-end">
      <div className={'info-page mx-auto w-full p-4 flex flex-1 flex-col m-6 bg-white rounded-2xl ' + windowMaxWidth}>
        <h1>Privacy Agreement for Request IQ Inc.</h1>
        <h2>Effective Date: January 1, 2025</h2>
        <p>
          This Privacy Agreement (the "Agreement") outlines the terms and conditions under which Request IQ Inc.
          ("Request IQ," "we," "our," or "us") collects, stores, processes, and protects user data. By using the Request
          IQ platform (the "Platform"), you ("User," "you," or "your") agree to the terms set forth in this Agreement.
        </p>
        <h2>1. Purpose and Scope</h2>
        <p>
          Request IQ Inc. provides Software as a Service (SaaS) that includes document management and storage solutions.
          The Platform is designed to manage confidential client financial data, which is crucial for ensuring the
          protection and integrity of your business’s sensitive information.
        </p>
        <h2>2. Data Collection</h2>
        <p>
          Request IQ Inc. collects and processes data related to the use of the Platform, including but not limited to:
          <ul>
            <li>User account details (e.g., name, email, role, etc.).</li>
            <li>Document and financial data uploaded, stored, or shared via the Platform.</li>
            <li>
              User activity data, including login history, access times, actions performed within the Platform, and
              interactions with the Platform's features.
            </li>
          </ul>
        </p>
        <h2>3. Data Storage and Processing</h2>
        <p>
          <ul>
            <li>
              <b>Location of Storage:</b> Your data will be stored in servers located in the United States of America,
              and as such, is subject to U.S. laws and regulations.
            </li>
            <li>
              <b>Data Protection:</b> Request IQ Inc. takes the security of your data seriously. We implement
              industry-standard measures to protect against unauthorized access, alteration, or disclosure of data
              stored within the Platform.
            </li>
            <li>
              <b>Access and Processing:</b> Data stored within the Platform may be processed by Request IQ Inc. for
              purposes including, but not limited to, maintaining the Platform's functionality, performing
              administrative tasks, and providing customer support.
            </li>
          </ul>
        </p>
        <h2>4. User Activity Tracking and Data Inspection</h2>
        <p>
          Request IQ Inc. retains the right to track and monitor user activity within the Platform. This may include:
        </p>
        <ul>
          <li>Tracking login and usage activity to ensure secure access to the Platform.</li>
          <li>
            Monitoring actions performed by users to optimize the functionality of the Platform and improve the user
            experience.
          </li>
          <li>
            Inspecting data, including user-uploaded documents, for the purpose of ensuring compliance with applicable
            policies, detecting misuse or breaches, and conducting troubleshooting or maintenance.
          </li>
        </ul>
        <h2>5. Confidentiality of Data</h2>
        <ul>
          <li>
            <b>Client Data:</b> Request IQ Inc. is committed to safeguarding the confidentiality of client data,
            including financial information, and will not disclose such data to third parties unless required by law or
            explicitly authorized by you.
          </li>
          <li>
            <b>Third-Party Services:</b> We may use third-party services for the provision of specific features or
            functionalities within the Platform. These third-party services will be subject to their respective privacy
            policies, and we will ensure that any such services comply with our privacy and data protection standards.
          </li>
        </ul>
        <h2>6. User Responsibilities</h2>
        <p>As a User of the Platform, you are responsible for:</p>
        <ul>
          <li>Keeping your login credentials and account information confidential and secure.</li>
          <li>
            Ensuring that the data you upload and store within the Platform complies with applicable laws and
            regulations.
          </li>
          <li>
            Not uploading or sharing data that violates any laws, including data that is illegal, obscene, or violates
            third-party rights.
          </li>
        </ul>
        <h2>7. Data Retention</h2>
        <p>
          Request IQ Inc. retains the right to store data on the Platform for as long as necessary for the purposes of
          providing services, fulfilling legal obligations, and maintaining business records. Upon termination or
          cancellation of your account, Request IQ Inc. may retain certain data as required by law or for legitimate
          business purposes.
        </p>
        <h2>8. Data Subject Rights</h2>
        <p>
          If you are a resident of Canada or another jurisdiction with applicable data protection laws, you may have
          certain rights regarding your data, including:
        </p>
        <ul>
          <li>The right to access the personal data we hold about you.</li>
          <li>The right to request corrections to any inaccuracies in your data.</li>
          <li>The right to request deletion of your data, subject to legal and contractual obligations.</li>
        </ul>
        <p className="mt-2">
          Requests for access, correction, or deletion of data may be submitted to our privacy team at
          Kevin@RequestIQ.com.
        </p>
        <h2>9. Data Transfers and Third-Party Disclosures</h2>
        <p>
          Due to the storage of your data in the United States, your data may be subject to laws in the U.S. that differ
          from Canadian laws. By using the Platform, you consent to the transfer of data across borders.
        </p>
        <p className="mt-2">We may disclose user data to third parties only in the following circumstances:</p>
        <ul>
          <li>When required to do so by law or legal process.</li>
          <li>To comply with legal obligations or governmental requests.</li>
          <li>To protect our legal rights, property, or safety, or the rights, property, or safety of others.</li>
        </ul>
        <h2>10. Limitation of Liability</h2>
        <p>
          Request IQ Inc. will not be liable for any indirect, incidental, special, or consequential damages arising
          from your use of the platform. Our liability to you will not exceed the amount you paid for the service in the
          past six months.
        </p>
        <h2>11. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless Request IQ Inc., its officers, directors, employees, and agents from
          any claims, damages, or liabilities arising from your use of the platform, including any breach of this
          Privacy Agreement.
        </p>
        <h2>12. Changes to This Agreement</h2>
        <p>
          Request IQ Inc. reserves the right to amend, modify, or update this Privacy Agreement at any time. Any changes
          will be effective immediately upon posting the updated Privacy Agreement to the Platform. We will notify users
          of material changes via email or through the Platform.
        </p>
        <h2>13. Governing Law</h2>
        <p>
          This Privacy Agreement is governed by the laws of the Province of Manitoba, Canada. Any disputes arising from
          this Agreement will be resolved in the courts located in Manitoba.
        </p>
        <h2>14. Contact Information</h2>
        <p className="mb-2">
          If you have any questions, concerns, or requests regarding this Privacy Agreement or the privacy practices of
          Request IQ Inc., please contact us at:
        </p>
        <p>
          Email: <a href="mailto:kevin@requestiq.com">Kevin@RequestIQ.com</a>
        </p>
        <p>
          Phone: <a href="tel:+12049902825">+1 (204) 990-2825</a>
        </p>
        <h2>
          <b>Effective Date:</b> January 1, 2025
        </h2>
        <p>
          By using the Platform, you acknowledge that you have read, understood, and agreed to this Privacy Agreement.
        </p>
      </div>
    </div>
  );
}
