import { useSearchParams } from 'react-router-dom';
import { Deletable, Nameable } from '../../../../lib/types';
import { ISortIdentifiable, SortedKeys, SortMethod, SortMethodTuple } from '../table.types';
import { useCallback, useState } from 'react';
import moment from 'moment';

// Static functions
export const nameAndStatusSort = <T extends Nameable & Deletable>(a: T, b: T) => {
  // Sort by status, and then name
  if (a.deletedAt && !b.deletedAt) return 1;
  if (!a.deletedAt && b.deletedAt) return -1;

  if (a.name && !b.name) return -1;
  if (!a.name && b.name) return 1;

  return (a.name ?? '').localeCompare(b.name ?? '');
};

// Null if no return action should be based on result
export const truthySort = (a: unknown, b: unknown, isDesc: boolean) => {
  if (!a && !b) return 0;
  if (a && !b) return isDesc ? -1 : 1;
  if (!a && b) return isDesc ? 1 : -1;

  return null;
};

export const stringSort = (a: string, b: string, isDesc: boolean) => (isDesc ? a.localeCompare(b) : b.localeCompare(a));

export const truthyStringSort = (isDesc: boolean, a?: string, b?: string) => {
  const result = truthySort(a, b, isDesc);
  if (result !== null) return result;
  return stringSort(String(a), String(b), isDesc);
};

export const sortMethodTuple = <T extends object>(
  sortMethod: SortMethod<T> = 'string',
  sortKeyExtractor?: (__: unknown) => unknown
) => [sortMethod, sortKeyExtractor] as SortMethodTuple<T>;

// Hooks
export const useTableSortConfig = <T extends object, U extends object>({
  baseValueExtractor,
  defaultSortFunc,
  sortMethods
}: {
  baseValueExtractor?: (_: T) => U | undefined;
  defaultSortFunc?: (a: T, b: T) => number;
  sortMethods: Record<string, SortMethodTuple<U>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortedKeys, setSortedKeys] = useState<SortedKeys>(
    // Initialize sorted keys with valid search param values
    searchParams.getAll('sort').reduce((acc: SortedKeys, curr) => {
      const split = curr.split(',');
      if (split.length > 1 && (split[1] === 'asc' || split[1] === 'desc')) acc[split[0]] = split[1];

      return acc;
    }, {})
  );

  const onSortSelect = useCallback(
    ({ sortKey }: ISortIdentifiable) => {
      if (sortKey) {
        // Toggle sorted keys between "desc" -> "asc" -> "off", store in order clicked
        const newSortedKeys = { ...sortedKeys };
        if (sortKey in newSortedKeys) {
          if (newSortedKeys[sortKey] === 'desc') newSortedKeys[sortKey] = 'asc';
          else if (newSortedKeys[sortKey] === 'asc') delete newSortedKeys[sortKey];
        } else {
          newSortedKeys[sortKey] = 'desc';
        }

        setSortedKeys(newSortedKeys);

        const newValue = newSortedKeys[sortKey];
        setSearchParams((prev) => {
          const prevSortKeyValue = prev.getAll('sort').find((v) => v.startsWith(sortKey));

          // Remove original search param regardless, and replace if necessary
          if (prevSortKeyValue) prev.delete('sort', prevSortKeyValue);
          if (newValue) prev.append('sort', `${sortKey},${newValue}`);
          return prev;
        });
      }
    },
    [setSearchParams, sortedKeys]
  );

  const sortFunc = useCallback(
    (a: T, b: T) => {
      const selectedSortFunc = (a: unknown, b: unknown) => {
        // Iterate over each selected sort key and determine first non-equal sort result
        const sortDirectionsAndKeys = Object.entries(sortedKeys);
        for (let i = 0; i < sortDirectionsAndKeys.length; i++) {
          // Get sort method and direction for sort key
          const [key, sorted] = sortDirectionsAndKeys[i];
          const [sortMethod, sortKeyExtractor]: SortMethodTuple<U> =
            key in sortMethods ? sortMethods[key] : ['default', undefined];

          const isDesc = sorted === 'desc';

          const aBaseValue = (baseValueExtractor?.(a as T) ?? a) as object;
          const bBaseValue = (baseValueExtractor?.(b as T) ?? b) as object;

          // @ts-expect-error Expected error indexing baseValue with key
          let aValue = sortKeyExtractor?.(aBaseValue[key]) ?? aBaseValue[key];
          // @ts-expect-error Expected error indexing baseValue with key
          let bValue = sortKeyExtractor?.(bBaseValue[key]) ?? bBaseValue[key];

          // Default sorts based on truthiness of value
          const truthyResult = truthySort(aValue, bValue, isDesc);
          if (truthyResult !== null) return truthyResult;

          // Default sort methods
          if (typeof sortMethod === 'string') {
            // Compare strings
            if (sortMethod === 'string') {
              const stringResult = stringSort(aValue, bValue, isDesc);
              if (stringResult) return stringResult;
            }

            // Default value compares, but properly assign value types first if necessary
            if (sortMethod === 'date') {
              aValue = moment(aValue);
              bValue = moment(bValue);
            }

            const defaultResult = isDesc ? bValue - aValue : aValue - bValue;
            if (defaultResult) return defaultResult;
          }
          // Custom sort method
          else {
            const customResult = sortMethod(a as U, b as U, sorted);
            if (customResult) return customResult;
          }
        }

        // No sort methods were determined to find a sort difference
        return 0;
      };

      // Main thread
      const sorted = selectedSortFunc(a, b);
      if (sorted) return sorted;

      // Fallback to optional default sort if selected sort is evaluated as equal
      if (defaultSortFunc) return defaultSortFunc(a, b);
      return 0;
    },
    [baseValueExtractor, defaultSortFunc, sortMethods, sortedKeys]
  );

  return { onSortSelect, sortedKeys, sortFunc };
};
