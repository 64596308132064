import { ILocation } from '../../../lib/types';
import { ICompanyLocationsResult } from './location.types';
import { GET_COMPANY_LOCATIONS } from './location.queries';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { mergeCacheLists } from '../../../lib/utils';
import { IArchivableProps } from '../../../lib/query.types';

export const updateLocationInCache = ({ cache, location }: IApolloCacheParams & { location: ILocation }) => {
  const currCompanyLocations = cache.readQuery<ICompanyLocationsResult, IArchivableProps>({
    query: GET_COMPANY_LOCATIONS,
    variables: { includeArchived: true }
  })?.locations;

  cache.writeQuery<ICompanyLocationsResult, IArchivableProps>({
    query: GET_COMPANY_LOCATIONS,
    data: { locations: mergeCacheLists(currCompanyLocations ?? [], [location]) },
    variables: { includeArchived: true },
    overwrite: true
  });
};
