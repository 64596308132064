import { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../_routes';
import Logo from '../../../../assets/imgs/logoSecondaryPrimary.png';

const FooterLink = ({ to, title }: { to: ROUTE_PATHS; title: string }) => {
  return (
    <li className="inline-block mr-4">
      <NavLink className="no-underline hover:underline text-gray-800 hover:text-pink-500" to={to}>
        {title}
      </NavLink>
    </li>
  );
};

const FooterLinkList = ({ children, title }: PropsWithChildren & { title: string }) => {
  return (
    <div className="flex-1 mb-1">
      <p className="uppercase text-gray-500">{title}</p>
      <ul className="list-disc">{children}</ul>
    </div>
  );
};

export const Footer = () => {
  return (
    <footer className="bg-white mt-auto">
      <div className="container mx-auto">
        <div className="w-full flex flex-col md:flex-row py-1">
          <div className="flex-1 mb-1 text-black">
            <img className="max-w-48" src={Logo} />
          </div>
          <FooterLinkList title="Legal">
            <FooterLink to={ROUTE_PATHS.TERMS_AND_CONDITIONS} title="Terms & Conditions" />
            <FooterLink to={ROUTE_PATHS.PRIVACY_POLICY} title="Privacy Policy" />
          </FooterLinkList>
          <FooterLinkList title="Company">
            <FooterLink to={ROUTE_PATHS.CONTACT_US} title="Contact Us" />
          </FooterLinkList>
        </div>
      </div>
    </footer>
  );
};
