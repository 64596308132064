import { FastFieldProps } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';

import { FormTagsInput, IFormTagsInputProps } from './form-tags-input.component';
import { FormMultiSelectInput } from './form-multiselect-input.component';
import { FormSelectInput, IFormSelectInputProps } from './form-select-input.component';
import { IMultiSelectInputProps } from '../../input/multiselect-input.component';
import { InputType } from '../../input/input.type';
import { getInputClasses } from '../../input/input-utils';
import { useCallback, useMemo } from 'react';

export interface IFormInputProps {
  containerClass?: string;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  setFieldValue?: (field: string, value: unknown) => void;
  type?: InputType;

  // Input specific props
  multiSelectProps?: IMultiSelectInputProps;
  selectProps?: IFormSelectInputProps;
  tagsProps?: IFormTagsInputProps;
}

export interface IFastFieldFormInputProps extends FastFieldProps, Omit<IFormInputProps, 'name'> {}

export const FormInput = ({
  containerClass,
  multiSelectProps,
  selectProps,
  tagsProps,
  type = 'text',
  field,
  form,
  meta,
  ...rest
}: IFastFieldFormInputProps) => {
  const { setFieldValue } = form;
  const { name } = field;
  const { error, touched, value } = meta;

  const props = useMemo(() => ({ ...rest, id: name, name }), [name, rest]);

  const syncSetFieldValue = useCallback((f: string, v: unknown) => setFieldValue(f, v), [setFieldValue]);

  const errorMessage = useMemo(
    () => (!!error && touched ? <span className="my-1.5 text-red-500 text-sm">{error}</span> : undefined),
    [error, touched]
  );
  const isCheckbox = useMemo(() => type === 'checkbox', [type]);

  const { finalContainerClass, inputClasses } = getInputClasses({
    containerClass,
    hasError: !!error && touched,
    type
  });

  const drawTextInput = useCallback(() => {
    const finalProps = {
      ...field,
      type,
      checked: isCheckbox ? !!meta.value : undefined,
      className: inputClasses,
      value: type === 'date' && meta.value ? moment(meta.value).toDate().toLocaleDateString('en-CA') : meta.value
    };

    if (type === 'textarea') return <textarea {...finalProps} />;
    return <input {...finalProps} />;
  }, [field, type, isCheckbox, meta.value, inputClasses]);

  if (type === 'tags')
    return (
      <FormTagsInput
        {...field}
        {...tagsProps}
        setFieldValue={syncSetFieldValue}
        {...props}
        inputClasses={inputClasses}
        error={errorMessage}
        containerClass={finalContainerClass}
      />
    );
  else if (type === 'multiselect')
    return (
      <div className="w-full">
        <FormMultiSelectInput
          setFieldValue={syncSetFieldValue}
          {...props}
          {...multiSelectProps}
          inputClasses={inputClasses}
          error={errorMessage}
          containerClass={finalContainerClass}
        />
      </div>
    );
  else if (type === 'select')
    return (
      <div className="w-full">
        <FormSelectInput
          setFieldValue={syncSetFieldValue}
          {...props}
          {...selectProps}
          inputClasses={inputClasses}
          error={errorMessage}
          containerClass={finalContainerClass}
        />
      </div>
    );

  return (
    <div className={finalContainerClass}>
      {type === 'phone' ? (
        <PhoneInput
          inputStyle={{
            width: '100%',
            border: '1px solid rgb(107, 114, 128)',
            height: '36px',
            borderWidth: '0px',
            boxShadow:
              'rgb(255, 255, 255) 0px 0px 0px 0px inset, rgb(209, 213, 219) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
            boxSizing: 'border-box'
          }}
          country={'ca'}
          value={value}
          onChange={(value) => syncSetFieldValue && syncSetFieldValue(name, value)}
        />
      ) : (
        drawTextInput()
      )}
      {errorMessage}
    </div>
  );
};
