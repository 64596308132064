import { Form, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { PropsWithChildren, useMemo } from 'react';
import { ISendCustomRequestMessageParams } from '../../request/request.types';
import { IRequest, REQUEST_NOTIFY_OPTION, requestNotifyOptionMultiselectLabels } from '../../../../lib/types';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';
import _ from 'lodash';

export interface ISendCustomRequestMessageFormParams
  extends Omit<ISendCustomRequestMessageParams, '_id' | 'notify' | 'to'> {
  notify?: IMultiSelectOption[];
  to?: IMultiSelectOption[];
  groupsToggle?: boolean;
}

interface ISendRequestMessageFormFieldsParams
  extends FormikProps<ISendCustomRequestMessageFormParams>,
    PropsWithChildren {
  clientMode?: boolean;
  forceSendAll?: boolean;
  request: IRequest;
  userId: string;
}

export const SendRequestMessageFormFields = ({
  children,
  clientMode,
  forceSendAll,
  request,
  userId,
  values
}: ISendRequestMessageFormFieldsParams) => {
  const toOptions = useMemo(() => {
    const options: IMultiSelectOption[] = [];

    if (!clientMode) {
      if (request.assignedTo && request.assignedTo.user._id !== userId)
        options.push({
          label: request.assignedTo?.name ?? request.assignedTo.user.name,
          secondaryLabel: 'Assignee - ' + request.assignedTo.user.email,
          value: request.assignedTo?._id,
          group: 'Contacts'
        });

      options.push(
        ...[...(request.clientEditorIds ?? []), ...(request.clientViewerIds ?? [])]
          .filter(({ user }) => user._id !== userId)
          .map(({ _id, user, name }) => ({
            label: name ?? user.name,
            secondaryLabel: user.email,
            value: _id,
            group: 'Contacts'
          }))
      );
    }

    options.push({
      label: request.createdBy.name,
      value: request.createdBy._id,
      group: 'Team Members'
    });

    options.push(
      ...[...(request.staffEditorIds ?? []), ...(request.staffViewerIds ?? [])]
        .filter(({ user }) => user._id !== userId)
        .map(({ _id, user }) => ({
          label: user.name,
          secondaryLabel: user.email,
          value: _id,
          group: 'Team Members'
        }))
    );

    return _.uniqBy(options, ({ value }) => value).filter(({ value }) => value !== userId);
  }, [
    clientMode,
    request.assignedTo,
    request.clientEditorIds,
    request.clientViewerIds,
    request.createdBy._id,
    request.createdBy.name,
    request.staffEditorIds,
    request.staffViewerIds,
    userId
  ]);

  return (
    <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
      {!forceSendAll && (
        <>
          {!clientMode && <FormInputWithLabel name="groupsToggle" label="Send to groups" type="checkbox" />}
          {values.groupsToggle ? (
            <FormInputWithLabel
              name="notify"
              label="To:"
              placeholder="Notify groups..."
              type="multiselect"
              multiSelectProps={{
                options: [
                  {
                    label: requestNotifyOptionMultiselectLabels[REQUEST_NOTIFY_OPTION.CLIENTS],
                    value: REQUEST_NOTIFY_OPTION.CLIENTS
                  },
                  {
                    label: requestNotifyOptionMultiselectLabels[REQUEST_NOTIFY_OPTION.STAFF],
                    value: REQUEST_NOTIFY_OPTION.STAFF
                  }
                ],
                selectAll: true
              }}
            />
          ) : (
            <FormInputWithLabel
              name="to"
              label="To:"
              type="multiselect"
              placeholder="To"
              multiSelectProps={{
                options: toOptions,
                groupOrder: clientMode ? undefined : ['Contacts', 'Team Members']
              }}
            />
          )}
        </>
      )}
      <FormInputWithLabel name="replyTo" label="Reply To:" type="email" placeholder="Enter email" />
      <FormInputWithLabel
        name="subject"
        label="Subject:"
        type="text"
        placeholder="You have received a new message on one of your Request's"
      />
      <FormInputWithLabel name="message" label="Message:" placeholder="Enter your message here..." type="textarea" />
      {children}
    </Form>
  );
};
