import { InternalRefetchQueriesInclude, makeVar, useMutation, useQuery } from '@apollo/client';

// Queries
import {
  EDIT_COMPANY_REQUEST_TYPES,
  GET_CURR_COMPANY,
  GET_USER_COMPANIES,
  REGISTER_COMPANY,
  TRANSFER_OWNERSHIP,
  UPDATE_COMPANY
} from './company.queries';
import { ICompany, ICompanyOverview, IMemberOverview, PLAN_NAME } from '../../../lib/types';
import { useCallback } from 'react';
import { GET_CURR_USER } from '../users/user.queries';
import { ICurrCompanyResult, IRegisterCompanyProps, IUpdateCompanyProps, IUserCompaniesResult } from './company.types';
import { GET_SELECTED_REQUEST_TYPES } from '../request-type/request-type.queries';
import { ICurrUserResults } from '../users/user.types';
import { logError, mergeCacheLists } from '../../../lib/utils';
import toast from 'react-hot-toast';
import { readUserCompaniesCache, wipeCurrCompanyCache, writeUserCompaniesCache } from './company.utils';
import { IArchivableProps } from '../../../lib/query.types';
import { CURR_COMPANY_REFETCH_QUERIES } from './company.const';
import { updateMemberCache } from '../member/member.utils';

export const activeCompanyLoadingVar = makeVar<boolean>(false);
export const companyIdVar = makeVar<string | null>(null);
export const useCurrCompany = () => {
  const { data, ...rest } = useQuery<ICurrCompanyResult>(GET_CURR_COMPANY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => companyIdVar(result.company?._id ?? null)
  });

  return {
    ...rest,
    company: data?.company ?? null,
    isPersonal: data?.company.plan.name === PLAN_NAME.PERSONAL
  };
};

export const useEditCompanyRequestTypes = () => {
  const [mutation, rest] = useMutation<{ setCompanyRequestTypes: ICompany }>(EDIT_COMPANY_REQUEST_TYPES, {
    context: { serializationKey: 'MUTATION', tracked: true },
    refetchQueries: [{ query: GET_SELECTED_REQUEST_TYPES }],
    update: (cache, { data }) => {
      // Update user companies query with newly created company
      const currUserCompanies = readUserCompaniesCache(cache);
      if (data?.setCompanyRequestTypes) {
        writeUserCompaniesCache(
          cache,
          mergeCacheLists<ICompanyOverview>(currUserCompanies.companies, [data.setCompanyRequestTypes])
        );

        cache.writeQuery({ query: GET_CURR_COMPANY, data: { company: data.setCompanyRequestTypes }, overwrite: true });
      }
    }
  });

  const editRequestTypes = useCallback(
    (requestTypeIDs: string[]) => mutation({ variables: { requestTypeIDs } }),
    [mutation]
  );

  return { editRequestTypes, ...rest };
};

export const useRegisterCompany = () => {
  const [mutation, rest] = useMutation<{ registerCompany: ICompany }>(REGISTER_COMPANY, {
    context: { serializationKey: 'MUTATION', tracked: true },
    refetchQueries: CURR_COMPANY_REFETCH_QUERIES,
    awaitRefetchQueries: true,
    update: (cache, { data }) => {
      // Update user companies query with newly created company
      const newData = readUserCompaniesCache(cache);

      if (data?.registerCompany) {
        writeUserCompaniesCache(cache, [...newData.companies, data.registerCompany]);

        // Update curr user query's active company ID
        const loggedInUser = cache.readQuery<ICurrUserResults>({ query: GET_CURR_USER })?.loggedInUser;
        if (loggedInUser) {
          cache.writeQuery({
            query: GET_CURR_USER,
            data: {
              loggedInUser: {
                ...loggedInUser,
                settings: { ...loggedInUser.settings, activeCompanyId: data.registerCompany._id }
              }
            },
            overwrite: true
          });
        }

        // Wipe curr company queries, because they will be empty now after new company registration
        cache.writeQuery({
          query: GET_CURR_COMPANY,
          data: { company: data.registerCompany } as ICurrCompanyResult,
          overwrite: true
        });
        wipeCurrCompanyCache(cache);
      }
    }
  });

  const registerCompany = useCallback(
    (variables: IRegisterCompanyProps) => {
      activeCompanyLoadingVar(true);
      mutation({ variables }).finally(() => activeCompanyLoadingVar(false));
    },
    [mutation]
  );

  return { registerCompany, ...rest };
};

export const useUpdateCompany = (refetchQueries?: InternalRefetchQueriesInclude) => {
  const [mutation, rest] = useMutation<{ updateCompany: ICompany }>(UPDATE_COMPANY, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateCompany) {
        // Update user companies query with newly updated company
        const currUserCompanies = readUserCompaniesCache(cache);
        writeUserCompaniesCache(cache, mergeCacheLists(currUserCompanies.companies, [data.updateCompany]));

        cache.writeQuery({
          query: GET_CURR_COMPANY,
          data: { company: data.updateCompany } as ICurrCompanyResult,
          overwrite: true
        });
      }
    },
    onError: (err) => {
      const errMsg = 'Unable to update organization.';
      logError(errMsg, (err as Error).message);
      toast.error(errMsg);
    },
    refetchQueries,
    awaitRefetchQueries: true
  });

  const updateCompany = useCallback(
    async (variables: IUpdateCompanyProps) => {
      if (refetchQueries) activeCompanyLoadingVar(true);
      return mutation({ variables }).finally(() => {
        if (refetchQueries) activeCompanyLoadingVar(false);
      });
    },
    [mutation, refetchQueries]
  );

  return { updateCompany, ...rest };
};

export const useTransferOwnership = () => {
  const [mutation, rest] = useMutation<{ transferOwnership: IMemberOverview[] }>(TRANSFER_OWNERSHIP, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.transferOwnership) updateMemberCache({ cache, members: data.transferOwnership });
    }
  });

  const transferOwnership = useCallback((memberId: string) => mutation({ variables: { memberId } }), [mutation]);

  return { transferOwnership, ...rest };
};

export const useUserCompanies = ({ includeArchived = true, skip = false }: IArchivableProps & { skip?: boolean }) => {
  const { data, ...rest } = useQuery<IUserCompaniesResult, IArchivableProps>(GET_USER_COMPANIES, {
    fetchPolicy: 'cache-and-network',
    variables: { includeArchived },
    skip
  });

  return { ...rest, ...data, activeCompanies: data?.companies.filter(({ deletedAt }) => !deletedAt) };
};
