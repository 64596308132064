import { GlobeAltIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '../tooltip.component';

export const GlobalIcon = ({
  margin = 'md',
  size = 20,
  text
}: {
  margin?: 'sm' | 'md';
  size?: number;
  text?: string;
}) => (
  <Tooltip singleLine text={text} direction="top" distance="sm">
    <GlobeAltIcon width={size} height={size} className={`text-blue-400 ${margin === 'md' ? 'mr-2' : 'mr-1'}`} />
  </Tooltip>
);
