import { createRoot } from 'react-dom/client';
import { QuestionType } from '../../question/question.types';
import { inputClasses, inputClassesLineHeightStr, underlinedInputClasses } from '../editor.const';
import { onReadonlyInputKeydown } from './input.utils';
import { AutoSizeTextArea } from '../autosizetextarea.component';
import { InputAppearanceVariant } from '../types';

export const renderInputPrefix = (prefix: string) => {
  const labelPrefix = document.createElement('span');
  labelPrefix.classList.add(
    'inline-flex',
    'items-center',
    'justify-center',
    'px-3',
    'text-gray-900',
    'bg-gray-200',
    'border',
    'rounded-s-md',
    'border-gray-300',
    'h-8',
    ...inputClassesLineHeightStr.split(' ')
  );
  labelPrefix.textContent = prefix;

  return labelPrefix;
};

export const createAnswerInput = ({
  addOnEdit,
  blockId,
  disabled,
  id,
  onAnswerChange,
  options,
  placeholder = '< Enter answer >',
  prefix,
  readOnly,
  type,
  value,
  variant = 'box',
  width = 'w-full'
}: {
  addOnEdit?: { onInsertToDOM: (_: HTMLDivElement) => void; element: JSX.Element };
  blockId?: string;
  disabled?: boolean;
  id?: string;
  onAnswerChange: (_: EventTarget | null) => void;
  options?: {
    [QuestionType.NUMBER]?: { min: number; max: number; step?: number };
  };
  placeholder?: string;
  prefix?: string;
  readOnly?: boolean;
  type: QuestionType;
  value?: string;
  variant?: InputAppearanceVariant;
  width?: 'w-full' | 'w-48';
}) => {
  const answerTopContainer = document.createElement('div');
  answerTopContainer.classList.add('answer-container', 'w-full', 'inline-flex', 'gap-4', 'items-start');

  const answerContainer = document.createElement('div');
  answerContainer.classList.add('flex', 'w-full');

  const inputWrapper = document.createElement('div');
  inputWrapper.classList.add('flex', width);

  let answerInput: HTMLInputElement | null;
  if (type === QuestionType.TEXTAREA || type === QuestionType.TEXT) {
    answerInput = null;
    const textAreaRoot = createRoot(answerContainer);
    textAreaRoot.render(
      <AutoSizeTextArea
        data-private
        id={id}
        className={`answer-input ${blockId ? 'input-' + blockId : ''}`}
        disabled={disabled}
        hasPrefix={!readOnly}
        initialValue={value ?? ''}
        onChange={(v) => onAnswerChange(v)}
        placeholder={placeholder}
        variant={variant}
      >
        {!readOnly && addOnEdit?.element}
      </AutoSizeTextArea>
    );
  } else {
    const tempAnswerInput: HTMLInputElement = document.createElement('input');
    if (type === QuestionType.CURRENCY) {
      tempAnswerInput.type = 'number';
      if (readOnly) {
        tempAnswerInput.classList.add('rounded-l-none');

        const prefix = document.createElement('span');
        prefix.classList.add(
          'inline-flex',
          'items-center',
          'px-3',
          'text-sm',
          'text-gray-900',
          'bg-gray-200',
          'border',
          'rounded-s-md',
          'border-gray-300'
        );
        prefix.textContent = '$';
        answerContainer.appendChild(prefix);
      }
    } else if (type === QuestionType.DATE) {
      tempAnswerInput.type = 'date';
    } else if (type === QuestionType.EMAIL) {
      tempAnswerInput.type = 'email';
      if (variant === 'underline') tempAnswerInput.classList.add(...underlinedInputClasses);
    } else if (type === QuestionType.NUMBER) {
      tempAnswerInput.type = 'number';

      const numberOptions = options?.[QuestionType.NUMBER];
      if (numberOptions) {
        tempAnswerInput.min = numberOptions.min.toString();
        tempAnswerInput.max = numberOptions.max.toString();
        if (numberOptions.step) tempAnswerInput.step = numberOptions.step.toString();
      }
    } else if (type === QuestionType.PHONE) {
      tempAnswerInput.type = 'tel';
      if (variant === 'underline') tempAnswerInput.classList.add(...underlinedInputClasses);
    } else if (type === QuestionType.URL) {
      tempAnswerInput.type = 'url';
      if (variant === 'underline') tempAnswerInput.classList.add(...underlinedInputClasses);
    } else {
      if (variant === 'underline') tempAnswerInput.classList.add(...underlinedInputClasses);
    }

    tempAnswerInput.setAttribute('data-private', '');
    answerInput = tempAnswerInput;
  }

  if (prefix) {
    answerInput?.classList.add('rounded-l-none');

    const labelPrefix = renderInputPrefix(prefix);
    inputWrapper.prepend(labelPrefix);
  }

  if (answerInput) {
    answerInput.classList.add('answer-input', 'w-full', ...inputClasses);
    if (blockId) answerInput.classList.add('input-' + blockId);

    if (id) answerInput.id = id;

    if (!readOnly && addOnEdit) {
      answerInput.classList.add('rounded-l-none');
      addOnEdit.onInsertToDOM(answerContainer);
    }

    if (answerInput instanceof HTMLInputElement) {
      (answerInput as HTMLInputElement).placeholder = placeholder;
      (answerInput as HTMLInputElement).value =
        type === QuestionType.NUMBER ? (value ?? '').replace(/[^0-9.]/g, '') : value ?? '';

      if (disabled) (answerInput as HTMLInputElement).disabled = disabled;
      if (readOnly) answerInput = onReadonlyInputKeydown(answerInput as HTMLInputElement);

      answerInput.oninput = (e) => onAnswerChange(e.target);
      answerInput.onchange = (e) => onAnswerChange(e.target);

      answerContainer.appendChild(answerInput);
    }
  }

  inputWrapper.appendChild(answerContainer);
  answerTopContainer.append(inputWrapper);
  return answerTopContainer;
};

export const validateUrl = (link: string): { result: boolean; error?: string } => {
  const validation: { result: boolean; error?: string } = { result: true };

  if (!link.startsWith('http://') && !link.startsWith('https://')) {
    validation.result = false;
    validation.error = 'URL must start with http:// or https://';
  }

  if (validation.result)
    try {
      const url = new URL(link);
      validation.result = url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      validation.result = false;
      validation.error = 'Invalid URL';
    }

  return validation;
};
