import { IApolloCacheParams } from '../../../lib/cache.types';
import { mergeCacheLists } from '../../../lib/utils';
import { GET_ENTITY_MEMBERS } from './entity-members.queries';
import { IEntitiesWithMembersResult, IEntityMemberParams, IEntityMemberResults } from './entity-members.types';

export const updateEntityMemberCache = ({
  cache,
  entityMembers,
  variables
}: IApolloCacheParams & IEntityMemberResults & { variables: IEntityMemberParams }) => {
  const currMembers = cache.readQuery<IEntityMemberResults, IEntityMemberParams>({
    query: GET_ENTITY_MEMBERS,
    variables
  })?.entityMembers;

  if (currMembers)
    cache.writeQuery<IEntityMemberResults, IEntityMemberParams>({
      query: GET_ENTITY_MEMBERS,
      data: { entityMembers: mergeCacheLists(currMembers, entityMembers) },
      variables,
      overwrite: true
    });

  // Update entities members cache if entityId is set
  if (variables.entityId) {
    const currEntitiesMembers = cache.readQuery<IEntitiesWithMembersResult, { includeArchived?: boolean }>({
      query: GET_ENTITY_MEMBERS,
      variables: { includeArchived: true }
    })?.entitiesMembers;

    if (currEntitiesMembers)
      cache.writeQuery<IEntitiesWithMembersResult, IEntityMemberParams>({
        query: GET_ENTITY_MEMBERS,
        data: {
          entitiesMembers: currEntitiesMembers.map((em) =>
            em.entity._id === variables.entityId ? { ...em, members: mergeCacheLists(em.members, entityMembers) } : em
          )
        },
        variables,
        overwrite: true
      });
  }
};
