import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { useLazyUserInviteCode } from '../user-invite-code.service';
import { FetchUserInviteCodeForm } from './fetch-user-invite-code-form.component';
import { Emailable, Identifiable } from '../../../../lib/types';
import { IGetUserInviteCodeProps } from '../user-invite-code.types';

interface IEditMemberNestedContainerProps extends IFormWithStepperProps {
  onSubmit: (_: Identifiable & Emailable) => void;
}

export const FetchUserInviteCodeNestedContainer = ({
  onError,
  onSuccess,
  onSubmit,
  ...rest
}: IEditMemberNestedContainerProps) => {
  const { getUserInviteCode } = useLazyUserInviteCode();

  const handleSubmit = async (variables: IGetUserInviteCodeProps) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      const { data } = await getUserInviteCode(variables);
      if (!data?.getUserInviteCode) throw new Error();

      onSubmit({ ...data.getUserInviteCode, email: variables.email });
    } catch (err) {
      const errMsg = 'Unable to find invite code';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }
  };

  return <FetchUserInviteCodeForm onSubmit={handleSubmit} {...rest} />;
};
