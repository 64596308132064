interface IIconProps {
  open?: boolean;
}

interface IUpDownIconProps extends IIconProps {
  spacing?: 'smallLeft' | 'smallRight' | 'auto';
  color?: string;
}

export const UpDownIcon = ({ spacing = 'auto', color, open }: IUpDownIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke={color ?? 'currentColor'}
    className={`w-6 h-6 ${spacing === 'auto' ? 'mx-auto' : spacing === 'smallRight' ? 'mr-2' : 'ml-2'}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d={open ? 'm4.5 15.75 7.5-7.5 7.5 7.5' : 'm19.5 8.25-7.5 7.5-7.5-7.5'}
    />
  </svg>
);

export const OpenCollapseIcon = ({ open }: { open?: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className={`w-4 h-4`} fill="#fff">
    <div
      dangerouslySetInnerHTML={{
        __html:
          '<!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->'
      }}
    />
    {open ? (
      <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm88 200l144 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-144 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z" />
    ) : (
      <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM200 344l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
    )}
  </svg>
);
