import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '../../_core/loader.component';
import { useAcceptInvite, useInvite } from '../../domains/invite/invite.service';
import { ROUTE_PATHS } from '../../../_routes';
import { useEffect, useMemo, useState } from 'react';
import { showError } from '../../../lib/utils';
import { INVITE_STATUS } from '../../../lib/types';
import { useCallOnce } from '../../_core/utils/once';

// Will force join with code if possible
export const JoinWithCodePageContainer = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { isAuthenticated, user: authUser } = useAuth0();

  const { acceptInvite } = useAcceptInvite();

  const code = params.code ?? '';
  const { invite } = useInvite(code);

  const [accepting, setAccepting] = useState(false);

  const foundInvite = useMemo(() => authUser?.email && invite, [invite, authUser?.email]);

  const isWrongUserForInvite = useMemo(
    () => foundInvite && authUser?.email !== invite?.email,
    [foundInvite, invite?.email, authUser?.email]
  );

  useCallOnce(
    () =>
      acceptInvite({ code, email: authUser?.email, name: authUser?.name })
        .then(() => navigate(ROUTE_PATHS.DASHBOARD))
        .catch((err) => showError('Failed to accept invite', err as Error)),
    accepting
  );

  useEffect(() => {
    if (
      !accepting &&
      isAuthenticated &&
      foundInvite &&
      invite?.status === INVITE_STATUS.PENDING &&
      !isWrongUserForInvite
    ) {
      setAccepting(true);
    }
  }, [accepting, foundInvite, invite?.status, isAuthenticated, isWrongUserForInvite]);

  if (!isAuthenticated || isWrongUserForInvite) return <Navigate to={ROUTE_PATHS.INVITE + '/' + code} />;
  return (
    <div className="flex flex-col flex-1 items-center justify-center text-center">
      {!invite || invite?.status === INVITE_STATUS.PENDING ? (
        <>
          <h1 className="mb-4">{invite ? 'Accepting invite...' : 'Fetching invite...'}</h1>
          <Loader />
        </>
      ) : (
        <h1 className="mb-4 text-error">
          Invite is no longer valid.
          <br />
          Please request a new invite.
        </h1>
      )}
    </div>
  );
};
