import { Tag } from '../../_core/tag.component';
import { DragChildrenProps, Draggable } from '../../_core/drag-and-drop/draggable.component';

interface IRadioOption {
  tagClass: string;
  onRemove: () => void;
  tag: string;
  i: number;
}

export const RadioOption = ({ i, onRemove, tag, tagClass }: IRadioOption) => {
  return (
    <Draggable
      inline
      DragPreview={() => <div className="border-solid rounded p-2 bg-white">{tag}</div>}
      allowedEdges={['left', 'right']}
      data={{ dragItemId: tag }}
    >
      {({ dragState, draggableRef }: DragChildrenProps<HTMLDivElement, HTMLDivElement>) => (
        <Tag
          key={'tag-' + tag + i}
          ref={draggableRef}
          onRemove={onRemove}
          tag={tag}
          tagClass={tagClass}
          className={dragState.type === 'is-dragging' ? 'opacity-50' : ''}
          size="small"
        />
      )}
    </Draggable>
  );
};
