import { GET_REQUEST_GROUPS } from './request-group.queries';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { IGetRequestGroupsResults } from './request-group.types';
import { IArchivableProps } from '../../../lib/query.types';

export const readRequestGroupsCache = ({ cache }: IApolloCacheParams) =>
  cache.readQuery<IGetRequestGroupsResults, IArchivableProps>({
    query: GET_REQUEST_GROUPS,
    variables: { includeArchived: true }
  })?.requestGroups;

export const writeRequestGroupsCache = ({ cache, requestGroups }: IApolloCacheParams & IGetRequestGroupsResults) => {
  cache.writeQuery({
    query: GET_REQUEST_GROUPS,
    data: { requestGroups },
    variables: { includeArchived: true },
    overwrite: true
  });
};
