import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';

export const useResponsiveLimits = () => {
  const { width } = useWindowSize();

  const isMd = useMemo(() => width > 768, [width]);
  const isMdLg = useMemo(() => width > 1024, [width]);
  const isLg = useMemo(() => width > 1124, [width]);
  const isXl = useMemo(() => width > 1280, [width]);
  const is2xl = useMemo(() => width > 1536, [width]);
  const is3xl = useMemo(() => width > 1600, [width]);

  return { width, isMd, isMdLg, isLg, isXl, is2xl, is3xl };
};
