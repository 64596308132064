import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { Identifiable } from './types';

// export let BASE_API_URL: string | undefined = 'http://localhost:4000';
export let BASE_API_URL: string | undefined = import.meta.env.VITE_AUTH0_AUDIENCE;
if (BASE_API_URL?.endsWith('/')) BASE_API_URL = BASE_API_URL.substring(0, BASE_API_URL.length - 1);

export const API_ENDPOINTS = {
  EMAIL_PASSWORD_LOGIN: '/auth/local',
  EMAIL_PASSWORD_REGISTER: '/auth/local/register'
};

export const getServerEndpoint = (endpoint: string) => {
  return `${BASE_API_URL}/api/${endpoint}`;
};

export const logError = (...msgs: string[]) => {
  if (import.meta.env.DEV) console.error(...msgs);
};

export const showError = (msg: string, err: Error, id?: string) => {
  logError(msg, err.message);
  toast.error(msg, { id });
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const mergeCacheLists = <T extends Identifiable>(a: T[], b: T[]) => {
  // Create a copy of a, incase it is an immutable list
  const aCopy = [...a];

  const swappedIds: string[] = [];
  aCopy.map((aItem) => {
    const bItem = b.find(({ _id }) => _id === aItem._id);
    if (bItem) {
      swappedIds.push(bItem._id);
      return bItem;
    }

    return aItem;
  });

  // Add all unswapped items to list at end
  if (swappedIds.length < b.length) {
    b.forEach((bItem) => {
      if (!swappedIds.includes(bItem._id)) aCopy.push(bItem);
    });
  }

  return aCopy;
};

export const usePrev = <T>(value?: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const convertRemToPixels = (rem: number) =>
  rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
