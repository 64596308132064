import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';
import { IDepartment, IEntity, ILocation } from '../../../../lib/types';

export interface IUpdateEntityFormValues {
  name: string;
  department: IMultiSelectOption[];
  location: IMultiSelectOption[];
}

interface IUpdateEntityFormProps extends IRIFormProps<IUpdateEntityFormValues> {
  departments: IDepartment[];
  entity: IEntity;
  locations: ILocation[];
}

export const UpdateEntityForm = ({ departments, entity, locations, onSubmit }: IUpdateEntityFormProps) => {
  const validateForm = (values: IUpdateEntityFormValues) => {
    const errors: FormikErrors<IUpdateEntityFormValues> = {};

    if (!values.name.trim()) {
      errors.name = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={
        {
          name: entity.name,
          department:
            entity.department?.map((d) => ({
              value: d._id,
              label: d.name,
              secondaryLabel: d.deletedAt ? 'INACTIVE' : undefined
            })) ?? [],
          location:
            entity.location?.map((l) => ({
              value: l._id,
              label: l.name,
              secondaryLabel: l.deletedAt ? 'INACTIVE' : undefined
            })) ?? []
        } as IUpdateEntityFormValues
      }
      onSubmit={(values) => onSubmit({ ...values, name: values.name.trim() })}
      validate={validateForm}
    >
      {(props: FormikProps<IUpdateEntityFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel name="name" label="Name" placeholder="Edit company name" />
          <FormInputWithLabel
            name="department"
            label="Departments"
            placeholder="Edit departments"
            type="multiselect"
            multiSelectProps={{
              options: departments.filter((d) => !d.deletedAt).map((d) => ({ label: d.name, value: d._id })),
              selectAll: true
            }}
          />
          <FormInputWithLabel
            name="location"
            label="Locations"
            placeholder="Edit locations"
            type="multiselect"
            multiSelectProps={{
              options: locations.filter((d) => !d.deletedAt).map((l) => ({ label: l.name, value: l._id })),
              selectAll: true
            }}
          />
          <FormButton loading={props.isSubmitting} text="Update" />
        </Form>
      )}
    </Formik>
  );
};
