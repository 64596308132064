import { gql } from '@apollo/client';
import { COMPANY_OVERVIEW_FRAGMENT } from '../companies/company.queries';
import { LOCATION_FRAGMENT } from '../locations/location.queries';
import { DEPARTMENT_FRAGMENT } from '../departments/department.queries';
import { USER_FRAGMENT, USER_OVERVIEW_FRAGMENT } from '../users/user.queries';

const INVITE_OVERVIEW_FRAGMENT = gql`
  fragment InviteOverview on Invite {
    _id
    code
    department {
      ...DepartmentDetails
    }
    email
    expiresAt
    isExpired
    location {
      ...LocationDetails
    }
    name
    roles
    sentAt
    status
  }

  ${DEPARTMENT_FRAGMENT}
  ${LOCATION_FRAGMENT}
`;

const INVITE_FRAGMENT = gql`
  fragment InviteDetails on Invite {
    ...InviteOverview
    code
    company {
      ...CompanyOverview
    }
    sentBy {
      ...UserOverview
    }
  }

  ${COMPANY_OVERVIEW_FRAGMENT}
  ${INVITE_OVERVIEW_FRAGMENT}
  ${USER_OVERVIEW_FRAGMENT}
`;

export const ACCEPT_INVITE = gql`
  mutation AcceptInvite($code: String!, $email: String, $name: String) {
    acceptInvite(code: $code, email: $email, name: $name) {
      ...UserDetails
    }
  }

  ${USER_FRAGMENT}
`;

export const GET_INVITE = gql`
  query GetInvite($code: String!, $status: STATUS) {
    invite(code: $code, status: $status) {
      ...InviteDetails
    }
  }

  ${INVITE_FRAGMENT}
`;

export const GET_COMPANY_INVITES = gql`
  query GetCompanyInvites($status: STATUS) {
    invites(status: $status) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;

export const RESEND_INVITE = gql`
  mutation ResendInvite($_id: ID!) {
    resendInvite(_id: $_id) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;

export const REMOVE_INVITE = gql`
  mutation RemoveInvite($_id: ID!) {
    removeInvite(_id: $_id) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;

export const SEND_INVITES = gql`
  mutation SendInvites($department: [ID!]!, $inviteData: [InviteData!]!, $location: [ID!]!, $roles: [ROLE!]!) {
    sendInvites(department: $department, inviteData: $inviteData, location: $location, roles: $roles) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;

export const UPDATE_INVITE = gql`
  mutation UpdateInvite($_id: ID!, $name: String) {
    updateInvite(_id: $_id, name: $name) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;

export const UPDATE_INVITES = gql`
  mutation UpdateInvites($department: [ID!], $ids: [ID!]!, $location: [ID!], $roles: [ROLE!]) {
    updateInvites(department: $department, ids: $ids, location: $location, roles: $roles) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;
