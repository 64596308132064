import { BlockToolConstructorOptions, BlockToolData } from '@editorjs/editorjs/types/tools';
import { CreateTemplateBlockTool, ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { localTitleBlockExport, localTitleBlockImport } from '../_core/utils/block.utils';
import { BlockTitles, BlockType } from '../_core/editor.const';
import _ from 'lodash';
import { createRoot } from 'react-dom/client';
import { RichTextEditor } from './rich-text-editor.component';

interface RichTextData extends BlockToolData {
  value: string;
}

export class RichText extends CreateTemplateBlockTool<RichTextData, ICreateTemplateBlockConfig> {
  constructor(props: BlockToolConstructorOptions<RichTextData & { text?: string }>) {
    const { data, ...rest } = props;
    const newData = {
      ...data,
      value: _.unescape(data.value ?? data.text)
    };

    delete newData.text;

    super({ ...rest, data: newData });
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.Text],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416 32 416c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-1.8 0 18-48 159.6 0 18 48-1.8 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-25.8 0L254 52.8zM279.8 304l-111.6 0L224 155.1 279.8 304z"/></svg>'
    };
  }

  render() {
    super.render();

    const readOnly = this.api.readOnly.isEnabled || this.config.readOnly;

    const editorContainer = document.createElement('div');
    editorContainer.classList.add('w-full');
    const root = createRoot(editorContainer);
    root.render(
      <RichTextEditor
        className={this.block?.id ? 'input-' + this.block.id : undefined}
        initialValue={this.data.value}
        onChange={(p) => this.onValueChange(p)}
        readOnly={readOnly}
      />
    );

    this.wrapper?.append(editorContainer);

    const container = document.createElement('div');
    container.appendChild(super.render());

    return renderWithBlockFocusWrapper(container);
  }

  private onValueChange(value: string) {
    this.data.value = value;
    this.validate(this.data);
  }

  validate(data: RichTextData) {
    return !!data.value.length;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async save(_: HTMLDivElement): Promise<RichTextData> {
    return this.data;
  }

  static get conversionConfig() {
    return { export: localTitleBlockExport, import: localTitleBlockImport };
  }

  static get sanitize() {
    // allow HTML on value
    return { ...super.sanitize, value: true };
  }
}
