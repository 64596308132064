import TextareaAutosize from 'react-textarea-autosize';
import { inputClassesStr, underlinedInputClassesStr } from './editor.const';
import { PropsWithChildren, useEffect, useState } from 'react';
import { onReadonlyKeyDown } from './utils/input.utils';
import { InputAppearanceVariant } from './types';

export const AutoSizeTextArea = ({
  allowInitialValueUpdate,
  children,
  className,
  disabled,
  hasPrefix,
  id,
  initialValue,
  onChange,
  placeholder = '< Enter label >',
  readOnly,
  variant = 'box'
}: {
  allowInitialValueUpdate?: boolean;
  className?: string;
  disabled?: boolean;
  hasPrefix?: boolean;
  id?: string;
  initialValue: string;
  onChange?: (_: EventTarget | null) => void;
  placeholder?: string;
  readOnly?: boolean;
  variant?: InputAppearanceVariant;
} & PropsWithChildren) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (allowInitialValueUpdate) setValue(initialValue);
  }, [allowInitialValueUpdate, initialValue]);

  return (
    <>
      {children}
      <TextareaAutosize
        data-private
        id={id}
        className={`label min-h-8 overflow-auto w-full ${className ?? ''} ${inputClassesStr} ${
          hasPrefix ? 'rounded-l-none' : ''
        } ${variant === 'underline' ? underlinedInputClassesStr : ''}`}
        disabled={disabled}
        onChange={(e) => {
          setValue(e.target.value);
          onChange?.(e.target);
        }}
        onKeyDown={readOnly ? (e) => onReadonlyKeyDown(e.nativeEvent) : undefined}
        placeholder={placeholder}
        value={value}
      />
    </>
  );
};
