import EditorJs from '@editorjs/editorjs';
import { Button } from '../../../_core/button/button.component';
import {
  Completion,
  IClientOverview,
  Identifiable,
  IRequest,
  IRequestGroupOverview,
  IRequestOverview,
  IRequestType,
  IUserOverview,
  Nameable,
  REQUEST_NOTIFY_OPTION,
  REQUEST_STATUS,
  requestStatusLabels,
  TEMPLATE_TYPE
} from '../../../../lib/types';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { RequestTable, IRequestTableRowValues } from './request-table.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { RequestActionButton } from '../../../domains/request/request-action-button';
import { useFetchCurrUser } from '../../../domains/users/user.service';
import { TableFilterKeys } from '../../../_core/table/table.types';
import { useTableFilterConfig, useTableSearchConfig } from '../../../_core/table/utils/table-utils';
import { LowlightTextWithSubtitle } from '../../../_core/typography';
import { ICreateTemplateContainerProps } from '../../../domains/template/create/create-template-form-nested.container';
import { CreateTemplateContainer } from '../../../domains/template/create/create-template-form.container';
import { useLazyRequest, useSendRequest, useUpdateRequestAssignment } from '../../../domains/request/request.service';
import toast from 'react-hot-toast';
import { showError } from '../../../../lib/utils';
import { useSearchParams } from 'react-router-dom';
import { SendRequestMessageContainer } from '../../../domains/conversation/send-request-message/send-request-message.container';
import { TableDate } from '../../../_core/table/table-date.component';
import { RequestStatusIcon } from '../../RequestPage/request-status-icon';
import { IRequestTableContainer, RequestTableType } from './types';
import { RequestGroupTable } from './request-group-table.component';
import { EditRequestGroupContainer } from '../../../domains/request-group/edit/edit-request-group.container';
import { sortMethodTuple, useTableSortConfig } from '../../../_core/table/utils/table-sort';
import { CompletionText } from '../../../_editor/_core/completion.component';
import { UndeliveredRequestActionButton } from '../../../domains/request/undelivered-request-action-button';
import { useDeliverRequestGroup } from '../../../domains/request-group/request-group.service';

interface IRequestTableNestedContainerProps extends IRequestTableContainer, PropsWithChildren {
  favoritable?: boolean;
  groupsLoading?: boolean;
  hideBulk?: boolean;
  hideModeToggle?: boolean;
  requests?: IRequestOverview[];
  requestsLoading: boolean;
  requestGroups?: IRequestGroupOverview[];
  showAllStatuses?: boolean;
}

export const RequestTableNestedContainer: React.FC<IRequestTableNestedContainerProps> = ({
  children,
  favoritable,
  groupsLoading,
  hideBulk,
  hideModeToggle,
  requests,
  requestsLoading,
  requestGroups,
  showAllStatuses
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useFetchCurrUser();
  const { getRequest, loading: gettingRequest } = useLazyRequest();

  const { deliverRequestGroup, loading: deliveringRequestGroup } = useDeliverRequestGroup();
  const { sendRequest, loading: sendingRequest } = useSendRequest();
  const { updateRequestAssignment, loading: updatingRequest } = useUpdateRequestAssignment();

  const [createRequestGroup, setCreateRequestGroup] = useState(false);

  const [preppingSendRequest, setPreppingSendRequest] = useState(false);
  const [bulkIndividualRequestToSend, setBulkIndividualRequestToSend] = useState<IRequestOverview | null>(null);
  const [requestToSend, setRequestToSend] = useState<IRequest | null>(null);
  const [template, setTemplate] = useState<ICreateTemplateContainerProps | null>(null);

  const [mode, setMode] = useState<string>(
    searchParams.has('mode')
      ? Object.values(RequestTableType).find((v) => v.toLowerCase() === searchParams.get('mode')?.toLowerCase()) ??
          RequestTableType.ACTIVE
      : RequestTableType.ACTIVE
  );

  const [favoritedIds, setFavoritedIds] = useState<Record<string, true>>({});
  useEffect(
    () =>
      setFavoritedIds(
        requests?.reduce((acc: Record<string, true>, curr) => {
          if (curr.currStaffIsAssigned) acc[curr._id] = true;
          return acc;
        }, {}) ?? {}
      ),
    [requests]
  );

  const showingBulk = useMemo(() => !hideBulk && mode === RequestTableType.BULK, [hideBulk, mode]);
  const showingDrafts = useMemo(() => mode === RequestTableType.WORKSPACE, [mode]);

  const [searchConfig, searchMatches] = useTableSearchConfig({
    items: requests,
    keys: [
      'name',
      'company',
      'requestType',
      'company.name',
      'assignedTo.name',
      'deadline',
      'sentAt',
      'status',
      'createdBy.name'
    ]
  });
  const [filterConfig, filterFunc] = useTableFilterConfig({
    filters: [
      {
        title: TableFilterKeys.STATUS,
        placeholder: 'Filter by status',
        options: showAllStatuses
          ? [
              REQUEST_STATUS.SENT,
              REQUEST_STATUS.UNDELIVERED,
              REQUEST_STATUS.CLOSED,
              REQUEST_STATUS.DRAFT,
              REQUEST_STATUS.ARCHIVED
            ]
          : showingDrafts
          ? [REQUEST_STATUS.DRAFT, REQUEST_STATUS.ARCHIVED_DRAFT]
          : [REQUEST_STATUS.SENT, REQUEST_STATUS.CLOSED, REQUEST_STATUS.ARCHIVED],
        value: showingDrafts
          ? [REQUEST_STATUS.DRAFT]
          : showAllStatuses
          ? [REQUEST_STATUS.SENT, REQUEST_STATUS.UNDELIVERED]
          : [REQUEST_STATUS.SENT],
        matcher: (curr: IRequestOverview, filterValue: string[]) => {
          let currStatus = curr.status ?? REQUEST_STATUS.DRAFT;
          if (currStatus === REQUEST_STATUS.LOCKED) currStatus = REQUEST_STATUS.SENT;

          const isDraft = currStatus === REQUEST_STATUS.DRAFT;
          if (!filterValue.length) return showAllStatuses || (showingDrafts ? isDraft : !isDraft);

          if (curr.deletedAt)
            return isDraft
              ? filterValue.includes(REQUEST_STATUS.ARCHIVED_DRAFT)
              : filterValue.includes(REQUEST_STATUS.ARCHIVED);

          return filterValue.includes(currStatus);
        },
        labeler: (value) =>
          value in requestStatusLabels ? requestStatusLabels[value as REQUEST_STATUS].title : undefined
      }
    ]
  });

  const onCreateRequestTemplate = useCallback(
    (variables: Identifiable) => {
      getRequest(variables)
        .then(({ data }) => {
          if (data?.requestById.blocks)
            setTemplate({
              data: { ...data.requestById, blocks: data.requestById.blocks ?? [] },
              onSubmit: () => new Promise((res) => res()),
              type: TEMPLATE_TYPE.REQUEST,
              version: data.requestById.version ?? EditorJs.version
            });
          else toast.error('Failed to get request and create template');
        })
        .catch((err) => showError('Failed to load request', err));
    },
    [getRequest]
  );

  const onSendRequestTemplate = useCallback(
    (variables: Identifiable) => {
      getRequest(variables)
        .then(({ data }) => {
          if (data?.requestById) setRequestToSend(data.requestById);
          else toast.error('Failed to send request');
        })
        .catch((err) => showError('Failed to load request', err));
    },
    [getRequest]
  );

  const { sortFunc, ...restSort } = useTableSortConfig({
    sortMethods: {
      updatedAt: sortMethodTuple('date'),
      sentAt: sortMethodTuple('date'),
      name: sortMethodTuple(),
      requestType: sortMethodTuple('string', (r) => (r as IRequestType).type),
      completion: sortMethodTuple('default', (c) => (c as Completion).percentage),
      deadline: sortMethodTuple('date'),
      createdBy: sortMethodTuple('string', (c) => {
        const createdBy = c as IUserOverview;
        return createdBy.name ?? createdBy.email;
      }),
      assignedTo: sortMethodTuple('string', (c) => {
        const assignedTo = c as IClientOverview;
        return assignedTo.name ?? assignedTo.user.name ?? assignedTo.user.email;
      }),
      entity: sortMethodTuple('string', (c) => c && (c as Nameable).name),
      status: sortMethodTuple(
        'string',
        (s) => requestStatusLabels[s ? (s as REQUEST_STATUS) : REQUEST_STATUS.DRAFT].title
      )
    },
    defaultSortFunc: (a: IRequestOverview, b) => {
      if (a.deletedAt && b.deletedAt) return a.deletedAt < b.deletedAt ? 1 : -1;
      if (a.deletedAt && !b.deletedAt) return 1;
      if (!a.deletedAt && b.deletedAt) return -1;
      return a.updatedAt < b.updatedAt ? 1 : -1;
    }
  });

  const sortedRequests = useMemo(() => {
    if (!searchMatches) return [];
    const sortable = [...searchMatches];
    return sortable.filter(filterFunc).sort(sortFunc);
  }, [searchMatches, filterFunc, sortFunc]);

  const favoritedIndices = useMemo(
    () => sortedRequests.map((r) => r._id in favoritedIds),
    [favoritedIds, sortedRequests]
  );

  const requestRows = useMemo(() => {
    return sortedRequests.map((request, i) => {
      const row: IRequestTableRowValues = {
        favoritable: request.status !== REQUEST_STATUS.UNDELIVERED,
        request,
        values: [
          {
            children: <TableDate centered date={request.updatedAt} showTime />
          }
        ]
      };

      if (!showingDrafts)
        row.values.push({
          children: request.sentAt
            ? new Date(request.sentAt).toLocaleDateString('en-CA', {
                year: '2-digit',
                day: 'numeric',
                month: 'numeric'
              })
            : ''
        });

      row.values.push(
        {
          children: (
            <LowlightTextWithSubtitle
              subtitle={
                request.assignedTo?.name ?? request.assignedTo?.user.name ? request.assignedTo?.user.email : undefined
              }
            >
              {request.assignedTo?.name ?? request.assignedTo?.user.name ?? request.assignedTo?.user.email ?? ''}
            </LowlightTextWithSubtitle>
          )
        },
        { children: request.entity?.name ?? '' },
        { children: request.name },
        { children: request.requestType?.type ?? '' },
        {
          children: request.deadline ? <TableDate centered date={request.deadline} /> : ''
        }
      );

      if (!showingDrafts)
        row.values.push({
          children: (
            <div className="flex items-center">
              {request.clientFinishedWorking && <div className="rounded h-2 w-2 bg-success mr-2" />}
              <CompletionText completion={request.completion} />
            </div>
          )
        });

      row.values.push(
        {
          children: (
            <p
              className={`text-xs overflow-hidden whitespace-nowrap text-ellipsis font-bold ${
                user?._id === request.createdBy._id ? 'text-green-600' : ''
              }`}
            >
              {request.createdBy.name}
            </p>
          )
        },
        {
          children: <RequestStatusIcon inline request={request} />
        },
        {
          children: request.editable ? (
            <RequestActionButton
              slim
              loading={gettingRequest || !!requestToSend || sendingRequest || updatingRequest}
              onCreateTemplate={() => onCreateRequestTemplate({ _id: request._id })}
              onSendRequest={() => {
                setPreppingSendRequest(true);
                onSendRequestTemplate(request);
              }}
              onResendRequest={() => {
                setPreppingSendRequest(false);
                onSendRequestTemplate(request);
              }}
              request={request}
              last={sortedRequests.length > 10 && i === sortedRequests.length - 1}
            />
          ) : request.status === REQUEST_STATUS.UNDELIVERED && request.requestGroupOverview ? (
            <UndeliveredRequestActionButton
              slim
              last={sortedRequests.length > 10 && i === sortedRequests.length - 1}
              loading={deliveringRequestGroup}
              onSendRequest={() => setBulkIndividualRequestToSend(request)}
              request={request}
              requestGroup={request.requestGroupOverview}
            />
          ) : (
            ''
          )
        }
      );

      return row;
    });
  }, [
    sortedRequests,
    showingDrafts,
    user?._id,
    gettingRequest,
    requestToSend,
    sendingRequest,
    updatingRequest,
    deliveringRequestGroup,
    onCreateRequestTemplate,
    onSendRequestTemplate
  ]);

  const modeConfig = useMemo(
    () => ({
      onChange: (selected: string) => {
        setMode(selected);
        setSearchParams({ mode: selected.toLowerCase() });
        if (selected === RequestTableType.ACTIVE)
          filterConfig.setSelectedFilters({ title: TableFilterKeys.STATUS, value: [REQUEST_STATUS.SENT] });
        else if (selected === RequestTableType.BULK)
          filterConfig.setSelectedFilters({
            title: TableFilterKeys.STATUS,
            value: [REQUEST_STATUS.DRAFT, REQUEST_STATUS.SENT]
          });
        else filterConfig.setSelectedFilters({ title: TableFilterKeys.STATUS, value: [REQUEST_STATUS.DRAFT] });
      },
      options: Object.values(RequestTableType),
      value: mode
    }),
    [setSearchParams, filterConfig, mode]
  );

  if (showingBulk)
    return (
      <>
        <RequestGroupTable groups={requestGroups} modeConfig={modeConfig} loading={groupsLoading}>
          <Button
            onClick={() => setCreateRequestGroup(true)}
            text="Create Bulk Request"
            size="large"
            className="mt-1"
          />
        </RequestGroupTable>
        <RIDialog open={createRequestGroup} setOpen={setCreateRequestGroup}>
          <EditRequestGroupContainer />
        </RIDialog>
      </>
    );

  const isConfigLoading = !filterConfig.options.length;

  return (
    <>
      <RequestTable
        {...restSort}
        favoritable={favoritable}
        favorited={favoritedIndices}
        onFavorite={(index) => {
          const request = sortedRequests[index];
          updateRequestAssignment(request);
          setFavoritedIds((prev) => {
            const newFavorited = { ...prev };
            if (request._id in newFavorited) delete newFavorited[request._id];
            else newFavorited[request._id] = true;
            return newFavorited;
          });
        }}
        loading={!requests || (!requests.length && requestsLoading) || isConfigLoading}
        filterConfig={filterConfig}
        requests={isConfigLoading ? [] : requestRows}
        searchConfig={searchConfig}
        draftsMode={showingDrafts}
        modeConfig={hideModeToggle ? undefined : modeConfig}
      >
        {children}
      </RequestTable>
      {!!template?.data && (
        <RIDialog open={!!template} setOpen={(o) => setTemplate((v) => (o ? v : null))}>
          <CreateTemplateContainer {...template} close={() => setTemplate(null)} data={template.data} />
        </RIDialog>
      )}
      {!!requestToSend && (
        <RIDialog setOpen={(o) => setRequestToSend((r) => (o ? r : null))}>
          <SendRequestMessageContainer
            close={() => {
              setPreppingSendRequest(false);
              setRequestToSend(null);
            }}
            request={requestToSend}
            forceSendAll={preppingSendRequest}
            onSubmit={
              preppingSendRequest
                ? ({ message, notify = [REQUEST_NOTIFY_OPTION.ALL], replyTo, subject }) =>
                    sendRequest({ _id: requestToSend._id, customMessage: message, replyTo, notify, subject })
                : undefined
            }
          />
        </RIDialog>
      )}
      {!!bulkIndividualRequestToSend && (
        <RIDialog setOpen={(o) => setBulkIndividualRequestToSend((r) => (o ? r : null))}>
          <SendRequestMessageContainer
            forceSendAll
            close={() => setBulkIndividualRequestToSend(null)}
            request={bulkIndividualRequestToSend as IRequest}
            onSubmit={({ message, replyTo, subject }) =>
              deliverRequestGroup({
                _id: bulkIndividualRequestToSend.requestGroup?._id ?? '',
                customMessage: message,
                replyTo,
                subject,
                toClients: [bulkIndividualRequestToSend.assignedTo?._id ?? '']
              })
            }
          />
        </RIDialog>
      )}
    </>
  );
};
