import { uniqueId } from 'lodash';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { DetailBox } from '../details/details-box.component';
import { getInputClasses } from '../input/input-utils';
import { MultiSelectInput } from '../input/multiselect-input.component';
import { Table } from './table.component';
import { TableModeSelect } from './table-mode-select';
import { ITableProps } from './table.types';

interface ITableWithActionsProps<T extends object> extends ITableProps<T>, React.PropsWithChildren {}

export const TableWithActions = <T extends object>({
  bordered,
  children,
  filterConfig,
  modeConfig,
  searchConfig,
  ...tableProps
}: ITableWithActionsProps<T>) => {
  const { finalContainerClass, inputClasses } = getInputClasses({ type: 'multiselect' });

  const textInputClasses = getInputClasses({ type: 'text' });
  const uid = uniqueId();
  const searchLabel = `table-search-${uid}`;

  const hasActions = !!children || filterConfig?.options.length;
  return (
    <DetailBox stretch stacked bordered={bordered} rounded={bordered} straightTopLeft={bordered}>
      <div className={`w-full flex justify-end gap-4 z-40 ${hasActions ? 'pt-4' : ''}`}>
        {!!filterConfig?.options.length && (
          <div className="w-full lg:flex xs:flex-grid items-start gap-2">
            {!!modeConfig && (
              <TableModeSelect mode={modeConfig.value} onChange={modeConfig.onChange} options={modeConfig.options} />
            )}
            {!!searchConfig && (
              <div className="flex items-center mt-1 w-96">
                <MagnifyingGlassIcon height={30} width={30} className="mr-2" />
                <input
                  name={searchLabel}
                  className={`${textInputClasses.inputClasses} `}
                  value={searchConfig.value}
                  onChange={({ target }) => searchConfig.onChange(target.value)}
                  placeholder="Enter search text..."
                />
              </div>
            )}
            {filterConfig.options
              ?.filter(({ hidden }) => !hidden)
              .map((f) => (
                <div key={'table-filter-' + f.title} className="mt-1">
                  <MultiSelectInput
                    placeholder={f.placeholder}
                    inputClasses={inputClasses}
                    containerClass={`${finalContainerClass} ${f.containerClass ?? 'w-48'}`}
                    options={f.options.map((value) => ({ value, label: f.labeler?.(value) }))}
                    setValue={(v) =>
                      filterConfig.setSelectedFilters({ title: f.title, value: v.map(({ value }) => value) })
                    }
                    value={f.value.map((value) => ({ value, label: f.labeler?.(value) }))}
                    tagSize="small"
                  />
                </div>
              ))}
          </div>
        )}
        {/* TODO: Need to update the sticky point for tables so they don't force a 16* gap here when not actively stickied */}
        {children ? children : !filterConfig?.options.length ? <div className="h-16" /> : null}
      </div>
      <Table {...tableProps} />
    </DetailBox>
  );
};
