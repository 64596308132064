import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';

import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { ICompany, IUser, PLAN_NAME } from '../../../../lib/types';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IRegisterCompanyProps } from '../company.types';

interface IRegisterCompanyFormValues {
  name: string;
  fullName: string;
  email: string;
  phone: string;
}

export const RegisterCompanyForm = ({
  company,
  user,
  onBack,
  onSubmit,
  error
}: IRIFormProps<IRegisterCompanyProps> & { company?: ICompany | null; user?: IUser; error: string | null }) => {
  const validateForm = (values: IRegisterCompanyFormValues) => {
    const errors: FormikErrors<IRegisterCompanyFormValues> = {};

    if (!values.name.trim()) {
      errors.name = 'Required';
    }

    if (!values.fullName.trim()) {
      errors.fullName = 'Required';
    }

    if (!values.email.trim()) {
      errors.email = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        name: company?.name || '',
        fullName: user?.name || '',
        email: user?.email || '',
        phone: user?.phone || ''
      }}
      onSubmit={(values) =>
        onSubmit({
          name: values.name.trim(),
          fullName: values.fullName.trim(),
          email: values.email.trim(),
          phone: values.phone.trim(),
          planName: PLAN_NAME.FIRM
        })
      }
      validate={validateForm}
    >
      {(props: FormikProps<IRegisterCompanyFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto">
          <h1 className="text-center text-2xl mb-4">Organization Info</h1>
          <p className="font-light text-sm mb-4">
            Enter your organization and contact details for automated client communication.
          </p>
          <p className="font-light text-sm mb-4">Ensure accuracy for clear, professional messaging.</p>
          <div className="flex flex-col">
            <FormInputWithLabel name="name" label="Organization Name" />
            <h2 className="text-center text-2xl mb-4 mt-8">Contact Info</h2>
            <div className="mb-4">
              <FormInputWithLabel name="fullName" label="Full Name" />
            </div>
            <div className="mb-4">
              <FormInputWithLabel name="email" label="Contact Email" />
            </div>
            <div className="mb-4">
              <FormInputWithLabel type="phone" name="phone" label="Contact Phone" />
            </div>
            <div>
              <div className="flex-row justify-between mt-4">
                <FormButton loading={props.isSubmitting} text="Next" />
                {!!onBack && (
                  <div className="flex items-end justify-start mt-4">
                    <div className="w-24">
                      <FormButton
                        icon={<ArrowLongLeftIcon className="w-6 h-6 mr-2" />}
                        onPress={onBack}
                        text="Back"
                        type="button"
                        variant="text"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {error && <p className="text-red-500 text-sm text-left mt-4">{error}</p>}
          </div>
        </Form>
      )}
    </Formik>
  );
};
