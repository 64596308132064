import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';

export interface IUpdateCompanyFormValues {
  name: string;
}

interface IUpdateCompanyFormProps extends IRIFormProps<IUpdateCompanyFormValues> {
  name: string;
}

export const UpdateCompanyForm = ({ name, onSubmit }: IUpdateCompanyFormProps) => {
  return (
    <Formik
      initialValues={{ name } as IUpdateCompanyFormValues}
      onSubmit={(values) => onSubmit({ ...values, name: values.name.trim() })}
    >
      {(props: FormikProps<IUpdateCompanyFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel name="name" label="Name" placeholder="Edit organization name" />
          <FormActionsRow>
            <FormButton size="medium" loading={props.isSubmitting} text="Update" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
