import { GET_CLIENTS } from './client.queries';
import { IClient } from '../../../lib/types';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { mergeCacheLists } from '../../../lib/utils';
import { IClientsResults } from './client.types';
import { IArchivableProps } from '../../../lib/query.types';

export const updateClientCache = ({ cache, clients }: IApolloCacheParams & { clients: IClient[] }) => {
  const currClients = cache.readQuery<IClientsResults, IArchivableProps>({
    query: GET_CLIENTS,
    variables: { includeArchived: true }
  })?.clients;

  if (currClients)
    cache.writeQuery<IClientsResults, IArchivableProps>({
      query: GET_CLIENTS,
      data: { clients: mergeCacheLists(currClients, clients) },
      variables: { includeArchived: true },
      overwrite: true
    });
};
