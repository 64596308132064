import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { FetchUserInviteCodeNestedContainer } from './fetch-user-invite-code-form-nested.container';
import { Emailable, Identifiable } from '../../../../lib/types';

interface IFetchUserInviteCodeContainer {
  onBack: () => void;
  onSubmit: (_: Identifiable & Emailable) => void;
}

export const FetchUserInviteCodeContainer = (props: IFetchUserInviteCodeContainer) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Enter Invite Code"
    >
      <FetchUserInviteCodeNestedContainer
        {...props}
        onError={(e) => setSubmitError(e ?? null)}
        onSuccess={(s) => setSuccess(s ?? null)}
      />
    </FormContainer>
  );
};
