import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import { flushSync } from 'react-dom';
import { usePrev } from '../../../lib/utils';
import { useDebounceCallback } from 'usehooks-ts';
import { useCallOnce } from '../utils/once';

interface IUseDrops<T> {
  axis?: 'horizontal' | 'vertical';
  disabled?: boolean;
  dragItemKey?: string; // If not set then item is a scalar type that will be used as key itself
  setData: React.Dispatch<SetStateAction<T[]>>;
  onUpdate: (newData: T[], newKeysInOrder: string[]) => void;
}

export const useDrop = <T,>({ axis = 'vertical', disabled, dragItemKey, setData, onUpdate }: IUseDrops<T>) => {
  // Setup drag and drop handlers
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastDrop, setLastDrop] = useState<{
    source: string;
    sourceData: Record<string | symbol, unknown>;
    target: string;
    targetData: Record<string | symbol, unknown>;
  } | null>(null);

  const findIndex = useCallback(
    (t: T, data: Record<string | symbol, unknown>) =>
      // @ts-expect-error Expected type inference error
      (dragItemKey && t && typeof t === 'object' && dragItemKey in t ? t[dragItemKey] : t) === data.dragItemId,
    [dragItemKey]
  );

  const onDrop = useDebounceCallback(
    ({ location, source }: { location: DragLocationHistory; source: { data: object } }) => {
      const target = location.current.dropTargets[0];
      if (!target) return;

      const sourceData = source.data;
      const targetData = target.data;

      if (!('dragItemId' in sourceData) || !('dragItemId' in targetData)) return;

      const newDrop = {
        source: sourceData.dragItemId as string,
        sourceData,
        target: targetData.dragItemId as string,
        targetData
      };

      setLastDrop((prevDrop) => {
        let updateDrop = prevDrop;
        if (prevDrop?.source !== newDrop.source || prevDrop?.target !== newDrop.target) updateDrop = newDrop;

        return updateDrop;
      });
    },
    500,
    { leading: true }
  );

  const prevLastDrop = usePrev(lastDrop);
  useEffect(() => {
    if (lastDrop && lastDrop !== prevLastDrop) {
      // TODO: Getting called too much
      setData((prevData: T[]) => {
        const indexOfSource = prevData.findIndex((t) => findIndex(t, lastDrop.sourceData));
        const indexOfTarget = prevData.findIndex((t) => findIndex(t, lastDrop.targetData));
        if (indexOfTarget < 0 || indexOfSource < 0) return prevData;

        const newData = reorderWithEdge({
          list: prevData,
          startIndex: indexOfSource,
          indexOfTarget,
          closestEdgeOfTarget: extractClosestEdge(lastDrop.targetData),
          axis
        });

        onUpdate(
          newData,
          // @ts-expect-error Expected type inference error
          newData.map((d) => (dragItemKey && d && typeof d === 'object' && dragItemKey in d ? d[dragItemKey] : d))
        );
        return newData;
      });
      setTimeout(() => {
        flushSync(() => {
          // Being simple and just querying for the item after the drop.
          // We could use react context to register the element in a lookup,
          // and then we could retrieve that element after the drop and use
          // `triggerPostMoveFlash`. But this gets the job done.
          const element = document.querySelector(`[data-handler-id="${lastDrop.source}"]`);
          if (element instanceof HTMLElement) triggerPostMoveFlash(element);
        });
      }, 100);

      setLastDrop(null);
    }
  }, [axis, dragItemKey, findIndex, lastDrop, onUpdate, prevLastDrop, setData]);

  useCallOnce(() =>
    monitorForElements({ canMonitor: ({ source }) => !disabled && 'dragItemId' in source.data, onDrop })
  );
};
