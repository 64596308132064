import toast from 'react-hot-toast';

import { ISectionData } from './section.types';
import { IBranchData } from '../branch';
import { IRepeatSectionData } from '../repeat-section';
import { BlockType } from '../_core/editor.const';

const countUncompletedSectionFields = (data: ISectionData) => {
  let uncompletedOptional = 0;
  let uncompletedRequired = 0;

  for (const block of data.outputData.blocks) {
    const isBlockUnfilled = 'filled' in block.data && !block.data.filled;
    const isBlockRequired = 'required' in block.data && block.data.required;

    // Determine filled status of nested section blocks
    if (block.type === BlockType.QuestionGroup) {
      const uncompletedQuestionGroup = countUncompletedSectionFields(block.data);
      uncompletedOptional += uncompletedQuestionGroup.uncompletedOptional;
      uncompletedRequired += uncompletedQuestionGroup.uncompletedRequired;
    }
    // Branch and Multi Question Group should be considered as 1 item if triggering questions are not answered, and otherwise count their nested section data
    else if (block.type === BlockType.BranchYesNo || block.type === BlockType.BranchMulti) {
      const branchData = block.data as IBranchData;
      if (isBlockUnfilled || !branchData.questionData.value) {
        if (isBlockRequired) uncompletedRequired += 1;
        else uncompletedOptional += 1;
      } else {
        if (branchData.questionData.value) {
          const branchSelectedSection =
            branchData.sectionsData[
              branchData.questionData.options.findIndex((o) => o === branchData.questionData.value)
            ];

          const uncompletedBranchSection = countUncompletedSectionFields(branchSelectedSection);
          uncompletedOptional += uncompletedBranchSection.uncompletedOptional;
          uncompletedRequired += uncompletedBranchSection.uncompletedRequired;
        } else toast.error('Failed to determine completion status of branch block.');
      }
    } else if (block.type === BlockType.RepeatSection) {
      if (isBlockUnfilled) {
        if (isBlockRequired) uncompletedRequired += 1;
        else uncompletedOptional += 1;
      } else {
        const repeatSectionData = block.data as IRepeatSectionData;
        if (repeatSectionData.repeatCount) {
          for (let i = 0; i < repeatSectionData.repeatCount; i++) {
            const sectionData = repeatSectionData.sectionsData[i];
            const uncompletedRepeatSection = countUncompletedSectionFields(sectionData);
            uncompletedOptional += uncompletedRepeatSection.uncompletedOptional;
            uncompletedRequired += uncompletedRepeatSection.uncompletedRequired;
          }
        } else toast.error('Failed to determine completion status of Question Group - Multi block.');
      }
    } else {
      if (isBlockUnfilled) {
        if (isBlockRequired) uncompletedRequired += 1;
        else uncompletedOptional += 1;
      }
    }
  }

  return { uncompletedOptional, uncompletedRequired };
};

export const checkIfCanCompleteAndCountOptional = async ({ data, uid }: { data: ISectionData; uid?: string }) => {
  const { uncompletedOptional, uncompletedRequired } = countUncompletedSectionFields(data);

  if (uncompletedRequired) {
    toast.error(
      `Cannot complete ${
        data.label ? data.label : 'section'
      }. You have ${uncompletedRequired} missing answers to required questions`,
      {
        id: 'incomplete-required-' + uid
      }
    );
    throw new Error('Cannot complete');
  }

  return uncompletedOptional;
};

export const checkIfCanComplete = async ({
  confirm,
  ...rest
}: {
  confirm: (_?: string) => Promise<boolean>;
  data: ISectionData;
  uid?: string;
}) => {
  try {
    const uncompletedOptional = await checkIfCanCompleteAndCountOptional(rest);

    if (uncompletedOptional && !(await confirm(`You have ${uncompletedOptional} empty fields.`))) return false;
    return true;
  } catch (err) {
    return false;
  }
};
