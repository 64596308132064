import { useCallback, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { User } from '@auth0/auth0-react';

// Services
import { useRegisterUser } from '../../../domains/users/user.service';
import { ICompany } from '../../../../lib/types';
import { useLogout } from '../../../domains/auth/auth.service';
import toast from 'react-hot-toast';
import { useCallOnce } from '../../utils/once';
import { showError } from '../../../../lib/utils';

export interface ICompanyValidationProps {
  ignoreCompanyValidation?: boolean;
  ignoreForceRegister?: boolean;
}

interface IForceRegisterPrompts extends ICompanyValidationProps {
  authUser: User;
  company?: ICompany | null;
  companiesLoading: boolean;
  isRegistered: boolean;
}

export const ForceRegisterPrompts: React.FC<IForceRegisterPrompts> = ({
  authUser,
  company,
  companiesLoading,
  ignoreCompanyValidation,
  ignoreForceRegister,
  isRegistered
}) => {
  const [searchParams] = useSearchParams();
  const inviteCodeId = searchParams.get('foundCode');

  const { handleLogout } = useLogout();
  const { registerUser } = useRegisterUser({ authId: authUser.sub, joiningExistingCompany: !inviteCodeId });

  const [isRegistering, setRegistering] = useState(false);

  const logout = useCallback(
    (msg: string) => handleLogout({ onFinish: () => toast.error(msg, { id: 'failedToRegister' }) }),
    [handleLogout]
  );

  useCallOnce(() => {
    registerUser(inviteCodeId ?? '').catch((err) => {
      showError('Failed to register user', err as Error);
      logout('Failed to register user');
    });
  }, isRegistering);

  // Register user validations
  useEffect(() => {
    if (!isRegistered && !isRegistering && !ignoreForceRegister) {
      if (!inviteCodeId) {
        logout('Invite code is required');
      } else setRegistering(true);
    }
  }, [ignoreForceRegister, inviteCodeId, isRegistered, isRegistering, logout]);

  // Register company validations
  if (!ignoreCompanyValidation && isRegistered && !companiesLoading && !company?.completedOnboarding)
    return <Navigate to="/onboarding" />;

  return <></>;
};
