import { Form, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { IClientOverview, IEntityOverview, IMemberOverview, IRequestType } from '../../../../lib/types';
import { PropsWithChildren, useMemo } from 'react';
import { ICreateRequestProps } from '../request.types';
import { useEntityMembers } from '../../entity-members/entity-members.service';
import { useEditRequestFormClientFields, useEditRequestFormTemplateFields } from './edit-request-form.utils';

export interface IEditRequestFormFieldProps {
  clients: IClientOverview[];
  entities: IEntityOverview[];
  requestTypes: IRequestType[];
  staff: IMemberOverview[];
  hasBeenCreated?: boolean;
}

export const EditRequestFormFields = ({
  clients,
  entities,
  requestTypes,
  staff,
  createdById,
  hasBeenCreated,

  // Formik props
  children,
  setFieldValue,
  values
}: IEditRequestFormFieldProps &
  FormikProps<ICreateRequestProps> &
  PropsWithChildren & {
    createdById: string;
  }) => {
  const { entityMembers } = useEntityMembers({
    entityId: values.entity?.length ? values.entity[0]?.value : undefined
  });

  const activeEntityMembers = useMemo(() => entityMembers?.filter((em) => !em.deletedAt), [entityMembers]);

  const clientGroupOrder = useMemo(
    () =>
      values.entity?.length && values.entity?.[0]
        ? [values.entity[0]?.label + "'s Contacts", 'Non Org Contacts']
        : undefined,
    [values.entity]
  );

  const { AddNewClientDialog, MultiClientInput, SingleClientInput } = useEditRequestFormClientFields({
    clients,
    clientsMultiKey: 'ccClients',
    clientsMultiLabel: 'CC Clients',
    setFieldValue,
    values,
    activeEntityMembers,
    clientGroupOrder,
    clientsSingleKey: 'assignedTo',
    disabledForEntity: values.entity && !entityMembers
  });

  const { TemplateInput } = useEditRequestFormTemplateFields({ hasBeenCreated, inputKey: 'templateId' });

  const staticOptions = useMemo(
    () => ({
      entities: entities.filter((e) => !e.deletedAt).map((e) => ({ label: e.name, value: e._id })),
      requestTypes: requestTypes.filter((rt) => !rt.deletedAt).map((l) => ({ label: l.type, value: l._id })),
      staff: staff
        .filter((s) => !s.deletedAt && !s.user.deletedAt && s.user._id !== createdById)
        .map((d) => ({ label: d.name ?? d.user.name, secondaryLabel: d.user.email, value: d._id }))
    }),
    [createdById, entities, requestTypes, staff]
  );

  return (
    <>
      <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
        <FormInputWithLabel autoFocus name="name" label="Request Name" placeholder="Request Name" />
        <SingleClientInput />
        <FormInputWithLabel
          name="entity"
          label="Company"
          type="multiselect"
          placeholder="Assign to company"
          multiSelectProps={{ options: staticOptions.entities, singleSelect: true, nullable: true }}
        />
        <MultiClientInput />
        <FormInputWithLabel
          name="staff"
          label="Team Members CC'd"
          type="multiselect"
          placeholder="CC Team Member"
          multiSelectProps={{ options: staticOptions.staff, nullable: true, selectAll: true }}
        />
        <FormInputWithLabel
          name="requestType"
          label="Request Type"
          type="multiselect"
          placeholder="Select request type"
          multiSelectProps={{ options: staticOptions.requestTypes, singleSelect: true, nullable: true }}
        />
        <FormInputWithLabel name="deadline" label="Deadline" placeholder="Enter deadline date" type="date" />
        <FormInputWithLabel name="notes" label="Notes" placeholder="Optional notes" />
        <TemplateInput />
        {children}
      </Form>
      <AddNewClientDialog />
    </>
  );
};
