import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import { IInviteOverview, INVITE_STATUS, IUser, Identifiable, Nameable } from '../../../lib/types';
import { ICompanyInviteQueryParams, ICompanyInviteResult, IInviteQueryParams, IInviteResult } from './invite.types';
import {
  ACCEPT_INVITE,
  GET_COMPANY_INVITES,
  GET_INVITE,
  REMOVE_INVITE,
  RESEND_INVITE,
  SEND_INVITES,
  UPDATE_INVITE,
  UPDATE_INVITES
} from './invite.queries';
import { IUpdateMemberParams, IUpdateMembersParams } from '../../../lib/query.types';
import { readInvitesCache, writeInvitesCache } from './invite.utils';
import { wipeCurrCompanyCache } from '../companies/company.utils';
import { mergeCacheLists } from '../../../lib/utils';
import { USER_COMPANIES_REFETCH_QUERIES } from '../companies/company.const';
import { activeCompanyLoadingVar } from '../companies/company.service';
import { ICurrUserResults } from '../users/user.types';
import { GET_CURR_USER } from '../users/user.queries';

export const useAcceptInvite = () => {
  const [mutation, rest] = useMutation<{ acceptInvite: IUser }, IInviteQueryParams>(ACCEPT_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.acceptInvite)
        cache.writeQuery<ICurrUserResults>({ query: GET_CURR_USER, data: { loggedInUser: data.acceptInvite } });

      wipeCurrCompanyCache(cache);
    },
    awaitRefetchQueries: true,
    refetchQueries: USER_COMPANIES_REFETCH_QUERIES
  });

  const acceptInvite = useCallback(
    (variables: IInviteQueryParams) => {
      activeCompanyLoadingVar(true);
      return mutation({ variables }).finally(() => activeCompanyLoadingVar(false));
    },
    [mutation]
  );

  return { acceptInvite, ...rest };
};

export const useCompanyInvites = (status: INVITE_STATUS = INVITE_STATUS.PENDING) => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<ICompanyInviteResult, ICompanyInviteQueryParams>(GET_COMPANY_INVITES, {
    fetchPolicy: 'cache-and-network',
    variables: { status }
  });

  return { ...rest, ...data, loading: rest.loading || activeCompanyLoading };
};

export const useInvite = (code: string) => {
  const { data, ...rest } = useQuery<IInviteResult, IInviteQueryParams>(GET_INVITE, {
    fetchPolicy: 'cache-and-network',
    variables: { code }
  });

  return { invite: data?.invite, ...rest };
};

export const useLazyInvite = () => {
  const [query, { data, ...rest }] = useLazyQuery<IInviteResult, IInviteQueryParams>(GET_INVITE, {
    fetchPolicy: 'cache-and-network'
  });

  return { getInvite: query, ...data, ...rest };
};

export const useRemoveInvite = () => {
  const [mutation, rest] = useMutation<{ removeInvite: IInviteOverview }, Identifiable>(REMOVE_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const invites = readInvitesCache({ cache });

        if (invites) writeInvitesCache({ cache, invites: invites?.filter((v) => v._id !== data.removeInvite._id) });
      }
    }
  });

  const removeInvite = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { removeInvite, ...rest };
};

export const useResendInvite = () => {
  const [mutation, rest] = useMutation<{ resendInvite: IInviteOverview }, Identifiable>(RESEND_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const currInvites = readInvitesCache({ cache });
        if (currInvites) writeInvitesCache({ cache, invites: mergeCacheLists(currInvites, [data.resendInvite]) });
      }
    }
  });

  const resendInvite = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { resendInvite, ...rest };
};

export const useSendInvites = () => {
  const [mutation, rest] = useMutation<{ sendInvites: IInviteOverview[] }>(SEND_INVITES, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const currInvites = readInvitesCache({ cache });

        const newInvites = [];
        if (currInvites) newInvites.push(...currInvites);
        newInvites.push(...data.sendInvites);

        if (currInvites) writeInvitesCache({ cache, invites: newInvites });
      }
    }
  });

  const sendInvites = useCallback(
    (variables: {
      department: string[];
      inviteData: { name?: string; email: string }[];
      location: string[];
      roles: string[];
    }) => mutation({ variables }),
    [mutation]
  );

  return { sendInvites, ...rest };
};

export const useUpdateInvite = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ updateInvite: IInviteOverview }, IUpdateMemberParams>(UPDATE_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const currInvites = readInvitesCache({ cache });
        if (currInvites) writeInvitesCache({ cache, invites: mergeCacheLists(currInvites, [data.updateInvite]) });
      }
    }
  });

  const updateInvite = useCallback(({ name }: Nameable) => mutation({ variables: { _id, name } }), [_id, mutation]);

  return { updateInvite, ...rest };
};

export const useUpdateInvites = () => {
  const [mutation, rest] = useMutation<{ updateInvites: IInviteOverview[] }, IUpdateMembersParams>(UPDATE_INVITES, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const currInvites = readInvitesCache({ cache });
        if (currInvites) writeInvitesCache({ cache, invites: mergeCacheLists(currInvites, data.updateInvites) });
      }
    }
  });

  const updateInvites = useCallback((variables: IUpdateMembersParams) => mutation({ variables }), [mutation]);

  return { updateInvites, ...rest };
};
