import { IRequestOverview } from '../../../../lib/types';
import React, { PropsWithChildren, useMemo } from 'react';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import {
  ITableHeaderCell,
  ITableOptionsConfig,
  ITableRowClassConfig,
  ITableRowValues
} from '../../../_core/table/table.types';
import { useResponsiveLimits } from '../../../../utils/page.helpers';

interface IRequestTableProps
  extends PropsWithChildren,
    IAdminProps,
    IPersonalCompanyProps,
    ITableOptionsConfig<IRequestOverview> {
  requests: IRequestTableRowValues[];
  editingDisabled?: boolean;
  draftsMode?: boolean;
}

export interface IRequestTableRowValues extends ITableRowValues {
  request: IRequestOverview;
}

const baseDraftColSizes: (ITableRowClassConfig | null)[] = [
  { cell: 'w-24 text-center' },
  { cell: 'w-32' },
  { cell: 'w-32' },
  null, // Allow title col to grow
  { cell: 'w-26' },
  { cell: 'w-20' },
  { cell: 'w-24' },
  { cell: 'w-24' },
  { cell: 'w-40' }
];

const baseInboxColSizes: (ITableRowClassConfig | null)[] = [
  { cell: 'w-24 text-center' },
  { cell: 'w-20 text-center' },
  { cell: 'w-32' },
  { cell: 'w-32' },
  null, // Allow title col to grow
  { cell: 'w-26' },
  { cell: 'w-24 text-center' },
  { cell: 'w-32 text-center' },
  { cell: 'w-24' },
  { cell: 'w-24' },
  { cell: 'w-40' }
];

export const RequestTable: React.FC<IRequestTableProps> = ({ children, draftsMode, loading, requests, ...rest }) => {
  const headers: ITableHeaderCell[] = [{ content: 'Last Update', sortKey: 'updatedAt' }];
  if (!draftsMode) headers.push({ content: 'Sent At', sortKey: 'sentAt' });
  headers.push(
    { content: 'Contact', sortKey: 'assignedTo' },
    { content: 'Company', sortKey: 'entity' },
    { content: 'Title', sortKey: 'name' },
    { content: 'Type', sortKey: 'requestType' },
    { content: 'Deadline', sortKey: 'deadline' }
  );
  if (!draftsMode) headers.push({ content: 'Completion', sortKey: 'completion' });
  headers.push({ content: 'Owner', sortKey: 'createdBy' }, { content: 'Status', sortKey: 'status' }, { content: '' });

  const { isMd, isMdLg, isLg, isXl, is2xl } = useResponsiveLimits();

  const rowClasses = useMemo(() => {
    const sizes = [...(draftsMode ? baseDraftColSizes : baseInboxColSizes)];

    // Hide Sent At if less than 2xl
    if (!is2xl && !draftsMode) sizes[1] = { cell: 'hidden' };

    // Update contact and company cols
    const contactIndex = draftsMode ? 1 : 2;
    sizes[contactIndex] = isXl ? { cell: 'w-40' } : isLg ? { cell: 'w-36' } : { cell: 'w-28' };
    sizes[contactIndex + 1] = is2xl ? { cell: 'w-40' } : isLg ? { cell: 'w-32' } : { cell: 'w-26' };

    // Update owner col
    sizes[sizes.length - 3] = isXl ? { cell: 'w-32' } : { cell: 'w-28' };

    if (isXl) return sizes;

    // Hide deadline
    if (!draftsMode) sizes[sizes.length - 5] = { cell: 'hidden' };
    if (isLg) return sizes;

    // Hide deadline
    if (draftsMode) sizes[sizes.length - 4] = { cell: 'hidden' };
    // Hide type
    else sizes[sizes.length - 6] = { cell: 'hidden' };
    if (isMdLg) return sizes;

    // Hide owner col
    sizes[sizes.length - 3] = { cell: 'hidden' };

    // Hide type col
    if (draftsMode) sizes[sizes.length - 5] = { cell: 'hidden' };
    // Hide Completion
    else sizes[sizes.length - 4] = { cell: 'hidden' };

    if (isMd) return sizes;

    // Hide Updated At
    sizes[0] = { cell: 'hidden' };
    return sizes;
  }, [draftsMode, isMd, isLg, isMdLg, isXl, is2xl]);

  return (
    <>
      <TableWithActions {...rest} loading={loading} headers={headers} rowClasses={rowClasses} values={requests}>
        {children}
      </TableWithActions>
    </>
  );
};
