import { IClient, IEntity } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { useSendClientInvite } from '../../client-invite/client-invite.service';
import { ISendClientInvite } from '../invite.types';
import { CreateInviteUserForm } from './create-invite-user-form.component';

export interface ICreateInviteUserContainerProps extends IPersonalCompanyProps {
  afterSuccess?: (_: IClient) => void;
  email: string;
  entity?: IEntity;
  onSubmit?: (_: ISendClientInvite) => void;
}

interface ICreateInviteUserNestedContainerProps extends IFormWithStepperProps, ICreateInviteUserContainerProps {}

export const CreateInviteUserNestedContainer = ({
  afterSuccess,
  entity,
  onError,
  onSuccess,
  onSubmit,
  ...rest
}: ICreateInviteUserNestedContainerProps) => {
  const { sendClientInvite } = useSendClientInvite();

  const handleSubmit = async ({ email, name }: ISendClientInvite) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      if (onSubmit) {
        onSubmit({ email, name });
        return;
      }

      const result = await sendClientInvite({ email, name, entityId: entity?._id });
      onSuccess?.('Successfully added contact');

      if (result.data) afterSuccess?.(result.data?.sendClientInvite.client);
    } catch (err) {
      const errMsg = 'Unable to add contact';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }

    return;
  };

  return <CreateInviteUserForm onSubmit={handleSubmit} {...rest} />;
};
