import { ICompletable } from '../../../lib/types';

export const CompletionText = ({ completion }: ICompletable) => {
  if (!completion) return null;
  return (
    <div className={`text-xs whitespace-nowrap ${completion.percentage === 1 ? 'text-green-600 font-semibold' : ''}`}>
      <span>{Math.round(completion.percentage * 100)}%</span>
      <span className="ml-2">
        {completion.completed}/{completion.total}
      </span>
    </div>
  );
};
