import { useCallback } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  ICurrCompanyRequestTypesResults,
  IDefaultRequestTypesResults,
  ISelectedRequestTypesResults,
  IUpdateRequestTypeProps
} from './request-type.types';
import {
  CREATE_REQUEST_TYPES,
  DELETE_GLOBAL_REQUEST_TYPE,
  DELETE_REQUEST_TYPES,
  GET_COMPANY_REQUEST_TYPES,
  GET_DEFAULT_REQUEST_TYPES,
  GET_SELECTED_REQUEST_TYPES,
  RESTORE_REQUEST_TYPE,
  UPDATE_GLOBAL_REQUEST_TYPE,
  UPDATE_REQUEST_TYPE
} from './request-type.queries';
import { IRequestType, Identifiable } from '../../../lib/types';
import { updateRequestTypeInCache } from './request-type.utils';
import { mergeCacheLists, showError } from '../../../lib/utils';
import { activeCompanyLoadingVar } from '../companies/company.service';

export const useCompanyRequestTypes = () => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<ICurrCompanyRequestTypesResults>(GET_COMPANY_REQUEST_TYPES, {
    fetchPolicy: 'cache-and-network'
  });

  return { ...rest, ...data, loading: rest.loading || activeCompanyLoading };
};

export const useDefaultRequestTypes = () => {
  const { data, ...rest } = useQuery<IDefaultRequestTypesResults>(GET_DEFAULT_REQUEST_TYPES, {
    fetchPolicy: 'cache-and-network'
  });

  return { ...rest, ...data };
};

export const useSelectedRequestTypes = () => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<ISelectedRequestTypesResults>(GET_SELECTED_REQUEST_TYPES, {
    fetchPolicy: 'cache-and-network'
  });

  return { ...rest, ...data, loading: rest.loading || activeCompanyLoading };
};

export const useCreateRequestTypes = () => {
  const [mutation, rest] = useMutation<{ createRequestTypes: IRequestType[] }>(CREATE_REQUEST_TYPES, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.createRequestTypes) {
        // Update curr company request types
        const currRequestTypes = cache.readQuery<ICurrCompanyRequestTypesResults>({
          query: GET_COMPANY_REQUEST_TYPES
        })?.companyRequestTypes;

        const newCurrRequestTypes = [];
        if (currRequestTypes) newCurrRequestTypes.push(...currRequestTypes);
        newCurrRequestTypes.push(...data.createRequestTypes);

        cache.writeQuery({
          query: GET_COMPANY_REQUEST_TYPES,
          data: { companyRequestTypes: newCurrRequestTypes } as ICurrCompanyRequestTypesResults,
          overwrite: true
        });
      }
    }
  });

  const createRequestTypes = useCallback((types: string[]) => mutation({ variables: { types } }), [mutation]);

  return { createRequestTypes, ...rest };
};

export const useDeleteGlobalRequestType = () => {
  const [mutation, rest] = useMutation<{ deleteGlobalRequestType: IRequestType }>(DELETE_GLOBAL_REQUEST_TYPE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.deleteGlobalRequestType) updateRequestTypeInCache({ cache, type: data.deleteGlobalRequestType });
    },
    onError: (err) => showError('Unable to delete global request type due to other organization dependencies', err)
  });

  const deleteGlobalRequestType = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteGlobalRequestType, ...rest };
};

export const useDeleteRequestTypes = () => {
  const [mutation, rest] = useMutation<{ deleteRequestTypes: IRequestType[] }>(DELETE_REQUEST_TYPES, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.deleteRequestTypes) {
        // Update curr company request types
        const currRequestTypes = cache.readQuery<ICurrCompanyRequestTypesResults>({
          query: GET_COMPANY_REQUEST_TYPES
        })?.companyRequestTypes;

        cache.writeQuery({
          query: GET_COMPANY_REQUEST_TYPES,
          data: {
            companyRequestTypes: mergeCacheLists(currRequestTypes ?? [], data.deleteRequestTypes)
          } as ICurrCompanyRequestTypesResults,
          overwrite: true
        });
      }
    }
  });

  const deleteRequestTypes = useCallback(
    (requestTypeIDs: string[]) => mutation({ variables: { requestTypeIDs } }),
    [mutation]
  );

  return { deleteRequestTypes, ...rest };
};

export const useRestoreRequestType = () => {
  const [mutation, rest] = useMutation<{ restoreRequestType: IRequestType }>(RESTORE_REQUEST_TYPE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.restoreRequestType) updateRequestTypeInCache({ cache, type: data.restoreRequestType });
    }
  });

  const restoreRequestType = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreRequestType, ...rest };
};

export const useUpdateRequestType = () => {
  const [mutation, rest] = useMutation<{ updateRequestType: IRequestType }>(UPDATE_REQUEST_TYPE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateRequestType) updateRequestTypeInCache({ cache, type: data.updateRequestType });
    }
  });

  const updateRequestType = useCallback((variables: IUpdateRequestTypeProps) => mutation({ variables }), [mutation]);

  return { updateRequestType, ...rest };
};

export const useUpdateGlobalRequestType = () => {
  const [mutation, rest] = useMutation<{ updateGlobalRequestType: IRequestType }>(UPDATE_GLOBAL_REQUEST_TYPE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateGlobalRequestType) updateRequestTypeInCache({ cache, type: data.updateGlobalRequestType });
    }
  });

  const updateGlobalRequestType = useCallback(
    (variables: IUpdateRequestTypeProps) => mutation({ variables }),
    [mutation]
  );

  return { updateGlobalRequestType, ...rest };
};
