import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ROLES_FOR_LABEL } from '../../../../lib/types';
import { ISelectedRolesProps } from '../../../../lib/query.types';
import { BASIC_ROLE_OPTIONS } from '../../../../_configuration';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

interface ISelectRolesFormFieldProps {
  roles: IMultiSelectOption[];
}

export const SelectRolesForm = ({ onSubmit }: IRIFormProps<ISelectedRolesProps>) => {
  const validateForm = (values: ISelectRolesFormFieldProps) => {
    const errors: FormikErrors<ISelectRolesFormFieldProps> = {};

    if (!values.roles.length) {
      errors.roles = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ roles: [] } as ISelectRolesFormFieldProps}
      onSubmit={({ roles }) => onSubmit({ roles: roles.map(({ value }) => ROLES_FOR_LABEL[value]) })}
      validate={validateForm}
    >
      {(props: FormikProps<ISelectRolesFormFieldProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel
            name="roles"
            label="Roles"
            type="multiselect"
            placeholder="Select Roles"
            multiSelectProps={{ options: BASIC_ROLE_OPTIONS, singleSelect: true, skipSort: true }}
          />
          <div className="flex row justify-around">
            <FormButton loading={props.isSubmitting} text="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
};
