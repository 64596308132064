import { Loader } from '../../../_core/loader.component';
import { useRequestWithAuth } from '../../../domains/request/request.service';
import { RequestPageContainer } from '../request-page.container';
import ErrorPage from '../../InfoPages/error.page';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ROUTE_PATHS } from '../../../../_routes';

interface IAuthorizedRequestPageWrapper {
  requestId: string;
}

export const AuthorizedRequestPageWrapper = ({ requestId }: IAuthorizedRequestPageWrapper) => {
  const { request, loading, error } = useRequestWithAuth({ _id: requestId });
  const navigate = useNavigate();

  useEffect(() => {
    if ((!request || error) && !loading) navigate(ROUTE_PATHS.DASHBOARD);
  }, [loading, navigate, request, error]);

  if (!request)
    if (loading) return <Loader />;
    else return <ErrorPage>Request not found</ErrorPage>;

  if (!request?.editable) return <ErrorPage>You are missing sufficient permissions to view this request</ErrorPage>;

  return <RequestPageContainer request={request} />;
};
