import { IDepartment } from '../../../lib/types';
import { ICompanyDepartmentsResult } from './department.types';
import { GET_COMPANY_DEPARTMENTS } from './department.queries';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { mergeCacheLists } from '../../../lib/utils';
import { IArchivableProps } from '../../../lib/query.types';

export const updateDepartmentInCache = ({ cache, department }: IApolloCacheParams & { department: IDepartment }) => {
  const currCompanyDepartments = cache.readQuery<ICompanyDepartmentsResult, IArchivableProps>({
    query: GET_COMPANY_DEPARTMENTS,
    variables: { includeArchived: true }
  })?.departments;

  cache.writeQuery<ICompanyDepartmentsResult, IArchivableProps>({
    query: GET_COMPANY_DEPARTMENTS,
    data: { departments: mergeCacheLists(currCompanyDepartments ?? [], [department]) },
    variables: { includeArchived: true },
    overwrite: true
  });
};
