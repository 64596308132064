import { FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { IClient, IClientOverview, IEntityMember, TEMPLATE_TYPE } from '../../../../lib/types';
import { useCallback, useMemo, useState } from 'react';
import { ICreateRequestProps } from '../request.types';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';
import { useTemplates } from '../../template/template.service';
import { OrderedOwnershipLevels, Ownership } from '../../../../lib/global.types';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { CreateInviteUserContainer } from '../../invite/create-invite-user/create-invite-user-form.container';

interface IUseEditRequestFormTemplateFields {
  hasBeenCreated?: boolean;
  inputKey: string;
}

export const useEditRequestFormTemplateFields = ({ hasBeenCreated, inputKey }: IUseEditRequestFormTemplateFields) => {
  const { templates } = useTemplates({ includeArchived: false, types: [TEMPLATE_TYPE.REQUEST] });

  const templateOptions = useMemo(
    () =>
      templates
        ?.filter((t) => !t.deletedAt)
        ?.map(
          (t) =>
            ({
              value: t._id,
              label: t.title,
              secondaryLabel: t.description,
              group: t.company ? Ownership.ORGANIZATION : Ownership.GLOBAL
            } as IMultiSelectOption)
        ),
    [templates]
  );

  const TemplateInput = useCallback(
    () => (
      <>
        {!hasBeenCreated && (
          <FormInputWithLabel
            name={inputKey}
            label="Template"
            placeholder="Select Template"
            type="multiselect"
            multiSelectProps={{
              nullable: true,
              singleSelect: true,
              options: templateOptions,
              groupOrder: OrderedOwnershipLevels,
              menuPlacement: 'top'
            }}
            disabled={!templateOptions}
          />
        )}
      </>
    ),
    [hasBeenCreated, inputKey, templateOptions]
  );

  return { TemplateInput };
};

interface IUseEditRequestFormClientFields extends Pick<FormikProps<ICreateRequestProps>, 'setFieldValue'> {
  activeEntityMembers?: IEntityMember[];
  clients: IClientOverview[];
  clientsMultiLabel: string;
  clientsMultiKey: string;
  clientsSingleKey?: string;
  clientsToIgnore?: string[];
  clientGroupOrder?: string[];
  disabledForEntity?: boolean;
  values: ICreateRequestProps;
}

export const useEditRequestFormClientFields = ({
  activeEntityMembers,
  clients,
  clientsMultiLabel,
  clientsMultiKey,
  clientsSingleKey,
  clientsToIgnore = [],
  clientGroupOrder,
  disabledForEntity,
  setFieldValue,
  values
}: IUseEditRequestFormClientFields) => {
  const [newContactData, setNewContactData] = useState<{
    email: string;
    fieldName: string;
    isMulti: boolean;
  } | null>(null);

  const entityValues = useMemo(() => ('entity' in values ? (values.entity as IMultiSelectOption[]) : []), [values]);
  const clientOptions = useMemo(() => {
    return clients
      .filter((c) => !c.deletedAt && !c.user.deletedAt && !clientsToIgnore.includes(c._id))
      .map(
        (d) =>
          ({
            label: d.name ?? d.user.name,
            secondaryLabel: d.user.email,
            value: d._id,
            group: clientGroupOrder
              ? entityValues.length && activeEntityMembers?.find((em) => em.user._id === d.user._id)
                ? entityValues[0]?.label + "'s Contacts"
                : 'Non Org Contacts'
              : undefined
          } as IMultiSelectOption)
      );
  }, [activeEntityMembers, clientGroupOrder, clients, clientsToIgnore, entityValues]);

  const clientsValues = useMemo(
    () =>
      newContactData && newContactData.fieldName in values
        ? // @ts-expect-error Expect inference issue
          (values[newContactData.fieldName] as IMultiSelectOption[])
        : [],
    [newContactData, values]
  );
  const onAddContactSuccess = useCallback(
    ({ _id, name, user }: IClient) => {
      if (newContactData) {
        // Find existing client matching email if one exists, or if
        const lowerCaseEmail = user.email.toLocaleLowerCase();
        const newClientEntry = clientOptions?.find((c) => c.secondaryLabel?.toLocaleLowerCase() === lowerCaseEmail) ?? {
          value: _id,
          label: name ?? user.name,
          secondaryLabel: user.email
        };

        // Skip updating field if it already includes the added client
        if (clientOptions && (!newClientEntry.value || !clientsValues.find((c) => c.value === newClientEntry.value)))
          setFieldValue(
            newContactData.fieldName,
            newContactData.isMulti ? [...clientsValues, newClientEntry] : [newClientEntry]
          );

        setNewContactData(null);
      }
    },
    [clientOptions, newContactData, setFieldValue, clientsValues]
  );

  const SingleClientInput = useCallback(
    () => (
      <>
        {!!clientsSingleKey && (
          <FormInputWithLabel
            name={clientsSingleKey}
            label="Contact"
            placeholder="Primary contact"
            type="multiselect"
            multiSelectProps={{
              options: clientOptions,
              singleSelect: true,
              nullable: true,
              groupOrder: clientGroupOrder,
              canAdd: true,
              onAdd: (email) => setNewContactData({ email, fieldName: clientsSingleKey, isMulti: false })
            }}
            disabled={disabledForEntity}
          />
        )}
      </>
    ),
    [clientGroupOrder, clientOptions, clientsSingleKey, disabledForEntity]
  );

  const MultiClientInput = useCallback(
    () => (
      <FormInputWithLabel
        name={clientsMultiKey}
        label={clientsMultiLabel}
        type="multiselect"
        placeholder={clientsMultiLabel}
        multiSelectProps={{
          options: clientOptions,
          nullable: true,
          groupOrder: clientGroupOrder,
          canAdd: true,
          onAdd: (email) => setNewContactData({ email, fieldName: clientsMultiKey, isMulti: true })
        }}
        disabled={disabledForEntity}
      />
    ),
    [clientsMultiLabel, clientsMultiKey, clientOptions, clientGroupOrder, disabledForEntity]
  );

  const AddNewClientDialog = useCallback(
    () => (
      <>
        {!!newContactData && (
          <RIDialog open={!!newContactData} setOpen={(o) => setNewContactData((d) => (o ? d : null))} locked>
            <CreateInviteUserContainer
              email={newContactData.email}
              close={() => setNewContactData(null)}
              afterSuccess={onAddContactSuccess}
            />
          </RIDialog>
        )}
      </>
    ),
    [newContactData, onAddContactSuccess]
  );

  return { AddNewClientDialog, MultiClientInput, SingleClientInput };
};
