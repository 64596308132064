import { Bars2Icon, CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Button } from '../_core/button/button.component';
import { useCallback, useEffect, useState } from 'react';
import { OutputData } from '@editorjs/editorjs';
import { ISectionData } from '../_editor/section/section.types';
import { Transition } from '@headlessui/react';
import { determineLocalTitle } from '../_editor/_core/local-title-block/local-title-block.utils';
import { buildDefaultSection } from '../_editor/_core/editor.const';
import { useDrop } from '../_core/drag-and-drop/droppable.utils';
import { DragChildrenProps, Draggable } from '../_core/drag-and-drop/draggable.component';

export const EditorSectionNav = ({
  data,
  disabled,
  onUpdateOrder,
  readOnly,
  activeSection,
  setActiveSection,
  showAllSections,
  setShowAllSections,
  setTogglingState
}: {
  data: OutputData;
  disabled?: boolean;
  onUpdateOrder: (newOrder: string[]) => void;
  readOnly?: boolean;
  activeSection: number;
  setActiveSection: (_: number) => void;
  showAllSections: boolean;
  setShowAllSections: (_: boolean) => void;
  setTogglingState: (_: boolean) => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navDataCopy, setNavDataCopy] = useState(data.blocks);
  const [open, setOpen] = useState(true);

  useEffect(() => setNavDataCopy(data.blocks), [data]);

  useDrop({
    disabled,
    dragItemKey: 'id',
    onUpdate: (newData, newOrder) => {
      setNavDataCopy(newData);
      onUpdateOrder(newOrder);
    },
    setData: setNavDataCopy
  });

  // Quickly insert new blocks into the document
  const onQuickCreate = useCallback(() => {
    data.blocks.push(buildDefaultSection());
    setActiveSection(data.blocks.length - 1);
    setShowAllSections(false);
  }, [data.blocks, setActiveSection, setShowAllSections]);

  const iconSize = 16;
  return (
    <div className={`p-1 ${open ? 'w-80' : 'w-10'} transition-[width] duration-300 ease-in-out`}>
      <div className="sticky top-[7.15rem] z-30">
        <div className="mx-auto flex items-center justify-between w-full h-7  ">
          {open ? <span className="text-lg font-semibold transition duration-300 ease-in-out">Sections</span> : <div />}
          <Button
            slim
            containerClass="h-5"
            onClick={() => setOpen((prev) => !prev)}
            icon={
              open ? (
                <ChevronLeftIcon height={iconSize} width={iconSize} />
              ) : (
                <ChevronRightIcon height={iconSize} width={iconSize} />
              )
            }
            size="fit"
            variant="secondary"
          />
        </div>
        <Transition show={open}>
          <ul className="mt-2 list-none pl-2 transition duration-300 ease-in-out">
            <li
              className={`mb-1 p-1 flex items-start rounded focus:bg-blue-400 text-xs text-blue-400 focus:text-white ${
                showAllSections ? 'bg-blue-400 text-white' : ''
              } ${disabled ? `!bg-opacity-50 text-gray-200` : 'hover:text-blue-400 hover:bg-gray-200 '}`}
            >
              <Button
                slim
                className="!py-0 pl-6 pr-2 cursor-pointer !justify-start"
                textClass="text-xs text-start disabled:bg-none"
                onClick={() => {
                  if (!disabled) {
                    setShowAllSections(true);
                    setTogglingState(true);
                  }
                }}
                variant="custom"
              >
                View All
              </Button>
            </li>
            {navDataCopy.map((b, i) => {
              const sectionData = b.data as ISectionData;
              return (
                <Draggable
                  key={'section-toc-' + i}
                  DragPreview={() => sectionData.label}
                  data={{ dragItemId: b.id ?? '' }}
                >
                  {({ draggableRef, dragHandleRef, dragState }: DragChildrenProps<HTMLLIElement, HTMLDivElement>) => (
                    <li
                      className={`mb-1 p-1 flex items-start rounded focus:bg-blue-400 text-xs text-blue-400 focus:text-white ${
                        activeSection === i && !showAllSections ? 'bg-blue-400 text-white' : ''
                      } ${dragState.type === 'is-dragging' ? 'opacity-50' : ''} ${
                        disabled ? `!bg-opacity-50 text-gray-200` : 'hover:text-blue-400 hover:bg-gray-200 '
                      }`}
                      ref={disabled ? undefined : draggableRef}
                    >
                      <div
                        ref={disabled ? undefined : dragHandleRef}
                        className="flex items-center justify-center draggable mr-2 text-secondary"
                      >
                        <Bars2Icon height={16} width={16} />
                      </div>
                      <span className="w-6">{`${determineLocalTitle(0, i)})`}</span>
                      <Button
                        slim
                        className="!py-0 px-0 pr-2 cursor-pointer !justify-start"
                        textClass="text-xs text-start disabled:bg-none"
                        onClick={() => {
                          if (!disabled) {
                            setActiveSection(i);
                            setShowAllSections(false);
                          }
                        }}
                        variant="custom"
                      >
                        {sectionData.label}
                      </Button>
                      <div className="w-6">
                        {!!sectionData.completed && <CheckCircleIcon height={16} width={16} color="#0DDD01" />}
                      </div>
                    </li>
                  )}
                </Draggable>
              );
            })}
          </ul>
          {!readOnly && (
            <div className="mt-3">
              <Button
                disabled={disabled}
                icon={
                  <PlusIcon
                    height={16}
                    width={16}
                    className={`my-auto rounded-md bg-green-400 text-white mr-auto ${
                      disabled ? '' : 'hover:bg-green-300'
                    }`}
                  />
                }
                className={`ce-toolbar__button !p-0 !text-green-400 max-w-32 !w-full mx-0 ml-3 ${
                  disabled ? '' : 'hover:bg-opacity-60'
                }`}
                onClick={onQuickCreate}
                text="add section"
                variant="custom"
              />
            </div>
          )}
        </Transition>
      </div>
    </div>
  );
};
