import { useMutation, useQuery, useReactiveVar } from '@apollo/client';

import {
  GET_CLIENTS,
  DELETE_CLIENT,
  RESTORE_CLIENT,
  UPDATE_CLIENT,
  GET_CLIENT,
  GET_CLIENT_OVERVIEWS
} from './client.queries';
import { IClientsResults } from './client.types';
import { useCallback } from 'react';
import { IClient, Identifiable, IEntityMember } from '../../../lib/types';
import { updateClientCache } from './client.utils';
import { IArchivableProps, IUpdateCompanyPermissionsVariantParams } from '../../../lib/query.types';
import { updateEntityMemberCache } from '../entity-members/entity-members.utils';
import { activeCompanyLoadingVar } from '../companies/company.service';

export const useClient = (variables: Identifiable) => {
  const { data, ...rest } = useQuery<{ client: IClient }>(GET_CLIENT, { fetchPolicy: 'cache-and-network', variables });
  return { ...rest, client: data?.client };
};

export const useClients = () => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<IClientsResults, IArchivableProps>(GET_CLIENTS, {
    fetchPolicy: 'cache-and-network',
    variables: { includeArchived: true }
  });

  return { ...rest, ...data, loading: rest.loading || activeCompanyLoading };
};

export const useClientOverviews = () => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<IClientsResults, IArchivableProps>(GET_CLIENT_OVERVIEWS, {
    fetchPolicy: 'cache-and-network',
    variables: { includeArchived: true }
  });

  return { ...rest, ...data, loading: rest.loading || activeCompanyLoading };
};

export const useDeleteClient = () => {
  const [mutation, rest] = useMutation<{ deleteClient: IClient }, Identifiable>(DELETE_CLIENT, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateClientCache({ cache, clients: [data.deleteClient] });
    }
  });

  const deleteClient = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteClient, ...rest };
};

export const useRestoreClient = () => {
  const [mutation, rest] = useMutation<{ restoreClient: IClient }, Identifiable>(RESTORE_CLIENT, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateClientCache({ cache, clients: [data.restoreClient] });
    }
  });

  const restoreClient = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreClient, ...rest };
};

export const useUpdateClient = () => {
  const [mutation, rest] = useMutation<
    { updateClient: { client: IClient; members?: IEntityMember[] } },
    IUpdateCompanyPermissionsVariantParams
  >(UPDATE_CLIENT, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        // Update client cache
        updateClientCache({ cache, clients: [data.updateClient.client] });

        // Update entity members cache for current user (belonging to client), and for all entities that that client is a part of
        if (data.updateClient.members) {
          updateEntityMemberCache({
            cache,
            entityMembers: data.updateClient.members,
            variables: { userId: data.updateClient.client.user._id }
          });

          data.updateClient.members.map((member) =>
            updateEntityMemberCache({
              cache,
              entityMembers: [member],
              variables: { entityId: member.entity._id }
            })
          );
        }
      }
    }
  });

  const updateClient = useCallback(
    (variables: IUpdateCompanyPermissionsVariantParams) => mutation({ variables }),
    [mutation]
  );

  return { updateClient, ...rest };
};
