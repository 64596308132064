import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { attachClosestEdge, Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';

import { DropIndicator } from './drop-indicator';
import { DragState, IDLE } from './types';
import { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';

export interface DragChildrenProps<U extends HTMLElement, V extends HTMLElement> {
  dragState: DragState;
  draggableRef: MutableRefObject<U | null>;
  dragHandleRef: MutableRefObject<V | null>;
}

export const Draggable = <T extends { dragItemId: string }, U extends HTMLElement, V extends HTMLElement>({
  allowedEdges = ['bottom', 'top'],
  children,
  data,
  DragPreview,
  inline
}: {
  allowedEdges?: Edge[];
  children: (_: DragChildrenProps<U, V>) => React.ReactNode;
  data: T;
  DragPreview: () => React.ReactNode;
  inline?: boolean;
}) => {
  const draggableRef = useRef<U | null>(null);
  const dragHandleRef = useRef<V | null>(null);
  const [dragState, setDragState] = useState<DragState>(IDLE);

  useEffect(() => {
    const element = draggableRef.current;
    const dragHandle = dragHandleRef.current ?? draggableRef.current;

    let combineCleanup: CleanupFn | null = null;
    if (element && dragHandle) {
      combineCleanup = combine(
        draggable({
          element: dragHandle,
          getInitialData: () => ({ dragItemId: data.dragItemId }),
          onGenerateDragPreview({ nativeSetDragImage }) {
            setCustomNativeDragPreview({
              nativeSetDragImage,
              getOffset: pointerOutsideOfPreview({ x: '16px', y: '8px' }),
              render: ({ container }) => setDragState({ type: 'preview', container })
            });
          },
          onDragStart: () => setDragState({ type: 'is-dragging' }),
          onDrop: () => setDragState(IDLE)
        }),
        dropTargetForElements({
          element,
          canDrop({ source }) {
            // not allowing dropping on yourself
            if (source.element === element) return false;
            return 'dragItemId' in source.data;
          },
          getData({ input }) {
            return attachClosestEdge(data, { element, input, allowedEdges });
          },
          getIsSticky: () => true,
          onDragEnter({ self }) {
            const closestEdge = extractClosestEdge(self.data);
            setDragState({ type: 'is-dragging-over', closestEdge });
          },
          onDrag({ self, source }) {
            const isSource = source.element === element;
            if (isSource) {
              setDragState(IDLE);
              return;
            }

            // Only need to update react state if nothing has changed.
            // Prevents re-rendering.
            setDragState((current: DragState) => {
              const closestEdge = extractClosestEdge(self.data);
              if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
                return current;
              }
              return { type: 'is-dragging-over', closestEdge };
            });
          },
          onDragLeave: () => setDragState(IDLE),
          onDrop: () => setDragState(IDLE)
        })
      );
    }

    return () => {
      if (combineCleanup) combineCleanup();
    };
  }, [allowedEdges, data]);

  return (
    <>
      <div className={`relative ${inline ? 'inline-flex' : ''}`}>
        {children({ draggableRef, dragHandleRef, dragState })}
        {dragState.type === 'is-dragging-over' && dragState.closestEdge ? (
          <DropIndicator edge={dragState.closestEdge} gap={'8px'} />
        ) : null}
      </div>
      {dragState.type === 'preview'
        ? createPortal(
            <div className="border-solid rounded p-2 bg-white">
              <DragPreview />
            </div>,
            dragState.container
          )
        : null}
    </>
  );
};
