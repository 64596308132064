import { useCallback } from 'react';
import {
  ApolloError,
  makeVar,
  MutationHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar
} from '@apollo/client';
import {
  IBlockPackageUpdateResponse,
  IRequest,
  IRequestBlock,
  IRequestOverview,
  Identifiable,
  REQUEST_NOTIFY_OPTION
} from '../../../lib/types';
import {
  CREATE_REQUEST,
  DELETE_REQUEST,
  GET_REQUEST,
  GET_REQUEST_WITH_TOKEN,
  LIST_REQUESTS_AS_STAFF,
  RESTORE_REQUEST,
  SEND_REQUEST,
  UPDATE_REQUEST_WITH_TOKEN,
  UPDATE_REQUEST,
  UPDATE_REQUEST_AS_CLIENT,
  CLEAR_REQUEST,
  DUPLICATE_REQUEST,
  SEND_CUSTOM_REQUEST_MESSAGE,
  SEND_CUSTOM_REQUEST_MESSAGE_WITH_TOKEN,
  CREATE_REQUEST_FROM_TEMPLATE,
  UPDATE_REQUEST_ASSIGNMENT,
  UNSEND_REQUEST
} from './request.queries';
import {
  ICreateRequestCallParams,
  IListRequestAsStaffResults,
  IListRequestAsStaffVariables,
  IRequestByIdResults,
  IRequestWithTokenResults,
  ISendCustomRequestMessageParams,
  ISendRequestParams,
  IUpdateRequestCallParams
} from './request.types';
import toast from 'react-hot-toast';
import { mergeCacheLists, showError } from '../../../lib/utils';
import { readRequestsCache, writeRequestsCache } from './request.utils';
import { activeCompanyLoadingVar } from '../companies/company.service';
import { DEFAULT_LIMIT } from './request.consts';

export const requestPollingPausedVar = makeVar<boolean>(false);

interface IRequestWithTokenParams {
  requestId: string;
  token: string;
}

const onUpdateRequestError = (error: ApolloError, action?: string) => {
  if (error.message.includes('One or more contact IDs do not have access to this request')) {
    toast.error('One or more contacts do not have access to this request');
  } else if (action) showError(`Failed to ${action} request`, error);
};

export const useClearRequest = ({ _id }: Identifiable, afterClear?: () => void) => {
  const [mutation, rest] = useMutation<{ clearRequest: IRequest }>(CLEAR_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.clearRequest) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.clearRequest } as IRequestByIdResults,
          variables: { _id },
          overwrite: true
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: mergeCacheLists(listRequestsAsStaff.requests, [data.clearRequest])
            }
          });

        afterClear?.();
      }
    },
    onCompleted: () => toast.success('Request cleared successfully!'),
    onError: (error) => onUpdateRequestError(error, 'clear')
  });

  const clearRequest = useCallback(() => mutation({ variables: { _id } }), [_id, mutation]);

  return { clearRequest, ...rest };
};

export const useDuplicateRequest = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ duplicateRequest: IRequest }>(DUPLICATE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.duplicateRequest) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.duplicateRequest } as IRequestByIdResults,
          variables: { _id: data.duplicateRequest._id },
          overwrite: true
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: mergeCacheLists(listRequestsAsStaff.requests, [data.duplicateRequest])
            }
          });
      }
    },
    onCompleted: () => toast.success('Request duplicated successfully!'),
    onError: (error) => {
      onUpdateRequestError(error, 'duplicate');
      throw error;
    }
  });

  const duplicateRequest = useCallback((clear: boolean) => mutation({ variables: { _id, clear } }), [_id, mutation]);

  return { duplicateRequest, ...rest };
};

export const useRequestWithToken = (variables: IRequestWithTokenParams) => {
  const skip = useReactiveVar(requestPollingPausedVar);

  const { data, ...rest } = useQuery<IRequestWithTokenResults, IRequestWithTokenParams>(GET_REQUEST_WITH_TOKEN, {
    variables,
    skip
  });

  return { ...rest, request: data?.requestByWithToken };
};

export const useRequestWithAuth = (variables: Identifiable) => {
  const skip = useReactiveVar(requestPollingPausedVar);

  const { data, ...rest } = useQuery<IRequestByIdResults, Identifiable>(GET_REQUEST, {
    fetchPolicy: 'cache-and-network',
    variables,
    skip
  });

  return { ...rest, request: data?.requestById };
};

export const useLazyRequest = () => {
  const [query, rest] = useLazyQuery<IRequestByIdResults, Identifiable>(GET_REQUEST, {
    fetchPolicy: 'cache-and-network'
  });

  const getRequest = (variables: Identifiable) => query({ variables });
  return { getRequest, ...rest };
};

export const useRequests = ({ limit = DEFAULT_LIMIT, ...restVars }: IListRequestAsStaffVariables = {}) => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<IListRequestAsStaffResults, IListRequestAsStaffVariables>(LIST_REQUESTS_AS_STAFF, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60 * 1000,
    variables: { ...restVars, limit }
  });

  return { ...rest, requests: data?.listRequestsAsStaff.requests, loading: rest.loading || activeCompanyLoading };
};

const useCreateBaseRequest = () => {
  const [mutation, rest] = useMutation<{ createRequest: IRequest }>(CREATE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.createRequest) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.createRequest } as IRequestByIdResults,
          overwrite: true
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: [...listRequestsAsStaff.requests, data.createRequest]
            }
          });
      }
    },
    onCompleted: () => toast.success('Request created successfully!'),
    onError: (error) => {
      if (error.message.includes('One or more contact IDs do not have access to this request')) {
        toast.error('One or more contacts do not have access to this request');
      }
    }
  });

  const createRequest = useCallback((variables: ICreateRequestCallParams) => mutation({ variables }), [mutation]);

  return { createRequest, ...rest };
};

const useCreateRequestFromTemplate = () => {
  const [mutation, rest] = useMutation<{ createRequestFromTemplate: IRequest }>(CREATE_REQUEST_FROM_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.createRequestFromTemplate) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.createRequestFromTemplate } as IRequestByIdResults,
          overwrite: true
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: [...listRequestsAsStaff.requests, data.createRequestFromTemplate]
            }
          });
      }
    },
    onCompleted: () => toast.success('Request created successfully!'),
    onError: (error) => {
      if (error.message.includes('One or more contact IDs do not have access to this request')) {
        toast.error('One or more contacts do not have access to this request');
      }
    }
  });

  const createRequestFromTemplate = useCallback(
    (variables: ICreateRequestCallParams) => mutation({ variables }),
    [mutation]
  );

  return { createRequestFromTemplate, ...rest };
};

export const useCreateRequest = () => {
  const { createRequest: createBaseRequest, loading: creatingBaseRequest } = useCreateBaseRequest();
  const { createRequestFromTemplate, loading: creatingRequestFromTemplate } = useCreateRequestFromTemplate();

  const createRequest = useCallback(
    async (variables: ICreateRequestCallParams) => {
      let request: IRequest | null;
      if (variables.templateId)
        request = (await createRequestFromTemplate(variables)).data?.createRequestFromTemplate ?? null;
      else request = (await createBaseRequest(variables)).data?.createRequest ?? null;

      return request;
    },
    [createBaseRequest, createRequestFromTemplate]
  );

  return { createRequest, loading: creatingBaseRequest || creatingRequestFromTemplate };
};

export const useSendRequest = () => {
  const [mutation, rest] = useMutation<{ sendRequest: IRequest }, ISendRequestParams>(SEND_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }, { variables }) => {
      if (data?.sendRequest) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.sendRequest } as IRequestByIdResults,
          variables: { _id: variables?._id },
          overwrite: true
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: mergeCacheLists(listRequestsAsStaff.requests, [data.sendRequest])
            }
          });
      }
    },
    onCompleted: () => toast.success('Request sent successfully!'),
    onError: (error) => {
      const errMsg = error.message.toLowerCase();
      if (errMsg.includes('blocks are required')) toast.error('Request must have content');
      else if (errMsg.includes('the request must be assigned to a contact'))
        toast.error('Request must be assigned to a contact');
      else showError('Failed to send request', error);
    }
  });

  const sendRequest = useCallback((variables: ISendRequestParams) => mutation({ variables }), [mutation]);

  return { sendRequest, ...rest };
};

export const useUpdateRequest = ({ _id, lastUpdatedAt }: Identifiable & { lastUpdatedAt?: Date }) => {
  const [mutation, rest] = useMutation<{ updateRequest: IBlockPackageUpdateResponse }>(UPDATE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateRequest.request) {
        if (data.updateRequest.error)
          toast.error(`Failed to update request. ${data.updateRequest.error}.`, { id: 'request-update-' + _id });

        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.updateRequest.request } as IRequestByIdResults,
          variables: { _id },
          overwrite: true
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: mergeCacheLists(listRequestsAsStaff.requests, [data.updateRequest.request])
            }
          });
      } else showError('Failed to update request', new Error(data?.updateRequest.error ?? 'Unknown error'));
    },
    onError: (error) => onUpdateRequestError(error)
  });

  const updateRequest = useCallback(
    async (variables: IUpdateRequestCallParams) => {
      const result = await mutation({ variables: { ...variables, _id, lastUpdatedAt } });
      return result.data?.updateRequest;
    },
    [_id, lastUpdatedAt, mutation]
  );

  return { updateRequest, ...rest };
};

export const useUpdateRequestAsClient = (variables: IRequestWithTokenParams & { lastUpdatedAt: Date }) => {
  const [asClientMutation, asClientRest] = useMutation<{ updateRequestAsClient: IBlockPackageUpdateResponse }>(
    UPDATE_REQUEST_AS_CLIENT,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data?.updateRequestAsClient.request) {
          cache.writeQuery({
            query: GET_REQUEST_WITH_TOKEN,
            data: { requestByWithToken: data.updateRequestAsClient.request } as IRequestWithTokenResults,
            variables,
            overwrite: true
          });
        }
      }
    }
  );

  const [withTokenMutation, withTokenRest] = useMutation<{ updateRequestWithToken: IBlockPackageUpdateResponse }>(
    UPDATE_REQUEST_WITH_TOKEN,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data?.updateRequestWithToken.request) {
          cache.writeQuery({
            query: GET_REQUEST_WITH_TOKEN,
            data: { requestByWithToken: data.updateRequestWithToken.request } as IRequestWithTokenResults,
            variables,
            overwrite: true
          });
        }
      }
    }
  );

  const updateRequestAsClient = useCallback(
    async (blocks: IRequestBlock[]) => {
      const mutationData: { variables: { blocks: IRequestBlock[]; _id: string; lastUpdatedAt: Date; token?: string } } =
        { variables: { blocks, _id: variables.requestId, lastUpdatedAt: variables.lastUpdatedAt } };

      if (variables.token) {
        mutationData.variables.token = variables.token;
        return (await withTokenMutation(mutationData)).data?.updateRequestWithToken;
      }
      return (await asClientMutation(mutationData)).data?.updateRequestAsClient;
    },
    [asClientMutation, variables.lastUpdatedAt, variables.requestId, variables.token, withTokenMutation]
  );

  return { updateRequestAsClient, ...(variables.token ? withTokenRest : asClientRest) };
};

export const useUpdateRequestAssignment = () => {
  const [mutation, rest] = useMutation<{ updateRequestAssignment: IRequestOverview }, Identifiable>(
    UPDATE_REQUEST_ASSIGNMENT,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data) {
          const listRequestsAsStaff = readRequestsCache({ cache });
          if (listRequestsAsStaff)
            writeRequestsCache({
              cache,
              listRequestsAsStaff: {
                ...listRequestsAsStaff,
                requests: data.updateRequestAssignment.currStaffIsAssigned
                  ? mergeCacheLists(listRequestsAsStaff.requests, [data.updateRequestAssignment])
                  : listRequestsAsStaff.requests.filter((r) => r._id !== data.updateRequestAssignment._id)
              }
            });
        }
      }
    }
  );

  const updateRequestAssignment = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { updateRequestAssignment, ...rest };
};

export const useDeleteRequest = () => {
  const [mutation, rest] = useMutation<{ deleteRequest: IRequest }, Identifiable>(DELETE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: mergeCacheLists(listRequestsAsStaff.requests, [data.deleteRequest])
            }
          });
      }
    }
  });

  const deleteRequest = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteRequest, ...rest };
};

export const useRestoreRequest = () => {
  const [mutation, rest] = useMutation<{ restoreRequest: IRequest }, Identifiable>(RESTORE_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: mergeCacheLists(listRequestsAsStaff.requests, [data.restoreRequest])
            }
          });
      }
    }
  });

  const restoreRequest = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreRequest, ...rest };
};

export const useSendCustomRequestMessage = ({
  isResponse,
  requestId,
  token
}: IRequestWithTokenParams & { isResponse?: boolean }) => {
  const mutationOptions: MutationHookOptions = {
    context: { serializationKey: 'MUTATION', tracked: true },
    onCompleted: () =>
      isResponse ? toast.success('Submitted request succesfully!') : toast.success('Message sent successfully!'),
    onError: (error: Error) => showError('Failed to send message', error),
    update: (cache) => {
      if (isResponse) {
        // Update request to mark as client finished working
        const listRequestsAsStaff = readRequestsCache({ cache });
        const currRequestOverview = listRequestsAsStaff?.requests.find((r) => r._id === requestId);

        if (listRequestsAsStaff && currRequestOverview)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: mergeCacheLists(listRequestsAsStaff.requests, [
                { ...currRequestOverview, clientFinishedWorking: true }
              ])
            }
          });

        if (token) {
          const currRequestDetails = cache.readQuery<IRequestWithTokenResults, IRequestWithTokenParams>({
            query: GET_REQUEST_WITH_TOKEN,
            variables: { requestId, token }
          })?.requestByWithToken;

          if (currRequestDetails)
            cache.writeQuery<IRequestWithTokenResults, IRequestWithTokenParams>({
              query: GET_REQUEST_WITH_TOKEN,
              data: { requestByWithToken: { ...currRequestDetails, clientFinishedWorking: true } },
              variables: { requestId, token },
              overwrite: true
            });
        } else {
          const currRequestDetails = cache.readQuery<IRequestByIdResults, Identifiable>({
            query: GET_REQUEST,
            variables: { _id: requestId }
          })?.requestById;

          if (currRequestDetails)
            cache.writeQuery<IRequestByIdResults, Identifiable>({
              query: GET_REQUEST,
              data: { requestById: { ...currRequestDetails, clientFinishedWorking: true } },
              variables: { _id: requestId },
              overwrite: true
            });
        }
      }
    }
  };

  const [authenticatedMutation, asAuthenticatedRest] = useMutation<{ sendCustomRequestMessage: boolean }>(
    SEND_CUSTOM_REQUEST_MESSAGE,
    mutationOptions
  );

  const [withTokenMutation, withTokenRest] = useMutation<{ sendCustomRequestMessageWithToken: boolean }>(
    SEND_CUSTOM_REQUEST_MESSAGE_WITH_TOKEN,
    mutationOptions
  );

  const sendCustomRequestMessage = useCallback(
    ({ notify = [REQUEST_NOTIFY_OPTION.ALL], ...restParams }: ISendCustomRequestMessageParams) => {
      const mutationData: {
        variables: ISendCustomRequestMessageParams & Identifiable & { completed?: boolean; token?: string };
      } = {
        variables: { ...restParams, token, _id: requestId, notify }
      };

      if (token) {
        mutationData.variables.token = token;
        if (isResponse) mutationData.variables.completed = true;
        return withTokenMutation(mutationData);
      }
      return authenticatedMutation(mutationData);
    },
    [authenticatedMutation, isResponse, requestId, token, withTokenMutation]
  );

  return { sendCustomRequestMessage, ...(token ? withTokenRest : asAuthenticatedRest) };
};

export const useUnsendRequest = ({ _id }: Identifiable) => {
  const [mutation, rest] = useMutation<{ unsendRequest: IRequest }>(UNSEND_REQUEST, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.unsendRequest) {
        cache.writeQuery({
          query: GET_REQUEST,
          data: { requestById: data.unsendRequest } as IRequestByIdResults,
          variables: { _id },
          overwrite: true
        });

        const listRequestsAsStaff = readRequestsCache({ cache });
        if (listRequestsAsStaff)
          writeRequestsCache({
            cache,
            listRequestsAsStaff: {
              ...listRequestsAsStaff,
              requests: mergeCacheLists(listRequestsAsStaff.requests, [data.unsendRequest])
            }
          });
      }
    },
    onCompleted: () => toast.success('Request unsent successfully!'),
    onError: (error) => onUpdateRequestError(error, 'unsend')
  });

  const unsendRequest = useCallback(() => mutation({ variables: { _id } }), [_id, mutation]);

  return { unsendRequest, ...rest };
};
