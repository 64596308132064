// Components
import { LandingPage } from './landing-page.component';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import { useAuth0WithRedirect } from '../../_auth/auth.utils';

export const LandingPageContainer = () => {
  const {
    loginWithRedirect,
    logout,
    error,
    isLoading: isAuthLoading,
    isAuthenticated,
    InviteCodeDialog,
    registerWithRedirect
  } = useAuth0WithRedirect();

  const [loading, setLoading] = useState(false);

  const startAuth0 = useCallback(
    (type?: 'login' | 'signup') => {
      setLoading(true);
      if (type === 'signup') registerWithRedirect();
      else
        loginWithRedirect({ type, appState: { returnTo: ROUTE_PATHS.DASHBOARD } }).finally(() =>
          setTimeout(() => setLoading(false), 500)
        );
    },
    [loginWithRedirect, registerWithRedirect]
  );

  useEffect(() => {
    if (error) {
      console.error('Authentication error', error);
      logout();
    }
  }, [error, logout]);

  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      setTimeout(() => {
        navigate(ROUTE_PATHS.DASHBOARD);
        setLoading(false);
      }, 500);
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <LandingPage onSignIn={startAuth0} isLoading={isAuthLoading || loading} />
      {InviteCodeDialog}
    </>
  );
};
