import { useRemoveInvite } from '../../../../domains/invite/invite.service';
import { useRemoveMember } from '../../../../domains/member/member.service';
import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { IStaffTableMultiselectActionProps } from './staff-table-multiselect-action-button.types';
import { showError } from '../../../../../lib/utils';
import toast from 'react-hot-toast';

export const useDeleteStaffAction = ({
  currUserId,
  getSelectedStaffModels,
  clearSelected
}: IStaffTableMultiselectActionProps): IButtonDropdownOptionProps => {
  const { removeInvite } = useRemoveInvite();
  const { removeMember } = useRemoveMember();

  return {
    onClick: () => {
      const selected = getSelectedStaffModels();
      if (!selected) toast.error('Unable to determine selected');
      else {
        const selectedInvites = selected.selectedInvites;
        const selectedMembers = selected.selectedMembers.filter(({ user }) => user._id !== currUserId);
        if (!selectedInvites.length && !selectedMembers.length)
          toast.error('No invites or non-current user members selected');
        else {
          selectedInvites.forEach((invite) =>
            removeInvite(invite).catch((err) => showError('Unable to delete invite ' + invite.email, err))
          );
          selectedMembers.forEach((member) =>
            removeMember(member).catch((err) =>
              showError('Unable to delete member ' + (member.name ?? member.user.name), err)
            )
          );
          clearSelected();
        }
      }
    },
    text: 'Delete'
  };
};
