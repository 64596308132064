import { useMutation, useQuery, useReactiveVar } from '@apollo/client';

import { ICreateEntityParams, IEntitiesResults } from './entity.types';
import { IEntity, Identifiable } from '../../../lib/types';
import { useCallback } from 'react';
import { updateEntityCache } from './entity.utils';
import {
  CREATE_ENTITY,
  DELETE_ENTITY,
  GET_ENTITIES,
  GET_ENTITY,
  GET_ENTITY_OVERVIEWS,
  RESTORE_ENTITY,
  UPDATE_ENTITY
} from './entity.queries';
import { IArchivableProps, IUpdateCompanyPermissionsVariantParams } from '../../../lib/query.types';
import { activeCompanyLoadingVar } from '../companies/company.service';

export const useEntity = (variables: Identifiable) => {
  const { data, ...rest } = useQuery<{ entity: IEntity }>(GET_ENTITY, { fetchPolicy: 'cache-and-network', variables });
  return { ...rest, ...data };
};

export const useEntities = () => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<IEntitiesResults, IArchivableProps>(GET_ENTITIES, {
    fetchPolicy: 'cache-and-network',
    variables: { includeArchived: true }
  });
  return { ...rest, ...data, loading: rest.loading || activeCompanyLoading };
};

export const useEntityOverviews = () => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<IEntitiesResults, IArchivableProps>(GET_ENTITY_OVERVIEWS, {
    fetchPolicy: 'cache-and-network',
    variables: { includeArchived: true }
  });
  return { ...rest, ...data, loading: rest.loading || activeCompanyLoading };
};

export const useCreateEntity = () => {
  const [mutation, rest] = useMutation<{ createEntity: IEntity }, ICreateEntityParams>(CREATE_ENTITY, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateEntityCache({ cache, entities: [data.createEntity] });
    }
  });

  const createEntity = useCallback((variables: ICreateEntityParams) => mutation({ variables }), [mutation]);

  return { createEntity, ...rest };
};

export const useDeleteEntity = () => {
  const [mutation, rest] = useMutation<{ deleteEntity: IEntity }, Identifiable>(DELETE_ENTITY, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateEntityCache({ cache, entities: [data.deleteEntity] });
    }
  });

  const deleteEntity = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { deleteEntity, ...rest };
};

export const useRestoreEntity = () => {
  const [mutation, rest] = useMutation<{ restoreEntity: IEntity }, Identifiable>(RESTORE_ENTITY, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateEntityCache({ cache, entities: [data.restoreEntity] });
    }
  });

  const restoreEntity = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreEntity, ...rest };
};

export const useUpdateEntity = () => {
  const [mutation, rest] = useMutation<{ updateEntity: IEntity }, IUpdateCompanyPermissionsVariantParams>(
    UPDATE_ENTITY,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data) updateEntityCache({ cache, entities: [data.updateEntity] });
      }
    }
  );

  const updateEntity = useCallback(
    (variables: IUpdateCompanyPermissionsVariantParams) => mutation({ variables }),
    [mutation]
  );

  return { updateEntity, ...rest };
};
