import { useState } from 'react';
import { AppState, useAuth0 } from '@auth0/auth0-react';
import { RIDialog } from '../_core/dialog/dialog.component';
import { FetchUserInviteCodeContainer } from '../domains/user-invite-code/fetch/fetch-user-invite-code-form.container';
import { ROUTE_PATHS } from '../../_routes';

export const useAuth0WithRedirect = () => {
  const { loginWithRedirect, ...rest } = useAuth0();

  const [showUserInviteCodeInput, setShowUserInviteCodeInput] = useState(false);

  const startAuth0 = ({
    type = 'login',
    appState,
    email
  }: {
    type?: 'login' | 'signup';
    appState?: AppState;
    email?: string;
  }) => {
    return loginWithRedirect({
      authorizationParams: { screen_hint: type, login_hint: email },
      appState
    });
  };

  const InviteCodeDialog = (
    <RIDialog open={showUserInviteCodeInput} setOpen={setShowUserInviteCodeInput}>
      <FetchUserInviteCodeContainer
        onBack={() => setShowUserInviteCodeInput(false)}
        onSubmit={({ _id, email }) => {
          setShowUserInviteCodeInput(false);
          startAuth0({
            type: 'signup',
            appState: { returnTo: ROUTE_PATHS.DASHBOARD + '?foundCode=' + _id },
            email
          });
        }}
      />
    </RIDialog>
  );

  return {
    ...rest,
    loginWithRedirect: startAuth0,
    registerWithRedirect: () => setShowUserInviteCodeInput(true),
    InviteCodeDialog
  };
};
