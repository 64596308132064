import { Listbox, Transition } from '@headlessui/react';
import { useCurrCompany, useUserCompanies } from '../../domains/companies/company.service';
import { useSetActiveCompany } from '../../domains/users/user.service';
import { Loader } from '../loader.component';
import { UpDownIcon } from '../icon/icon.components';
import { showError } from '../../../lib/utils';

export const FirmSelector = () => {
  const { company, loading: loadingCurrCompany } = useCurrCompany();
  const { activeCompanies } = useUserCompanies({});

  const { setActiveCompany, loading: settingActiveCompany } = useSetActiveCompany();

  if (!activeCompanies || activeCompanies.length < 1) return null;

  return (
    <div className="relative h-full">
      <Listbox
        value={company}
        onChange={(c) => {
          if (c) setActiveCompany(c?._id).catch((err) => showError('Unable to change active organization', err));
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Button className="bg-primary text-white xl:w-96 lg:w-76 md:w-64 xs:w-48 h-full relative">
              {settingActiveCompany || (!company && loadingCurrCompany) ? (
                <Loader center color="fill-white" />
              ) : (
                <div className="max-w-84 truncate ml-4 mr-auto text-start">{company?.name}</div>
              )}
              <div className="absolute flex right-0 h-full top-0 items-center">
                <UpDownIcon spacing="smallRight" open={open} />
              </div>
            </Listbox.Button>
            <Transition
              className="absolute z-50 bg-tertiary text-secondary w-full max-h-48 overflow-y-auto"
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Listbox.Options>
                {activeCompanies.map((c) => (
                  <Listbox.Option
                    className="text-center py-2 cursor-pointer hover:bg-secondary hover:text-white"
                    key={'select-company-opt-' + c._id}
                    value={c}
                  >
                    <div className="max-w-96 text-start truncate mx-4">{c.name}</div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};
