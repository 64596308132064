import { windowMaxWidth } from '../../_layouts/consts';

import './info-page.css';

export default function TermsAndConditionsPage() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      <div className={'info-page mx-auto w-full p-4 flex flex-1 flex-col m-6 bg-white rounded-2xl ' + windowMaxWidth}>
        <h1>Terms of Service for Request IQ Inc.</h1>
        <h2>Effective Date: January 1, 2025</h2>
        <p>
          These Terms of Service ("Agreement") govern your use of the Request IQ Inc. platform, which provides
          software-as-a-service document management services for confidential client financial data. By accessing or
          using our platform, you agree to be bound by these terms.
        </p>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By using our services, you agree to comply with and be bound by these Terms of Service, as well as any other
          rules or policies that apply to the platform. If you do not agree with these terms, you must not use our
          services.
        </p>
        <h2>2. License to Use the Platform</h2>
        <p>
          Request IQ Inc. grants you a limited, non-exclusive, non-transferable license to access and use the platform
          for the purposes of managing and storing confidential financial data. You may not use the platform for any
          illegal or unauthorized purposes.
        </p>
        <h2>3. User Responsibilities</h2>
        <p>You are responsible for:</p>
        <ul>
          <li>Ensuring the confidentiality and security of your login credentials and account.</li>
          <li>Complying with all applicable laws and regulations when using the platform.</li>
          <li>
            Not uploading, storing, or transmitting any content that is unlawful, harmful, or violates the rights of
            others.
          </li>
        </ul>
        <p className="mt-2">You agree not to:</p>
        <ul>
          <li>Attempt to hack, alter, or disrupt the platform.</li>
          <li>Share your account details with unauthorized users.</li>
          <li>Violate any applicable laws or regulations in your use of the platform.</li>
        </ul>
        <h2>4. Data Ownership and Responsibility</h2>
        <p>
          You retain ownership of the financial data you upload and store on the platform. However, by using our
          platform, you grant Request IQ Inc. the right to process and store your data for the purpose of providing
          services, as outlined in our Privacy Policy.
        </p>
        <h2>5. Tracking and Monitoring</h2>
        <p>
          You acknowledge and agree that Request IQ Inc. retains the right to track user activity on the platform for
          purposes of improving services, monitoring for security threats, and ensuring compliance with this Agreement.
        </p>
        <h2>6. Fees and Payment</h2>
        <p>
          By using our services, you agree to pay any applicable fees as outlined in the pricing section of our website
          or as specified in your contract. Fees may be subject to change, and you will be notified of any changes.
        </p>
        <h2>7. Termination of Service</h2>
        <p>
          Request IQ Inc. may suspend or terminate your account if you violate these Terms of Service or engage in any
          illegal, harmful, or disruptive behavior. You may also terminate your account at any time by contacting our
          support team.
        </p>
        <h2>8. Data Retention</h2>
        <p>
          Request IQ Inc. will retain your data for as long as your account is active. If your account becomes inactive,
          we have the right to delete your data within 30 days. However, we may retain certain information if necessary
          for legal or regulatory purposes. An inactive account is consider to be any account that is not under a paid
          contract and has not been logged into for 180 days.
        </p>
        <h2>9. Limitation of Liability</h2>
        <p>
          Request IQ Inc. will not be liable for any indirect, incidental, special, or consequential damages arising
          from your use of the platform. Our liability to you will not exceed the amount you paid for the service in the
          past six months.
        </p>
        <h2>10. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless Request IQ Inc., its officers, directors, employees, and agents from
          any claims, damages, or liabilities arising from your use of the platform, including any breach of these Terms
          of Service.
        </p>
        <h2>11. Governing Law</h2>
        <p>
          These Terms of Service are governed by the laws of the Province of Manitoba, Canada. Any disputes arising from
          this Agreement will be resolved in the courts located in Manitoba.
        </p>
        <h2>12. Amendments to the Terms of Service</h2>
        <p>
          Request IQ Inc. reserves the right to modify or amend these Terms of Service at any time. Any changes will be
          posted on this page, with an updated effective date. Your continued use of the platform after such changes
          constitutes your acceptance of the modified terms and should be reviewed regularly.
        </p>
        <h2>13. Contact Information</h2>
        <p className="mb-2">
          f you have any questions, concerns, or requests regarding this Terms of Service Agreement of Request IQ Inc.,
          please contact us at:
        </p>
        <p>
          Email: <a href="mailto:kevin@requestiq.com">Kevin@RequestIQ.com</a>
        </p>
        <p>
          Phone: <a href="tel:+12049902825">+1 (204) 990-2825</a>
        </p>
        <h2>
          <b>Effective Date:</b> January 1, 2025
        </h2>
        <p>
          By using the Platform, you acknowledge that you have read, understood, and agreed to this Terms of Service
          Agreement.
        </p>
      </div>
    </div>
  );
}
