import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileExcel, faFilePdf, faFileVideo, faFileWord, faImage } from '@fortawesome/free-regular-svg-icons';
import { Bars2Icon, XMarkIcon } from '@heroicons/react/20/solid';
import isImage from 'is-image';
import isVideo from 'is-video';

import { inputClassesLineHeightStr, textClasses } from '../_core/editor.const';
import { UploadInput } from './upload-input';
import { DeleteUploadButton } from './delete-upload-button';
import { UploadItemData, UploadMode } from './upload.types';
import { DownloadAssetButton } from '../../domains/assets/download-asset-button';
import { IAsset } from '../../../lib/types';
import { AutoSizeTextArea } from '../_core/autosizetextarea.component';
import { EditorSaveResult } from '../_core/types';
import { LOCAL_TITLE_CLASS, LOCAL_TITLE_SUFFIX } from '../_core/local-title-block/local-title-block.component';
import { determineLocalTitle } from '../_core/local-title-block/local-title-block.utils';
import { getDownloadFileName } from '../../domains/assets/download-utils';
import { DragChildrenProps, Draggable } from '../../_core/drag-and-drop/draggable.component';

export const UploadFile = ({
  blockId,
  data,
  disabled,
  index,
  mode,
  nestedDepth,
  onFileUpdate,
  onRemove,
  onSave,
  onUpdate,
  readOnly,
  requestId,
  requestToken,
  uid
}: {
  blockId?: string;
  data: UploadItemData;
  disabled?: boolean;
  index: number;
  mode: UploadMode;
  nestedDepth: number;
  onFileUpdate: (a: IAsset, originalFileData?: UploadItemData) => UploadItemData;
  onRemove: () => void;
  onSave: () => Promise<EditorSaveResult>;
  onUpdate: (_: UploadItemData) => void;
  readOnly: boolean;
  requestId?: string;
  requestToken?: string;
  uid: string;
}) => {
  const uploadRef = useRef<HTMLInputElement | null>(null);

  const [itemData, setData] = useState(data);
  const { fileContainerId, fileName, fileId, label, notes, clientAdded } = itemData;

  const getFileTypeIcon = (fileName: string) => {
    const iconProps = { fontSize: 24 };
    if (isImage(fileName)) return <FontAwesomeIcon icon={faImage} {...iconProps} />;
    if (isVideo(fileName)) return <FontAwesomeIcon icon={faFileVideo} {...iconProps} />;

    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') return <FontAwesomeIcon icon={faFilePdf} {...iconProps} />;
    if (extension?.startsWith('doc')) return <FontAwesomeIcon icon={faFileWord} {...iconProps} />;
    if (extension?.startsWith('xl') || extension === 'csv' || extension === 'numbers')
      return <FontAwesomeIcon icon={faFileExcel} {...iconProps} />;

    return <FontAwesomeIcon icon={faFile} {...iconProps} />;
  };

  const handleUpdate = (a: IAsset) => {
    setData((prev) => {
      const newData = onFileUpdate(a, prev);
      onUpdate(newData);
      return newData;
    });
  };

  const isProvide = mode === 'provide';
  const isEditable = !isProvide || !readOnly;

  const fileIcon = <div className={`flex items-center w-7`}>{getFileTypeIcon(fileName)}</div>;

  const downloadButton = (
    <div className="flex items-center">
      <DownloadAssetButton
        {...{ fileContainerId, fileName, fileId, label, notes }}
        getLatestFileLabel={() => label ?? null}
        requestToken={requestToken}
      />
    </div>
  );

  const isDraggable = !readOnly || !isProvide;

  const LocalTitleContainer = useCallback(
    ({ dragHandleRef }: { dragHandleRef: MutableRefObject<HTMLDivElement | null> }) => (
      <>
        <input
          className={`hidden file-id-input ${blockId ? 'input-' + blockId : ''}`}
          value={fileContainerId ?? fileId ?? ''}
          readOnly
        />
        {isDraggable && (
          <div>
            <div ref={dragHandleRef} className="flex items-center justify-center draggable h-8">
              <Bars2Icon height={20} width={20} />
            </div>
          </div>
        )}
        {!!nestedDepth && (
          <b className={`${inputClassesLineHeightStr} w-full max-w-4 ${LOCAL_TITLE_CLASS}`}>
            {determineLocalTitle(nestedDepth, index) + LOCAL_TITLE_SUFFIX}
          </b>
        )}
      </>
    ),
    [blockId, fileContainerId, fileId, index, isDraggable, nestedDepth]
  );

  return (
    <Draggable
      DragPreview={() => (
        <div className="border-solid rounded p-2 bg-white">
          {getDownloadFileName({ itemData, skipFileElementCheck: true })}
        </div>
      )}
      data={{ dragItemId: fileContainerId }}
    >
      {({ dragState, draggableRef, dragHandleRef }: DragChildrenProps<HTMLDivElement, HTMLDivElement>) => (
        <div
          id={'file-' + fileContainerId}
          className={`file-container flex gap-4 overflow-x-auto ml-8 ${
            dragState.type === 'is-dragging' ? 'opacity-50' : ''
          }`}
          ref={isDraggable ? draggableRef : undefined}
          data-handler-id={fileContainerId}
        >
          {isProvide && readOnly ? (
            <div className="flex gap-2 items-center w-full">
              <LocalTitleContainer dragHandleRef={dragHandleRef} />
              {!isEditable && <div className="mt-0.5">{downloadButton}</div>}
              {!!fileId && fileIcon}
              <div className="flex w-full items-center pr-2">
                <span className={`!text-2xs max-h-[1.5rem] text-ellipsis line-clamp-2`}>
                  {getDownloadFileName({ itemData, skipFileElementCheck: true })}
                </span>
              </div>
            </div>
          ) : (
            <>
              {/* TODO: The start of the upload inputs are not lining up with question answer inputs due to extra gaps */}
              <div className="flex w-3/4 gap-4">
                <LocalTitleContainer dragHandleRef={dragHandleRef} />
                {isEditable ? (
                  <div className="w-full">
                    <AutoSizeTextArea
                      initialValue={label ?? ''}
                      onChange={(v) =>
                        setData((d) => {
                          const newData = { ...d, label: (v as HTMLTextAreaElement).value };
                          onUpdate(newData);
                          return newData;
                        })
                      }
                      className={`file-label ${blockId ? 'input-' + blockId : ''}`}
                      disabled={disabled}
                      readOnly={readOnly}
                      placeholder="< Enter file name >"
                      variant={readOnly ? undefined : 'underline'}
                    />
                  </div>
                ) : isProvide ? null : (
                  <span className={`min-w-48 max-w-96 ${textClasses.join(' ')}`}>{label || fileName}</span>
                )}
                {!isProvide && (
                  <div className="w-full">
                    <AutoSizeTextArea
                      initialValue={notes ?? ''}
                      onChange={(v) =>
                        setData((d) => {
                          const newData = { ...d, notes: (v as HTMLTextAreaElement).value };
                          onUpdate(newData);
                          return newData;
                        })
                      }
                      className={`file-notes ${blockId ? 'input-' + blockId : ''}`}
                      disabled={disabled}
                      readOnly={readOnly}
                      placeholder="< Enter notes >"
                      variant={readOnly ? undefined : 'underline'}
                    />
                  </div>
                )}
              </div>
              <div className="w-1/4">
                <div className="flex gap-1 w-full items-center relative">
                  {(!isProvide || !readOnly) && (
                    <>
                      {!!fileId && fileIcon}
                      <div className="flex w-full items-center pr-2">
                        <span className={`!text-2xs max-h-[1.5rem] text-ellipsis line-clamp-2`}>{fileName ?? ''}</span>
                      </div>
                    </>
                  )}
                  {isEditable && (
                    <>
                      {!disabled && (
                        <>
                          {/* These aren't lining up with the other buttons */}
                          {mode === 'upload-list' && !fileId && (
                            <div>
                              <UploadInput
                                blockId={blockId}
                                disabled={disabled}
                                onSave={onSave}
                                onUploaded={handleUpdate}
                                uid={uid}
                                requestToken={requestToken}
                                uploadRef={uploadRef}
                              />
                            </div>
                          )}
                          {!!fileId && mode === 'upload-list' && (
                            <div>
                              <DeleteUploadButton
                                assetId={fileId}
                                confirmTitle="Are you sure you want to clear this file?"
                                icon={<XMarkIcon height={16} width={16} />}
                                onDeleteStart={() =>
                                  setData((prev) => {
                                    const newData: UploadItemData = { ...prev, fileId: null, fileName: '' };
                                    onUpdate(newData);
                                    return newData;
                                  })
                                }
                                onSave={onSave}
                                requestId={requestId}
                                requestToken={requestToken}
                                variant="gray"
                              />
                            </div>
                          )}
                          {(mode !== 'upload-list' || !!clientAdded || !requestToken) && (
                            <div className="flex items-center">
                              <DeleteUploadButton
                                assetId={fileId}
                                confirmTitle="Are you sure you want to delete this file?"
                                onDeleteStart={onRemove}
                                onSave={onSave}
                                requestId={requestId}
                                requestToken={requestToken}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {!!fileId && (!isProvide || isEditable) && downloadButton}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Draggable>
  );
};
