import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import {
  CreateInviteUserNestedContainer,
  ICreateInviteUserContainerProps
} from './create-invite-user-form-nested.container';
import { ICloseableDialogProps } from '../../../_core/core.types';

interface ICreateInviteUserContainer extends ICreateInviteUserContainerProps, ICloseableDialogProps {
  title?: string;
}

export const CreateInviteUserContainer = ({ title, ...props }: ICreateInviteUserContainer) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title={title ?? 'Create Contact'}
    >
      <CreateInviteUserNestedContainer
        onBack={props.close}
        onError={(e) => setSubmitError(e ?? null)}
        onSuccess={(s) => setSuccess(s ?? null)}
        {...props}
      />
    </FormContainer>
  );
};
