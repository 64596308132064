import {
  Completion,
  IRequestGroupOverview,
  IRequestType,
  IUserOverview,
  REQUEST_NOTIFY_OPTION,
  REQUEST_STATUS,
  requestStatusLabels
} from '../../../../lib/types';
import { PropsWithChildren, useMemo, useState } from 'react';

import { useFetchCurrUser } from '../../../domains/users/user.service';
import {
  ITableOptionsConfig,
  ITableRowClassConfig,
  ITableRowValues,
  TableFilterKeys
} from '../../../_core/table/table.types';
import { useTableFilterConfig, useTableSearchConfig } from '../../../_core/table/utils/table-utils';
import { TableDate } from '../../../_core/table/table-date.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { TableWithActions } from '../../../_core/table/table-with-actions.component';
import { RequestGroupStatusIcon } from './request-group-status-icon';
import { RequestGroupActionButton } from '../../../domains/request-group/request-group-action-button';
import { useDeliverRequestGroup, useRedeliverRequestGroup } from '../../../domains/request-group/request-group.service';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { SendRequestGroupMessageContainer } from '../../../domains/conversation/send-request-group-message/send-request-group-message.container';
import { sortMethodTuple, useTableSortConfig } from '../../../_core/table/utils/table-sort';
import { CompletionText } from '../../../_editor/_core/completion.component';
import { useResponsiveLimits } from '../../../../utils/page.helpers';

interface IRequestGroupTableProps
  extends PropsWithChildren,
    IAdminProps,
    IPersonalCompanyProps,
    ITableOptionsConfig<IRequestGroupOverview> {
  groups?: IRequestGroupOverview[];
}

export interface IRequestGroupTableRowValues extends ITableRowValues {
  requestGroup: IRequestGroupOverview;
}

const colSizes: (ITableRowClassConfig | null)[] = [
  { cell: 'w-20 text-center' },
  { cell: 'w-20 text-center' },
  null, // Allow title col to grow
  { cell: 'w-32' },
  { cell: 'w-16 text-center' },
  { cell: 'w-24 text-center' },
  { cell: 'w-24 text-center' },
  { cell: 'w-32' },
  { cell: 'w-24' },
  { cell: 'w-24' },
  { cell: 'w-24' },
  { cell: 'w-56' }
];

export const RequestGroupTable: React.FC<IRequestGroupTableProps> = ({ children, groups, loading, ...rest }) => {
  const { user } = useFetchCurrUser();

  const [groupToSend, setGroupToSend] = useState<IRequestGroupOverview | null>(null);
  const [isResend, setIsResend] = useState(false);

  const { deliverRequestGroup, loading: deliveringRequestGroup } = useDeliverRequestGroup();
  const { redeliverRequestGroup, loading: redeliveringRequestGroup } = useRedeliverRequestGroup();

  const [searchConfig, searchMatches] = useTableSearchConfig({
    items: groups,
    keys: ['name', 'requestType', 'deadline', 'sentAt', 'status', 'createdBy.name']
  });
  const [filterConfig, filterFunc] = useTableFilterConfig({
    filters: [
      {
        title: TableFilterKeys.STATUS,
        placeholder: 'Filter by status',
        options: [REQUEST_STATUS.DRAFT, REQUEST_STATUS.SENT, REQUEST_STATUS.CLOSED, REQUEST_STATUS.ARCHIVED],
        value: [REQUEST_STATUS.SENT, REQUEST_STATUS.DRAFT],
        matcher: (curr: IRequestGroupOverview, filterValue: string[]) => {
          if (!filterValue.length) return true;
          if (curr.deletedAt) return filterValue.includes(REQUEST_STATUS.ARCHIVED);
          return filterValue.includes(curr.status ?? REQUEST_STATUS.DRAFT);
        },
        labeler: (value) =>
          value in requestStatusLabels ? requestStatusLabels[value as REQUEST_STATUS].title : undefined
      }
    ]
  });

  const { sortFunc, ...restSort } = useTableSortConfig({
    sortMethods: {
      updatedAt: sortMethodTuple('date'),
      sentAt: sortMethodTuple('date'),
      name: sortMethodTuple(),
      requestType: sortMethodTuple('string', (r) => (r as IRequestType).type),
      completion: sortMethodTuple('default', (c) => (c as Completion).percentage),
      deadline: sortMethodTuple('date'),
      createdBy: sortMethodTuple('string', (c) => (c as IUserOverview).name ?? (c as IUserOverview).email)
    },
    defaultSortFunc: (a: IRequestGroupOverview, b) => {
      if (a.deletedAt && b.deletedAt) return a.deletedAt < b.deletedAt ? 1 : -1;
      if (a.deletedAt && !b.deletedAt) return 1;
      if (!a.deletedAt && b.deletedAt) return -1;
      return a.updatedAt < b.updatedAt ? 1 : -1;
    }
  });

  const groupRows = useMemo(() => {
    if (!searchMatches) return [];
    const sortable = [...searchMatches];
    return sortable
      .filter(filterFunc)
      .sort(sortFunc)
      .map((group, i) => {
        const row: IRequestGroupTableRowValues = {
          requestGroup: group,
          values: [
            {
              children: <TableDate centered date={group.updatedAt} showTime />
            },
            {
              children: group.sentAt
                ? new Date(group.sentAt).toLocaleDateString('en-CA', {
                    year: '2-digit',
                    day: 'numeric',
                    month: 'numeric'
                  })
                : ''
            },
            { children: group.name },
            { children: group.requestType?.type ?? '' },
            { children: group.canSelfServe ? 'On' : '' },
            { children: group.active },
            { children: group.closed },
            { children: <CompletionText completion={group.completion} /> },
            { children: group.deadline ? <TableDate centered date={group.deadline} /> : '' },
            {
              children: (
                <p
                  className={`text-xs overflow-hidden whitespace-nowrap text-ellipsis font-bold ${
                    user?._id === group.createdBy._id ? 'text-green-600' : ''
                  }`}
                >
                  {group.createdBy.name}
                </p>
              )
            },
            {
              children: <RequestGroupStatusIcon inline deletedAt={group.deletedAt} status={group.status} />
            },
            {
              children: (
                <RequestGroupActionButton
                  slim
                  loading={!!groupToSend || deliveringRequestGroup || redeliveringRequestGroup}
                  onResend={() => {
                    setIsResend(true);
                    setGroupToSend(group);
                  }}
                  onSend={() => {
                    setIsResend(false);
                    setGroupToSend(group);
                  }}
                  group={group}
                  last={searchMatches.length > 10 && i === searchMatches.length - 1}
                />
              )
            }
          ]
        };

        return row;
      });
  }, [searchMatches, filterFunc, sortFunc, user?._id, groupToSend, deliveringRequestGroup, redeliveringRequestGroup]);

  const { isMd, isMdLg, isLg, isXl, is2xl, is3xl } = useResponsiveLimits();

  const rowClasses = useMemo(() => {
    const sizes = [...colSizes];

    // Update owner col
    sizes[sizes.length - 3] = is2xl ? { cell: 'w-32' } : { cell: 'w-28' };

    // Update deadline col
    sizes[sizes.length - 4] = is2xl ? { cell: 'w-32' } : { cell: 'w-28' };
    if (is3xl) return sizes;

    // Hide sent at
    sizes[1] = { cell: 'hidden' };
    if (is2xl) return sizes;

    // Hide type
    sizes[4] = { cell: 'hidden' };
    if (isXl) return sizes;

    // Hide active and closed
    sizes[sizes.length - 6] = { cell: 'hidden' };
    sizes[sizes.length - 7] = { cell: 'hidden' };
    if (isLg) return sizes;

    // Hide deadline
    sizes[sizes.length - 4] = { cell: 'hidden' };
    if (isMdLg) return sizes;

    // Hide completion
    sizes[sizes.length - 5] = { cell: 'hidden' };

    // Hide owner
    sizes[sizes.length - 3] = { cell: 'hidden' };

    if (isMd) return sizes;

    // Hide updated at
    sizes[0] = { cell: 'hidden' };
    return sizes;
  }, [isMd, isLg, isMdLg, isXl, is2xl, is3xl]);

  return (
    <>
      <TableWithActions
        {...rest}
        {...restSort}
        loading={!groups || (!groups.length && loading)}
        headers={[
          { content: 'Last Update', sortKey: 'updatedAt' },
          { content: 'Sent At', sortKey: 'sentAt' },
          { content: 'Title', sortKey: 'name' },
          { content: 'Type', sortKey: 'requestType' },
          { content: 'Link', sortKey: 'canSelfServe' },
          { content: 'Active', sortKey: 'active' },
          { content: 'Completed', sortKey: 'closed' },
          { content: 'Completion', sortKey: 'completion' },
          { content: 'Deadline', sortKey: 'deadline' },
          { content: 'Owner', sortKey: 'createdBy' },
          { content: 'Status', sortKey: 'status' },
          { content: '' }
        ]}
        rowClasses={rowClasses}
        values={groupRows ?? []}
        filterConfig={filterConfig}
        searchConfig={searchConfig}
      >
        {children}
      </TableWithActions>
      {!!groupToSend && (
        <RIDialog open={!!groupToSend} setOpen={(o) => setGroupToSend((r) => (o ? r : null))}>
          <SendRequestGroupMessageContainer
            close={() => {
              setGroupToSend(null);
              setIsResend(false);
            }}
            group={groupToSend}
            forceSendAll={!isResend}
            onSubmit={({ message, notify = [REQUEST_NOTIFY_OPTION.ALL], ...rest }) =>
              isResend
                ? redeliverRequestGroup({ ...rest, _id: groupToSend._id, customMessage: message })
                : deliverRequestGroup({ ...rest, _id: groupToSend._id, customMessage: message, notify })
            }
          />
        </RIDialog>
      )}
    </>
  );
};
