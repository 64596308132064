import { LIST_REQUESTS_AS_STAFF } from './request.queries';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { IListRequestAsStaffResults, IListRequestAsStaffVariables } from './request.types';
import { DEFAULT_LIMIT } from './request.consts';

export const readRequestsCache = ({
  cache,
  limit = DEFAULT_LIMIT,
  page,
  requestGroup
}: IApolloCacheParams & IListRequestAsStaffVariables) =>
  cache.readQuery<IListRequestAsStaffResults>({
    query: LIST_REQUESTS_AS_STAFF,
    variables: { limit, page, requestGroup }
  })?.listRequestsAsStaff;

export const writeRequestsCache = ({
  cache,
  listRequestsAsStaff,
  limit = DEFAULT_LIMIT,
  page,
  requestGroup
}: IApolloCacheParams & IListRequestAsStaffResults & IListRequestAsStaffVariables) => {
  cache.writeQuery({
    query: LIST_REQUESTS_AS_STAFF,
    data: { listRequestsAsStaff },
    variables: { limit, page, requestGroup },
    overwrite: true
  });
};
