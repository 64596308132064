import { Form, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { IClientOverview, IMemberOverview, IRequestType } from '../../../../lib/types';
import { PropsWithChildren, useMemo } from 'react';
import { ICreateRequestGroupProps } from '../request-group.types';
import {
  useEditRequestFormClientFields,
  useEditRequestFormTemplateFields
} from '../../request/edit/edit-request-form.utils';

export interface IEditRequestGroupFormFieldProps {
  clients: IClientOverview[];
  clientsToIgnore?: string[];
  requestTypes: IRequestType[];
  staff: IMemberOverview[];
  hasBeenCreated?: boolean;
}

export const EditRequestGroupFormFields = ({
  clients,
  clientsToIgnore,
  requestTypes,
  staff,
  createdById,
  hasBeenCreated,

  // Formik props
  children,
  setFieldValue,
  values
}: IEditRequestGroupFormFieldProps &
  FormikProps<ICreateRequestGroupProps> &
  PropsWithChildren & { createdById: string }) => {
  const { AddNewClientDialog, MultiClientInput } = useEditRequestFormClientFields({
    clients,
    clientsMultiKey: 'assignedClients',
    clientsMultiLabel: 'Assigned Contacts',
    clientsToIgnore,
    setFieldValue,
    values
  });

  const { TemplateInput } = useEditRequestFormTemplateFields({ hasBeenCreated, inputKey: 'template' });

  const staticOptions = useMemo(
    () => ({
      requestTypes: requestTypes.filter((rt) => !rt.deletedAt).map((l) => ({ label: l.type, value: l._id })),
      staff: staff
        .filter((s) => !s.deletedAt && !s.user.deletedAt && s.user._id !== createdById)
        .map((d) => ({ label: d.name ?? d.user.name, secondaryLabel: d.user.email, value: d._id }))
    }),
    [createdById, requestTypes, staff]
  );

  return (
    <>
      <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
        <FormInputWithLabel autoFocus name="name" label="Bulk Request Name" placeholder="Bulk Request Name" />
        <MultiClientInput />
        <FormInputWithLabel
          name="assignedStaff"
          label="Share with Team Members"
          type="multiselect"
          placeholder="Share with Team Members"
          multiSelectProps={{ options: staticOptions.staff, nullable: true, selectAll: true }}
        />
        {/* TODO: Discuss default assigned with Kevin */}
        <FormInputWithLabel
          name="requestType"
          label="Request Type"
          type="multiselect"
          placeholder="Select request type"
          multiSelectProps={{ options: staticOptions.requestTypes, singleSelect: true, nullable: true }}
        />
        <FormInputWithLabel name="deadline" label="Deadline" placeholder="Enter deadline date" type="date" />
        <FormInputWithLabel name="notes" label="Notes" placeholder="Optional notes" />
        <FormInputWithLabel name="canSelfServe" label="Enable Self Serve Link" type="checkbox" />
        {!!values.canSelfServe && (
          <FormInputWithLabel name="expiration" label="Expiration" placeholder="Enter expiration date" type="date" />
        )}
        <TemplateInput />
        {children}
      </Form>
      <AddNewClientDialog />
    </>
  );
};
