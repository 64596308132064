import { Formik, FormikErrors, FormikProps } from 'formik';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IRequestGroupOverview, IUser, REQUEST_NOTIFY_OPTION } from '../../../../lib/types';
import {
  ISendCustomRequestGroupMessageFormParams,
  SendRequestGroupMessageFormFields
} from './send-request-group-message-form-fields';
import { isValidEmail } from '../../../../utils/validation.helpers';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';

interface ISendRequestMessageFormProps extends IRIFormProps<ISendCustomRequestGroupMessageFormParams> {
  clientMode: boolean;
  defaultMessage?: string;
  defaultNotify?: (REQUEST_NOTIFY_OPTION.CLIENTS | REQUEST_NOTIFY_OPTION.STAFF)[];
  forceSendAll?: boolean;
  group: IRequestGroupOverview;
  user?: IUser;
}

export const SendRequestGroupMessageForm = ({
  defaultMessage = '',
  forceSendAll,
  onBack,
  onSubmit,
  user
}: ISendRequestMessageFormProps) => {
  const validateForm = (values: ISendCustomRequestGroupMessageFormParams) => {
    const errors: FormikErrors<ISendCustomRequestGroupMessageFormParams> = {};
    if (values.replyTo && !isValidEmail(values.replyTo)) errors.replyTo = 'Must be valid email';

    return errors;
  };

  return (
    <Formik
      initialValues={
        {
          message: defaultMessage,
          replyTo: user?.email ?? '',
          subject: forceSendAll
            ? 'You have been sent a new request'
            : "You have received a message on one of your Request's",
          assignSelf: false
        } as ISendCustomRequestGroupMessageFormParams
      }
      onSubmit={onSubmit}
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ISendCustomRequestGroupMessageFormParams>) => (
        <SendRequestGroupMessageFormFields forceSendAll={forceSendAll}>
          <FormActionsRow>
            {!!onBack && <FormButton type="button" onPress={onBack} text="Cancel" variant="secondary" />}
            <FormButton loading={props.isSubmitting} disabled={props.isSubmitting} text="Send Bulk Request" />
          </FormActionsRow>
        </SendRequestGroupMessageFormFields>
      )}
    </Formik>
  );
};
