import { forwardRef } from 'react';

export type TagSize = 'small' | 'default';

interface ITagProps {
  className?: string;
  onRemove: () => void;
  size?: TagSize;
  tag: string;
  tagClass?: string;
}

export const Tag = forwardRef<HTMLDivElement, ITagProps>(
  ({ className, onRemove, size, tag, tagClass }: ITagProps, ref) => {
    const isSmall = size === 'small';
    return (
      <div
        ref={ref}
        className={`bg-indigo-100 inline-flex items-center rounded mr-1 ${isSmall ? 'text-xs' : 'text-sm mt-2'} ${
          className ?? ''
        }`}
      >
        {/* NOTE: No need for a typography usecase here yet, because text is so basic and custom and could be controlled with tailwind font customizations */}
        <span className={`leading-relaxed truncate max-w-xs ${tagClass ?? ''} ${isSmall ? 'ml-1' : 'ml-2 mr-1'}`}>
          {tag}
        </span>
        <button
          className={`inline-block align-middle text-gray-500 hover:text-gray-600 focus:outline-none ${
            isSmall ? 'w-4 h-4' : 'w-6 h-8'
          }`}
          onClick={() => onRemove()}
          type="button"
        >
          <svg
            className={`fill-current mx-auto ${isSmall ? 'w-4 h-4' : 'w-6 h-6'}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
            />
          </svg>
        </button>
      </div>
    );
  }
);
