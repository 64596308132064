import { useSearchParams } from 'react-router-dom';
import { IAuthorizedRequestPageWrapper } from './request-page-auth-wrapper.types';
import ErrorPage from '../../InfoPages/error.page';
import { TokenAccessRequestPageContainer } from './request-page-auth-wrapper.token.container';

export const TokenAccessRequestPageWrapper = ({ requestId }: IAuthorizedRequestPageWrapper) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  if (!token) return <ErrorPage>Missing access token</ErrorPage>;
  return <TokenAccessRequestPageContainer requestId={requestId} token={token} />;
};
