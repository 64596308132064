import { RequestTableNestedContainer } from './request-table.nested.container';
import { useRequests } from '../../../domains/request/request.service';
import { IRequestTableContainer } from './types';
import { useRequestGroups } from '../../../domains/request-group/request-group.service';
import { Button } from '../../../_core/button/button.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { EditRequestContainer } from '../../../domains/request/edit/edit-request.container';
import { useState } from 'react';

export const RequestTableContainer: React.FC<IRequestTableContainer> = (props) => {
  const { requests, loading: requestsLoading } = useRequests();
  const { requestGroups, loading: groupsLoading } = useRequestGroups({ includeArchived: true });

  const [createRequest, setCreateRequest] = useState(false);

  return (
    <>
      <RequestTableNestedContainer
        requests={requests}
        requestsLoading={requestsLoading}
        requestGroups={requestGroups}
        groupsLoading={groupsLoading}
        {...props}
      >
        <Button onClick={() => setCreateRequest(true)} text="Create Request" size="large" className="mt-1" />
      </RequestTableNestedContainer>
      <RIDialog open={createRequest} setOpen={setCreateRequest}>
        <EditRequestContainer />
      </RIDialog>
    </>
  );
};
