import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EditorJs, { OutputData } from '@editorjs/editorjs';

import { IRequest, ITemplate, REQUEST_STATUS, TEMPLATE_TYPE } from '../../lib/types';
import { FormEditor } from './FormBuilderPage/form-editor.component';
import { RIDialog } from '../_core/dialog/dialog.component';
import { convertRemToPixels, sleep, usePrev } from '../../lib/utils';
import { useExitPrompt } from '../_core/utils/browser';
import { PageActionWrapper } from './page-action-wrapper.component';
import { useSaveEditor } from '../_editor/_core/utils/editor.utils';
import {
  CreateTemplateContainer,
  ICreateTemplateContainerProps
} from '../domains/template/create/create-template-form.container';
import { ICreateBlockTemplateParams } from '../_editor/_core/create-template-block';
import { useConfirm } from '../_core/confirm/confirm.utils';
import { DownloadRequestAssetsButtonContainer } from '../domains/request/download-request-assets-button';
import { Button } from '../_core/button/button.component';
import { DocumentDuplicateIcon, ExclamationCircleIcon, TrashIcon } from '@heroicons/react/20/solid';
import { EditorSaveResult, SaveProps } from '../_editor/_core/types';
import { windowMaxWidth } from '../_layouts/consts';
import { Loader } from '../_core/loader.component';
import { EditorSectionNav } from './editor-section-nav.component';
import { AutoSizeTextArea } from '../_editor/_core/autosizetextarea.component';
import { ILabelBlockData } from '../_editor/_core/label-block';
import { ConfirmButton } from '../_core/confirm/confirm-button.component';
import { buildDefaultSection } from '../_editor/_core/editor.const';
import { FormEditorType } from './FormBuilderPage/form-editor.types';

interface IEditorPage {
  ActionButtons: (_: {
    afterClear: () => void;
    confirmClear: (__?: string) => Promise<boolean>;
    hasChanged: boolean;
    isReady: boolean;
    onSave: (_?: SaveProps) => Promise<EditorSaveResult>;
    onTogglePreview: (___: boolean) => void;
  }) => React.ReactElement;
  TitleActions?: () => JSX.Element;
  TitleComponent: JSX.Element;
  afterRefreshData?: (() => void) | null;
  canQuickCreate?: boolean;
  canSave: boolean;
  data: OutputData;
  disabled?: boolean;
  defaultEditorType?: FormEditorType;
  isClosed?: boolean;
  isSent?: boolean;
  menuOpen?: boolean;
  onBack: () => void;
  onEdit: () => void;
  openFirstSection?: boolean;
  saverRef?: MutableRefObject<{ onSave: (_?: SaveProps) => Promise<EditorSaveResult> } | undefined>;
  readOnly?: boolean;
  request?: IRequest;
  template?: ITemplate;
  title: string;
  token?: string;
}

export const EditorPage = ({
  afterRefreshData,
  canQuickCreate,
  canSave,
  data,
  defaultEditorType,
  isClosed,
  menuOpen,
  saverRef,
  readOnly,
  request,
  template,
  token,
  ActionButtons,
  TitleActions,
  TitleComponent,
  ...rest
}: IEditorPage) => {
  const start = Date.now();
  const [searchParams, setSearchParams] = useSearchParams();

  const editorRef = useRef<EditorJs>();

  const [canRefreshData, setCanRefreshData] = useState(false);
  const [createTemplate, setCreateTemplate] = useState<ICreateTemplateContainerProps | null>(null);
  const [editorDestroyed, setEditorDestroyed] = useState(false);
  const [mutableData, setMutableData] = useState(structuredClone(data));

  useEffect(() => setMutableData(structuredClone(data)), [data]);
  useEffect(() => setCanRefreshData((prev) => prev || !!afterRefreshData), [afterRefreshData]);

  const determineIfPreviewing = ({ sp, ro, sent }: { sp: URLSearchParams; ro?: boolean; sent?: boolean }) =>
    ro ?? sp.has('mode') ? sp.get('mode') === 'preview' : !!sent;

  const [previewing, setPreviewing] = useState(
    determineIfPreviewing({ sp: searchParams, ro: readOnly, sent: rest.isSent })
  );

  useEffect(
    () => setPreviewing(determineIfPreviewing({ sp: searchParams, ro: readOnly, sent: rest.isSent })),
    [readOnly, rest.isSent, searchParams]
  );

  const {
    hasChanged,
    onSave,
    updateOrder,
    saving,
    isReady,
    setIsReady,
    editorFailedToReady,
    onFail,
    togglingState,
    setTogglingState,
    activeSection,
    setActiveSection,
    showAllSections,
    setShowAllSections
  } = useSaveEditor({
    canSave,
    forceNonSectionEditor: !canQuickCreate,
    mutableData,
    setCanRefreshData,
    setMutableData,
    ref: editorRef,
    request,
    template,
    token
  });

  useMemo(() => {
    if (saverRef) saverRef.current = { onSave };
  }, [saverRef, onSave]);

  const handleCanRefreshUpdate = useCallback(
    (canStillRefresh: boolean) => {
      setCanRefreshData(canStillRefresh);
      if (!canStillRefresh) afterRefreshData?.();
    },
    [afterRefreshData]
  );

  const scrollBlockIntoView = useCallback((id: string, retries = 0) => {
    if (retries > 10) {
      console.log('Abandon attempt to scroll block into view');
      return;
    }

    const scrollToBlock = document.querySelector(`div[data-id="${id}"]`);
    if (scrollToBlock) scrollToBlock.scrollIntoView({ behavior: 'instant' });
    else sleep(100).then(() => scrollBlockIntoView(id, retries + 1));
  }, []);

  const onTogglePreview = useCallback(
    (state?: boolean) => {
      const updatePreviewState = async () => {
        try {
          setIsReady(false);
          setTogglingState(true);

          const newIsPreview = state ?? !previewing;
          setSearchParams((prev) => {
            prev.set('mode', newIsPreview ? 'preview' : 'edit');
            return prev;
          });
          setPreviewing(newIsPreview);

          await onSave({ forceFetchLatestData: true });

          if (!newIsPreview) {
            // TODO: Refreshing the page is a short-term workaround, ideally we find a way for prod editors to be re-rendered succesfully on external data changes without failing in prod

            window.location.reload();
          } else {
            await editorRef.current?.readOnly?.toggle(state);
            setIsReady(true);
            setTogglingState(false);

            if (firstBlockInView?.dataset.id) {
              await sleep(100);
              scrollBlockIntoView(firstBlockInView.dataset.id ?? '');
            }
          }
        } catch (err) {
          console.error('Failed to toggle preview due to error', err);
        }
      };

      // console.log('Toggle preview', state);
      // Top of section area based on sticky header height and typical size of section header
      const topOfSectionArea = convertRemToPixels(4.75) + convertRemToPixels(2.5);

      const blocks = document.querySelectorAll('.ce-block');
      const firstBlockInView = [...blocks.entries()].find((b) => {
        const e = b[1] as HTMLElement;
        const bounds = e.getBoundingClientRect();
        return bounds.y > topOfSectionArea;
      })?.[1] as HTMLElement;

      updatePreviewState();
    },
    [onSave, previewing, scrollBlockIntoView, setIsReady, setSearchParams, setTogglingState]
  );

  const prevRequestStatus = usePrev(request?.status);

  useEffect(() => {
    if (request?.status && isReady && editorRef.current)
      if (prevRequestStatus === REQUEST_STATUS.CLOSED && !isClosed)
        // Toggle edit mode when re-opening request
        onTogglePreview(false);
      // Toggle ready only on closed
      else if (prevRequestStatus !== REQUEST_STATUS.CLOSED && isClosed && !previewing) onTogglePreview(true);
  }, [editorRef, isClosed, isReady, onTogglePreview, prevRequestStatus, previewing, request?.status]);

  const onCreateBlockTemplate = ({ data, type = TEMPLATE_TYPE.SECTION }: ICreateBlockTemplateParams) => {
    setCreateTemplate({ data: { blocks: [{ data: data.data, type: data.type }] }, type, version: EditorJs.version });
  };

  const { ConfirmationDialog: ConfirmClearDialog, confirm: confirmClear } = useConfirm({
    title: 'Are you sure you want to clear template?'
  });

  useExitPrompt({ isDirty: hasChanged, onCancel: () => onSave() });

  const afterClear = useCallback(() => {
    setCanRefreshData(true);
    onTogglePreview(false);

    // TODO: Refreshing the page is a short-term workaround, ideally we find a way for prod editors to be re-rendered succesfully on external data changes without failing in prod
    window.location.reload();
  }, [onTogglePreview]);

  const showTOC = useMemo(
    () =>
      canQuickCreate &&
      (!!request || template?.type === TEMPLATE_TYPE.REQUEST || template?.type === TEMPLATE_TYPE.BULK),
    [canQuickCreate, request, template?.type]
  );

  const allowedActiveSection = useMemo(
    () => (showTOC && !showAllSections && mutableData.blocks.length > activeSection ? activeSection : null),
    [activeSection, mutableData.blocks.length, showAllSections, showTOC]
  );

  if (showTOC && mutableData.blocks.length <= activeSection) return <Loader />;
  return (
    <>
      <PageActionWrapper
        onBack={rest.onBack}
        onEdit={rest.onEdit}
        readOnly={readOnly}
        title={TitleComponent}
        actionButtons={
          <div className="relative flex h-7 gap-4">
            {editorFailedToReady ? (
              <div className="flex inline-block items-center text-center mr-4">
                <span className="ml-2 text-xs text-error">Failed to load editor. Please contact support</span>
              </div>
            ) : (
              !isReady && (
                <div className="flex inline-block items-center text-center mr-4">
                  <Loader />
                  <span className="ml-2 text-xs">Preparing editor...</span>
                </div>
              )
            )}
            <ActionButtons
              afterClear={afterClear}
              confirmClear={confirmClear}
              hasChanged={hasChanged}
              isReady={isReady}
              onSave={onSave}
              onTogglePreview={onTogglePreview}
            />
            {(!rest.isSent || !token) && (
              <div className="inline-block">
                <Button
                  slim
                  disabled={!isReady || saving}
                  loading={togglingState}
                  onClick={() => onTogglePreview()}
                  size="medium"
                  text={previewing ? 'Edit' : 'Preview'}
                  variant="secondary"
                />
              </div>
            )}
            {canSave && (
              <div className="inline-block">
                <Button
                  slim
                  disabled={!hasChanged || !isReady}
                  loading={saving}
                  onClick={async () => onSave()}
                  size="medium"
                  text="Save"
                  icon={
                    hasChanged ? (
                      <ExclamationCircleIcon width={16} height={16} color="gold" style={{ marginRight: 2 }} />
                    ) : undefined
                  }
                />
              </div>
            )}
            <DownloadRequestAssetsButtonContainer
              ignoreSpacing
              disabled={!isReady}
              request={request}
              requestToken={token}
              template={template}
              onSave={onSave}
              forceLabelsOnDocProvide={previewing}
            />
          </div>
        }
      >
        <div
          id="request-content"
          className={'mx-auto w-full p-4 pt-1 flex flex-1 flex-col m-6 bg-white rounded-2xl ' + windowMaxWidth}
        >
          <div className="shadow-customShort mx-auto mb-1 px-2 py-1 w-full relative flex items-center justify-center rounded-t relative bg-white sticky top-[4.75rem] z-50">
            <h2 className="text-center text-xl text-secondary font-bold">{rest.title}</h2>
            {!!TitleActions && (
              <div className="absolute right-0 mr-2">
                <TitleActions />
              </div>
            )}
          </div>
          {/* TODO: Fix overflow-x here, so whole editor can scroll */}
          <div className="shadow-custom mx-auto w-full rounded-b flex">
            {showTOC && (
              <EditorSectionNav
                disabled={saving || !isReady}
                data={mutableData}
                onUpdateOrder={updateOrder}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                showAllSections={showAllSections}
                setShowAllSections={setShowAllSections}
                setTogglingState={setTogglingState}
                readOnly={!!readOnly || previewing}
              />
            )}
            <div className={`w-full ${showTOC ? 'border-l' : ''}`}>
              {/* TODO: Does each bit of data need to be memoized to prevent the Editor re-renders? */}
              {/* TODO: Should force re-render on company switch */}
              {showTOC && !showAllSections && (
                <div className="p-3 flex gap-4 items-center">
                  {readOnly || previewing ? (
                    <span className="text-lg">
                      {(mutableData.blocks[activeSection].data as ILabelBlockData).label ?? ''}
                    </span>
                  ) : (
                    <>
                      <div className="flex items-center gap-1">
                        <ConfirmButton
                          hideEndMargin
                          className="!p-1"
                          icon={<TrashIcon height={12} width={12} />}
                          size="fit"
                          buttonTitle=""
                          onConfirm={() => {
                            setMutableData((prev) => {
                              const newData = { ...prev };
                              if (newData.blocks.length === 1) newData.blocks = [buildDefaultSection()];
                              else newData.blocks.splice(activeSection, 1);

                              return newData;
                            });
                            setActiveSection(Math.max(0, activeSection - 1));
                            setCanRefreshData(true);
                          }}
                          title="Are you sure you want to delete this section?"
                          tooltip="Delete Section"
                        />
                        <Button
                          hideEndMargin
                          className="!p-1"
                          icon={<DocumentDuplicateIcon height={12} width={12} />}
                          onClick={() =>
                            onCreateBlockTemplate({
                              data: mutableData.blocks[activeSection],
                              type: TEMPLATE_TYPE.SECTION
                            })
                          }
                          size="fit"
                          tooltip="Create Template"
                          variant="secondary"
                        />
                      </div>
                      <AutoSizeTextArea
                        allowInitialValueUpdate
                        initialValue={(mutableData.blocks[activeSection].data as ILabelBlockData).label ?? ''}
                        onChange={(e) =>
                          (mutableData.blocks[activeSection].data.label = (e as HTMLTextAreaElement).value)
                        }
                      />
                    </>
                  )}
                </div>
              )}
              {togglingState && <Loader />}
              <FormEditor
                activeSection={allowedActiveSection}
                canQuickCreate={canQuickCreate}
                canRefreshData={canRefreshData}
                canShowEditor={!menuOpen}
                data={mutableData}
                // TODO: Disable until ready, however this will cause the editor to disappear in production on switch
                disabled={rest.disabled}
                editorblock={'editorjs'}
                editorDestroyed={editorDestroyed}
                editorRef={editorRef}
                hidden={togglingState}
                onCreateTemplate={onCreateBlockTemplate}
                onFail={onFail}
                onReady={() => {
                  editorRef.current?.isReady
                    .then(() => {
                      console.log('editorjs - Editor is ready.', {
                        secondsToReady: (Date.now() - start) / 1000,
                        eRef: editorRef.current
                      });
                      setIsReady(true);
                      setEditorDestroyed(false);
                      setTogglingState(false);
                    })
                    .catch(onFail);
                }}
                onSave={onSave}
                openFirstSection={rest.openFirstSection}
                previewing={previewing}
                type={
                  defaultEditorType && !canQuickCreate
                    ? defaultEditorType
                    : !showTOC || showAllSections
                    ? 'base'
                    : 'section'
                }
                readOnly={!!readOnly}
                requestClosed={request?.status === REQUEST_STATUS.CLOSED || request?.status === REQUEST_STATUS.LOCKED}
                requestId={request?._id}
                requestSent={!!request?.sentAt}
                setCanRefreshData={handleCanRefreshUpdate}
                setEditorDestroyed={setEditorDestroyed}
                setIsReady={setIsReady}
                token={token}
              />
            </div>
          </div>
        </div>
      </PageActionWrapper>
      {!!createTemplate && (
        <RIDialog setOpen={(o) => setCreateTemplate((v) => (o ? v : null))}>
          <CreateTemplateContainer close={() => setCreateTemplate(null)} {...createTemplate} />
        </RIDialog>
      )}
      <ConfirmClearDialog />
    </>
  );
};
