import { AcceptInviteForm, IAcceptInviteFormValues } from './accept-invite-form.component';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useAcceptInvite, useInvite } from '../invite.service';
import { FetchInviteErrors } from '../invite.types';
import { InviteType } from '../get-by-code/get-invite-by-code-form.types';
import { useAcceptClientInvite } from '../../client-invite/client-invite.service';
import { useFetchCurrUser } from '../../users/user.service';
import { ROUTE_PATHS } from '../../../../_routes';
import { useLogout } from '../../auth/auth.service';
import { useCallback, useMemo } from 'react';
import { INVITE_STATUS } from '../../../../lib/types';
import { useAuth0WithRedirect } from '../../../_auth/auth.utils';
import { Loader } from '../../../_core/loader.component';

interface IAcceptInviteContainer extends IFormWithStepperProps {
  code?: string;
  type?: InviteType;
}

export const AcceptInviteNestedContainer = ({ code, type, onBack, onError, onSuccess }: IAcceptInviteContainer) => {
  const { acceptInvite } = useAcceptInvite();
  const { acceptClientInvite } = useAcceptClientInvite();

  const { loginWithRedirect, user: authUser } = useAuth0WithRedirect();
  const { handleLogout } = useLogout();
  const { invite, loading: loadingInvite, called: calledInvite } = useInvite(code ?? '');
  const { user } = useFetchCurrUser();

  const loginAndAccept = useCallback(async () => {
    return loginWithRedirect({ appState: { returnTo: ROUTE_PATHS.JOIN + '/' + code }, email: invite?.email });
  }, [code, invite?.email, loginWithRedirect]);

  const isWrongUser = useMemo(() => !!invite && !!authUser && invite.email !== authUser?.email, [invite, authUser]);

  const handleAccept = useCallback(
    ({ code: selectedCode, type: selectedType }: IAcceptInviteFormValues) => {
      const performAccept = async () => {
        try {
          await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

          if (!authUser) await loginAndAccept();
          else if (isWrongUser) {
            await handleLogout({
              onFinish: () => {
                return loginAndAccept();
              }
            });
          } else {
            if (selectedType === InviteType.CLIENT) await acceptClientInvite({ code: selectedCode });
            else if (selectedType === InviteType.STAFF)
              await acceptInvite({ code: selectedCode, email: authUser?.email, name: authUser?.name });
            else {
              onError?.('Invalid invite type.');
              return;
            }

            onSuccess?.('Successfully accepted invite.');
          }
        } catch (err) {
          const errorMessage = (err as Error).message;

          let msg = 'Failed to accept invite.';
          if (errorMessage === FetchInviteErrors.COMPANY_DELETED) {
            msg = 'Organization has been deleted.';
          } else if (errorMessage === FetchInviteErrors.DOES_NOT_EXIST) {
            msg = 'Invite does not exist.';
          } else if (errorMessage === FetchInviteErrors.EXPIRED) {
            msg = 'Invite expired.';
          }

          logError(msg, errorMessage);
          onError?.(msg);
        }
      };

      return performAccept();
    },
    [acceptClientInvite, acceptInvite, authUser, handleLogout, isWrongUser, loginAndAccept, onError, onSuccess]
  );

  if (invite?.status !== INVITE_STATUS.PENDING || invite?.isExpired)
    return (
      <div className="flex flex-1 justify-center text-center">
        {invite ? (
          <h2 className="mb-4 text-error">
            Invite is no longer valid.
            <br />
            Please request a new invite.
          </h2>
        ) : calledInvite && !loadingInvite ? (
          <h2 className="mb-4 text-error">Invite does not exist</h2>
        ) : (
          <Loader />
        )}
      </div>
    );

  return (
    <AcceptInviteForm
      code={code}
      invite={invite}
      isWrongUser={isWrongUser}
      type={type}
      user={user}
      onSubmit={handleAccept}
      onBack={onBack}
    />
  );
};
