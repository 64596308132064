import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import { RequestTableNestedContainer } from '../DashboardPage/request-table/request-table.nested.container';
import { PageActionWrapper } from '../page-action-wrapper.component';
import {
  useDeliverRequestGroup,
  useRedeliverRequestGroup,
  useRequestGroup,
  useRequestGroups
} from '../../domains/request-group/request-group.service';
import { RequestGroupActionButton } from '../../domains/request-group/request-group-action-button';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { EditRequestGroupContainer } from '../../domains/request-group/edit/edit-request-group.container';
import { useMemo, useState } from 'react';
import { SendRequestGroupMessageContainer } from '../../domains/conversation/send-request-group-message/send-request-group-message.container';
import { REQUEST_NOTIFY_OPTION, REQUEST_STATUS } from '../../../lib/types';
import { Loader } from '../../_core/loader.component';
import { Button } from '../../_core/button/button.component';
import { windowMaxWidth } from '../../_layouts/consts';

export const BulkRequestPageContainer = () => {
  const navigate = useNavigate();

  const params = useParams();
  const requestGroupId = params.groupId ?? '';

  const { requestGroups, loading: loadingGroups } = useRequestGroups();
  const { requestGroup, refetch, loading: loadingGroup, called } = useRequestGroup({ _id: requestGroupId });

  const { deliverRequestGroup, loading: deliveringRequestGroup } = useDeliverRequestGroup();
  const { redeliverRequestGroup, loading: redeliveringRequestGroup } = useRedeliverRequestGroup();

  const [editing, setEditing] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [sending, setSending] = useState(false);

  const title = useMemo(
    () =>
      requestGroup?.name ? (
        <h2 className="text-center text-xl text-black font-semibold">
          <span className="text-slate-800">Bulk Request:</span> {requestGroup.name}
        </h2>
      ) : (
        <Loader />
      ),
    [requestGroup?.name]
  );

  const requestItems = useMemo(() => {
    if (!requestGroup?.requests) return undefined;

    const finalRequests = [...requestGroup.requests];

    // Add undelivered clients as request line items
    requestGroup.assignedClients.forEach((c) => {
      finalRequests.push({
        _id: '',
        assignedTo: c,
        createdBy: requestGroup.createdBy,
        deadline: requestGroup.deadline,
        name: requestGroup.name,
        requestGroup: requestGroup,
        requestGroupOverview: requestGroup,
        requestType: requestGroup.requestType,
        staffEditorIds: [],
        staffViewerIds: [],
        status: REQUEST_STATUS.UNDELIVERED,
        updatedAt: new Date()
      });
    });

    return finalRequests;
  }, [requestGroup]);

  if (
    !requestGroupId ||
    (!requestGroup && !loadingGroup && called) ||
    (!requestGroups?.length && !loadingGroups) ||
    (requestGroups && !loadingGroups && !requestGroups.find((g) => g._id === requestGroupId))
  )
    return <Navigate to={ROUTE_PATHS.DASHBOARD} />;

  return (
    <>
      <PageActionWrapper
        onBack={() => navigate(ROUTE_PATHS.DASHBOARD + '?mode=bulk')}
        onEdit={requestGroup?.editable ? () => setEditing(true) : undefined}
        status={requestGroup?.status}
        title={title}
        actionButtons={
          requestGroup?.editable ? (
            <RequestGroupActionButton
              group={requestGroup}
              hideViewAction
              slim
              onResend={() => {
                setIsResend(true);
                setSending(true);
              }}
              onSend={() => {
                setIsResend(false);
                setSending(true);
              }}
              loading={deliveringRequestGroup || redeliveringRequestGroup}
            />
          ) : undefined
        }
      >
        <div className="mx-auto w-full flex min-h-full flex-1 flex-col items-center bg-white">
          <div className={'mx-auto w-full ' + windowMaxWidth}>
            <RequestTableNestedContainer
              favoritable
              hideModeToggle
              showAllStatuses
              requests={requestItems}
              requestsLoading={loadingGroup}
            >
              {!!requestGroup?.template && requestGroup.editable && (
                <Button
                  onClick={() =>
                    navigate(ROUTE_PATHS.TEMPLATE + '/' + requestGroup.template?._id, {
                      state: { backPath: window.location.pathname + window.location.search }
                    })
                  }
                  text="Edit Template"
                  size="large"
                  className="mt-1"
                />
              )}
            </RequestTableNestedContainer>
          </div>
        </div>
      </PageActionWrapper>
      {!!requestGroup?.editable && (
        <>
          <RIDialog open={editing} setOpen={setEditing}>
            <EditRequestGroupContainer group={requestGroup} close={() => setEditing(false)} />
          </RIDialog>
          <RIDialog open={sending} setOpen={setSending}>
            <SendRequestGroupMessageContainer
              close={() => {
                setSending(false);
                setIsResend(false);
              }}
              group={requestGroup}
              forceSendAll={!isResend}
              onSubmit={({ message, notify = [REQUEST_NOTIFY_OPTION.ALL], ...rest }) =>
                isResend
                  ? redeliverRequestGroup({ ...rest, _id: requestGroup._id, customMessage: message })
                  : deliverRequestGroup({ ...rest, _id: requestGroup._id, customMessage: message, notify }).then(() =>
                      refetch()
                    )
              }
            />
          </RIDialog>
        </>
      )}
    </>
  );
};
