import { EditInviteForm } from './edit-invite-form.component';
import { IInviteOverview, Nameable } from '../../../../lib/types';
import { useUpdateInvite } from '../invite.service';
import { logError, sleep } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { ICloseableDialogProps } from '../../../_core/core.types';

export interface IEditInviteContainerProps {
  invite: IInviteOverview;
}

interface IEditInviteNestedContainerProps
  extends IEditInviteContainerProps,
    IFormWithStepperProps,
    ICloseableDialogProps {}

export const EditInviteNestedContainer = ({
  close,
  invite,
  onError,
  onSuccess,
  ...rest
}: IEditInviteNestedContainerProps) => {
  const { updateInvite } = useUpdateInvite(invite);

  const handleSubmit = async (values: Nameable) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      await updateInvite(values);

      onSuccess?.('Successfully edited invite.');
      sleep(250).then(close);
    } catch (err) {
      const errMsg = 'Unable to edit invite.';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }
  };

  return <EditInviteForm invite={invite} onSubmit={handleSubmit} {...rest} />;
};
