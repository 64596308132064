import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { uniqueId } from 'lodash';

import { UploadFilesContainerHandle, UploadItemData, UploadMode } from './upload.types';
import { Button } from '../../_core/button/button.component';
import { EditorSaveResult } from '../_core/types';
import { UploadFile } from './upload-file';
import { logError } from '../../../lib/utils';
import { IAsset } from '../../../lib/types';
import { UploadInput } from './upload-input';
import { EditorApolloProvider } from '../_core/editor-apollo-provider';
import { useDrop } from '../../_core/drag-and-drop/droppable.utils';

interface IUploadFiles {
  blockId?: string;
  uploads: UploadItemData[];
  disabled?: boolean;
  inlineUploadId: string;
  mode: UploadMode;
  onSave: () => Promise<EditorSaveResult>;
  onUpdate: (_: UploadItemData[]) => void;
  readOnly: boolean;
  requestId?: string;
  requestToken?: string;
  uid: string;
}

const UploadFiles = forwardRef<UploadFilesContainerHandle, IUploadFiles>(
  ({ inlineUploadId, onUpdate, uploads, ...rest }, ref) => {
    const quickCreateRef = useRef<HTMLInputElement | null>(null);

    const [data, setData] = useState(uploads);
    useDrop({ dragItemKey: 'fileContainerId', setData, onUpdate });

    const createFileContainerId = () => new Date().getTime() + ' ' + uniqueId();

    const handleFileUpdate = useCallback(
      (a: IAsset, originalFileData?: UploadItemData): UploadItemData => ({
        ...originalFileData,
        fileContainerId: originalFileData?.fileContainerId ?? createFileContainerId(),
        fileName: a.name,
        fileId: a._id,
        label: originalFileData?.label ?? a.name,

        // It's considered added by the client if attached during a session with a request token, and file is entirely new
        clientAdded: originalFileData?.clientAdded ?? (!!rest.requestToken && !originalFileData)
      }),
      [rest.requestToken]
    );

    const onAddFile = useCallback(
      (asset: IAsset) => {
        const newFile = handleFileUpdate(asset);
        setData((prev) => {
          const newData = [...prev, newFile];
          onUpdate(newData);
          return newData;
        });
      },
      [handleFileUpdate, onUpdate]
    );

    // Create callback to alow updating nested depth for local title from parent updated callback
    const [nestedDepth, setNestedDepth] = useState(0);
    useImperativeHandle(ref, () => ({ addFile: onAddFile, updateNestedDepth: (depth) => setNestedDepth(depth + 1) }));

    const onRemove = (fileContainerId: string) => {
      setData((prev) => {
        const newData = [...prev.filter((d) => d.fileContainerId !== fileContainerId)];
        onUpdate(newData);
        return newData;
      });
    };

    const handleUpdate = ({ fileContainerId, ...updateData }: UploadItemData) => {
      setData((prev) => {
        const fileIndex = data.findIndex((u) => u.fileContainerId === fileContainerId);
        if (fileIndex !== -1) {
          const newFile = { ...data[fileIndex], ...updateData };

          const newData = [...data];
          newData[fileIndex] = newFile;

          onUpdate(newData);
          return newData;
        } else logError('Failed to update upload');

        return prev;
      });
    };

    const filesContainerHeaderId = 'files-container-header-' + rest.uid;

    const quickCreateButton = useMemo(() => {
      if (rest.mode !== 'provide' || !rest.readOnly) {
        const shouldUploadFilesImmediately = rest.readOnly || rest.mode !== 'upload-list';

        const button = (
          <div
            id={inlineUploadId}
            className={`flex flex-col ${rest.mode !== 'upload-list' && !data.length ? 'hidden' : ''} ${
              !data.length && 'mt-2'
            }`}
          >
            <div className="ml-8">
              <Button
                icon={
                  <PlusIcon
                    height={20}
                    width={20}
                    className="my-auto rounded-md bg-green-400 hover:bg-green-300 text-white mr-auto"
                  />
                }
                className="ce-toolbar__button !p-0 !text-green-400 hover:bg-opacity-60"
                onClick={() => {
                  if (shouldUploadFilesImmediately) quickCreateRef.current?.click();
                  else
                    setData((prev) => {
                      const newData: UploadItemData[] = [
                        ...prev,
                        { fileContainerId: createFileContainerId(), fileName: '', clientAdded: !!rest.requestToken }
                      ];
                      onUpdate(newData);
                      return newData;
                    });
                }}
                variant="custom"
              />
            </div>
          </div>
        );

        if (shouldUploadFilesImmediately)
          return (
            <UploadInput {...rest} multiple onUploaded={onAddFile} uploadRef={quickCreateRef}>
              {button}
            </UploadInput>
          );
        return button;
      }
    }, [rest, inlineUploadId, data.length, onAddFile, onUpdate]);

    return (
      <>
        {!!data.length && (
          <>
            {(rest.mode !== 'provide' || !rest.readOnly) && (
              <div id={filesContainerHeaderId} className="flex gap-4 ml-8">
                <div className="flex w-8/12 gap-4">
                  <span className="w-full text-center text-xs font-bold">Document name</span>
                  <span className="w-full text-center text-xs font-bold">{rest.mode !== 'provide' && 'Notes'}</span>
                </div>
              </div>
            )}
            {data.map((u, uIndex) => (
              <UploadFile
                key={u.fileContainerId}
                {...rest}
                data={u}
                index={uIndex}
                onFileUpdate={handleFileUpdate}
                onRemove={() => onRemove(u.fileContainerId)}
                onUpdate={handleUpdate}
                nestedDepth={nestedDepth}
              />
            ))}
          </>
        )}
        {quickCreateButton}
      </>
    );
  }
);

export const UploadFilesContainer = forwardRef<UploadFilesContainerHandle, IUploadFiles>((props, ref) => {
  return (
    <EditorApolloProvider>
      <UploadFiles ref={ref} {...props} />
    </EditorApolloProvider>
  );
});
