import { useState } from 'react';
// Utils
import { IRegisterCompanyProps } from '../company.types';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { logError } from '../../../../lib/utils';

// Services
import { useCurrCompany, useRegisterCompany, useUpdateCompany } from '../company.service';
import { useFetchCurrUser, useUpdateUser } from '../../users/user.service';

// Components
import { RegisterCompanyForm } from './register-company-form.component';

interface IRegisterCompanyContainer extends IFormWithStepperProps {
  forceNew?: boolean;
}

export const RegisterCompanyContainer = ({ forceNew, onSuccess, ...rest }: IRegisterCompanyContainer) => {
  const { registerCompany } = useRegisterCompany();
  const { updateCompany } = useUpdateCompany();
  const { updateUser } = useUpdateUser();
  const { company } = useCurrCompany();
  const { user } = useFetchCurrUser();

  const [error, setError] = useState<string | null>(null);

  const handleRegister = async (props: IRegisterCompanyProps) => {
    try {
      if (!forceNew && company) {
        await updateCompany({ name: props.name });
      } else {
        await registerCompany({ name: props.name, planName: props.planName });
      }

      await updateUser({ name: props.fullName, email: props.email, phone: props.phone });

      if (onSuccess) onSuccess();
    } catch (err) {
      logError('Unable to create organization.', (err as Error).message);
      setError('An unexpected error occurred. Please try again later');
    }
  };

  return (
    <RegisterCompanyForm
      company={forceNew ? undefined : company}
      user={user}
      onSubmit={handleRegister}
      error={error}
      {...rest}
    />
  );
};
