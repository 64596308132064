import { IGetTemplatesParams, ITemplateResults } from './template.types';
import { GET_TEMPLATES } from './template.queries';
import { ITemplateBaseDetails, TEMPLATE_TYPE } from '../../../lib/types';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { mergeCacheLists } from '../../../lib/utils';

const allTypes = [TEMPLATE_TYPE.BLOCK, TEMPLATE_TYPE.REQUEST, TEMPLATE_TYPE.SECTION];

const templateTypesCaches = (type: TEMPLATE_TYPE): TEMPLATE_TYPE[][] => {
  if (type === TEMPLATE_TYPE.REQUEST) return [[type], allTypes];
  return [[type], [TEMPLATE_TYPE.BLOCK, TEMPLATE_TYPE.SECTION], allTypes];
};

export const updateTemplateInCache = ({
  cache,
  template,
  type
}: IApolloCacheParams & { template: ITemplateBaseDetails; type: TEMPLATE_TYPE }) => {
  for (const types of templateTypesCaches(type)) {
    const currCompanyTemplates = cache.readQuery<ITemplateResults, IGetTemplatesParams>({
      query: GET_TEMPLATES,
      variables: { includeArchived: types.length < allTypes.length ? false : undefined, types }
    })?.templates;

    cache.writeQuery<ITemplateResults, IGetTemplatesParams>({
      query: GET_TEMPLATES,
      data: { templates: mergeCacheLists(currCompanyTemplates ?? [], [template]) },
      variables: { includeArchived: types.length < allTypes.length ? false : undefined, types },
      overwrite: true
    });
  }
};
