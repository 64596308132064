import { useEffect } from 'react';
import { Loader } from '../../_core/loader.component';
import ErrorPage from '../InfoPages/error.page';
import { ROUTE_PATHS } from '../../../_routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useTemplate } from '../../domains/template/template.service';
import { TemplatePage } from './template-page.component';

export const TemplatePageContainer = () => {
  const params = useParams();
  const { template, loading, error } = useTemplate(params.templateId ?? '');

  const navigate = useNavigate();

  useEffect(() => {
    if ((!template || error) && !loading) navigate(ROUTE_PATHS.DASHBOARD);
  }, [loading, navigate, template, error]);

  if (!template)
    if (loading) return <Loader />;
    else return <ErrorPage>{error?.message ?? 'Template not found'}</ErrorPage>;

  return <TemplatePage canSave={!template.deletedAt} template={template} />;
};
