import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { createRoot } from 'react-dom/client';
import { QuestionType } from './question.types';
import { QuestionTypeSelect } from './question-type-select';
import { BlockTitles, BlockType, inputErrorClasses } from '../_core/editor.const';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { localTitleBlockExport, localTitleBlockImport } from '../_core/utils/block.utils';
import { ILabelBlockData } from '../_core/label-block';
import _ from 'lodash';
import { createAnswerInput, validateUrl } from '../_core/utils/question.utils';

export interface IQuestionData extends ILabelBlockData {
  isValid: boolean;
  value: string;
  type: QuestionType;
}

export class Question extends LocalTitleBlockTool<IQuestionData, ICreateTemplateBlockConfig> {
  private error = '';

  private answerId: string;
  private errorId: string;
  private typeSelectId: string;

  constructor(props: BlockToolConstructorOptions<IQuestionData>) {
    const { data, ...rest } = props;
    const newData = {
      ...data,
      isValid: data.isValid ?? true,
      type: data.type ?? QuestionType.TEXT,
      value: _.unescape(data.value)
    };

    super({ ...rest, data: newData });

    this.answerId = 'answer-' + this.uid;
    this.errorId = 'error-' + this.uid;
    this.typeSelectId = 'type-select-' + this.uid;
  }

  static get sanitize() {
    // disallow HTML
    return { ...super.sanitize, value: false };
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.Question],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" /></svg>'
    };
  }

  render() {
    super.render();
    this.wrapper?.classList.add('flex', 'gap-4');

    const answerContainer = this.renderAnswerInput();

    // Add required UX components, if element is required
    const indicator = this.renderRequiredIndicator();
    if (indicator) answerContainer.appendChild(indicator);

    const completedCheckbox = this.renderCompletedCheckbox();
    if (completedCheckbox) answerContainer.appendChild(completedCheckbox);

    this.wrapper?.appendChild(answerContainer);

    const errorContent = document.createElement('div');
    const errorMessage = document.createElement('span');
    errorMessage.id = this.errorId;
    errorMessage.classList.add('my-1.5', 'text-red-500', 'text-sm', 'hidden');
    errorContent.appendChild(errorMessage);

    // Render content with settings and local title content prepened
    const container = document.createElement('div');
    container.appendChild(this.renderWithLocalTitle());
    container.appendChild(errorContent);

    return renderWithBlockFocusWrapper(container);
  }

  private phoneFormat(input: string) {
    let result = '';
    let size = input.length;

    if (size > 10 && input.charAt(0) === '1') {
      result += '+1 ';
      input = input.slice(1);
      size -= 1;
    }

    if (size > 0) result += '(' + input.slice(0, Math.min(size, 3));
    if (size > 3) result += ') ' + input.slice(3, Math.min(size, 6));
    if (size > 6) result += '-' + input.slice(6, Math.min(size, 10));

    return result;
  }

  private parseNumberInput = (value: string, regex = /\D/g) => {
    const v = value.trim().replace(regex, '');
    return v;
  };

  private formatNumber = (orig: string, ignoreTrailingPeriod?: boolean) => {
    if (!orig || (orig.length === 1 && orig.charAt(0) === '-')) return { newValue: orig, isNegative: false };
    const parsed = this.parseNumberInput(orig, /[^0-9.]/g);
    const newValue =
      Intl.NumberFormat('en-CA').format(parseFloat(parsed)) +
      (!ignoreTrailingPeriod && parsed.endsWith('.') ? '.' : '');
    const isNegative = orig.startsWith('-') || (orig.startsWith('(') && orig.endsWith(')'));

    return { newValue, isNegative };
  };

  private onAnswerChange(target: EventTarget | null) {
    const targetE = target as HTMLInputElement | HTMLTextAreaElement;

    let refocusPosition = 0;
    const isNumAnswer = [QuestionType.CURRENCY, QuestionType.NUMBER].includes(this.data.type);
    const shouldRefocus = isNumAnswer;

    if (isNumAnswer) {
      const oldNumberIsNegative = this.formatNumber(this.data.value).isNegative;
      const { newValue, isNegative } = this.formatNumber(targetE.value);

      // TODO: This still requires some work,
      // Refocus the input based on formatting
      if (oldNumberIsNegative === isNegative)
        if (this.data.value.length < newValue.length + (isNegative ? 1 : 0)) refocusPosition = 1;
        else if (this.data.value.length > newValue.length + (isNegative ? 1 : 0)) refocusPosition = -1;

      if (isNegative) this.data.value = `(${newValue})`;
      else this.data.value = newValue;
    } else if (this.data.type === QuestionType.PHONE) {
      this.data.value = this.phoneFormat(this.parseNumberInput(targetE.value));
      // TODO: Add phone input refocusing on formatting change
    } else this.data.value = targetE.value;

    const unformattedValue = this.data.value.replace(/,/g, '');
    if (targetE.value !== unformattedValue) {
      // Refocus input
      const originalFocusPosition = targetE.selectionStart;
      targetE.value = unformattedValue;

      if (shouldRefocus)
        if (refocusPosition) {
          // Adjust focus by offset indicated by formatting updates
          const newFocusPosition = Math.max(
            0,
            Math.min(
              this.data.value.length,
              originalFocusPosition !== null ? originalFocusPosition + refocusPosition : unformattedValue.length
            )
          );

          targetE.selectionStart = newFocusPosition;
          targetE.selectionEnd = newFocusPosition;
        } else if (originalFocusPosition !== null) {
          // Default to origin focus position when value has changed, so the focus doesn't automatically revert to the end of the line
          targetE.selectionStart = originalFocusPosition;
          targetE.selectionEnd = originalFocusPosition;
        }
    }

    this.data.filled = !!this.data.value;
    this.validate(this.data);
    this.toggleRequiredIndicator({ checked: this.data.filled });
  }

  private renderAnswerInput() {
    const selectElement = (
      <QuestionTypeSelect
        type={this.data.type}
        onChange={(selected) => {
          this.data.type = selected;
          this.wrapper?.replaceChild(this.renderAnswerInput(), this.wrapper.querySelector('.answer-container') as Node);
          this.validate(this.data);
        }}
      />
    );
    const readOnly = this.api.readOnly.isEnabled || this.config.readOnly;
    const answerContainer = createAnswerInput({
      blockId: this.block?.id,
      disabled: this.config.disabled,
      onAnswerChange: (t) => this.onAnswerChange(t),
      type: this.data.type,
      id: this.answerId,
      readOnly: readOnly,
      value: this.data.value,
      addOnEdit: {
        onInsertToDOM: (ac) => {
          const prefix = document.createElement('div');
          prefix.id = this.typeSelectId;

          const root = createRoot(prefix);
          root.render(selectElement);

          ac.prepend(prefix);
        },
        element: selectElement
      },
      variant: 'box'
    });

    return answerContainer;
  }

  async save(blockContent: HTMLDivElement): Promise<IQuestionData> {
    const answer = blockContent.querySelector('.answer-input') as HTMLInputElement | HTMLTextAreaElement | undefined;

    // Prep base block data
    const newData = await super.save(blockContent);
    if (!answer) return newData;

    let finalValue: string;
    if ([QuestionType.CURRENCY, QuestionType.NUMBER].includes(this.data.type)) {
      const { newValue, isNegative } = this.formatNumber(answer.value, true);
      finalValue = (isNegative ? '-' : '') + newValue;
    } else finalValue = answer.value;

    return {
      ...newData,
      filled: !!answer.value,
      value: finalValue
    };
  }

  // TODO: Need to determine this on first render so we can determine if we need to show the error message after new validation is configured on client
  private toggleError() {
    const answerInput = document.getElementById(this.answerId) as HTMLInputElement | HTMLTextAreaElement | null;
    const errorMessage = document.getElementById(this.errorId) as HTMLSpanElement | null;
    if (!this.data.isValid && this.error) {
      if (errorMessage) {
        errorMessage.textContent = this.error;
        errorMessage.classList.remove('hidden');
      }
      answerInput?.classList.add(...inputErrorClasses);
    } else {
      const errorMessage = document.getElementById(this.errorId);
      errorMessage?.classList.add('hidden');
      answerInput?.classList.remove(...inputErrorClasses);
    }
  }

  validate({ type, value, ...rest }: IQuestionData) {
    if (!super.validate(rest)) return false;
    let result = false;

    if (type) {
      if (!value) result = true;

      if (!result)
        switch (type) {
          case QuestionType.PHONE:
            result = !!value.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
            if (!result) this.error = 'Invalid phone number';
            break;
          case QuestionType.NUMBER:
          case QuestionType.CURRENCY: {
            const { newValue } = this.formatNumber(value);
            result = !!newValue.match(/^-?\d+(.\d*)*$/);
            if (!result) this.error = 'Invalid number';
            break;
          }
          case QuestionType.EMAIL:
            result = !!value.match(
              /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
            );
            if (!result) this.error = 'Invalid email';
            break;
          case QuestionType.DATE:
            result = !!value.match(/^\d{4}-\d{2}-\d{2}$/);
            if (!result) this.error = 'Invalid date';
            break;
          case QuestionType.URL: {
            const validation = validateUrl(value);
            result = validation.result;
            if (!result && validation.error) this.error = validation.error;
            break;
          }
          case QuestionType.TEXT:
          case QuestionType.TEXTAREA:
            // No validation required
            break;
          default:
            // Unknown type, this should never happen so assume invalid
            result = false;
            this.error = 'Unknown question type';
            break;
        }

      this.data.isValid = result;
      this.toggleError();
      return true;
    }

    return false;
  }

  static get conversionConfig() {
    return { export: localTitleBlockExport, import: localTitleBlockImport };
  }
}
