import { InternalRefetchQueriesInclude } from '@apollo/client';
import { GET_CURR_COMPANY, GET_USER_COMPANIES } from './company.queries';
import { GET_COMPANY_DEPARTMENTS } from '../departments/department.queries';
import { GET_COMPANY_INVITES } from '../invite/invite.queries';
import { GET_COMPANY_LOCATIONS } from '../locations/location.queries';
import { GET_COMPANY_REQUEST_TYPES, GET_SELECTED_REQUEST_TYPES } from '../request-type/request-type.queries';
import { GET_MEMBERS } from '../member/member.queries';
import { INVITE_STATUS, TEMPLATE_TYPE } from '../../../lib/types';
import { GET_ENTITIES, GET_ENTITY_OVERVIEWS } from '../entities/entity.queries';
import { GET_CLIENT_OVERVIEWS, GET_CLIENTS } from '../clients/client.queries';
import { GET_CLIENT_INVITES } from '../client-invite/client-invite.queries';
import { LIST_REQUESTS_AS_STAFF } from '../request/request.queries';
import { GET_TEMPLATES } from '../template/template.queries';
import { GET_REQUEST_GROUPS } from '../request-group/request-group.queries';
import { IArchivableProps } from '../../../lib/query.types';
import { IListRequestAsStaffVariables } from '../request/request.types';
import { DEFAULT_LIMIT } from '../request/request.consts';

export const CURR_COMPANY_REFETCH_QUERIES: InternalRefetchQueriesInclude = [
  { query: GET_CURR_COMPANY },
  { query: GET_COMPANY_DEPARTMENTS, variables: { includeArchived: true } as IArchivableProps },
  { query: GET_COMPANY_INVITES, variables: { status: INVITE_STATUS.PENDING } },
  { query: GET_COMPANY_LOCATIONS, variables: { includeArchived: true } as IArchivableProps },
  { query: GET_COMPANY_REQUEST_TYPES },
  { query: GET_SELECTED_REQUEST_TYPES },
  { query: GET_MEMBERS },
  { query: GET_ENTITIES, variables: { includeArchived: true } as IArchivableProps },
  { query: GET_ENTITY_OVERVIEWS, variables: { includeArchived: true } as IArchivableProps },
  { query: GET_CLIENTS, variables: { includeArchived: true } as IArchivableProps },
  { query: GET_CLIENT_OVERVIEWS, variables: { includeArchived: true } as IArchivableProps },
  { query: GET_CLIENT_INVITES, variables: { status: INVITE_STATUS.PENDING } },
  { query: LIST_REQUESTS_AS_STAFF, variables: { limit: DEFAULT_LIMIT } as IListRequestAsStaffVariables },
  { query: GET_TEMPLATES, variables: { includeArchived: false, types: [TEMPLATE_TYPE.REQUEST] } },
  { query: GET_TEMPLATES, variables: { includeArchived: false, types: [TEMPLATE_TYPE.BLOCK, TEMPLATE_TYPE.SECTION] } },
  { query: GET_TEMPLATES, variables: { types: [TEMPLATE_TYPE.BLOCK, TEMPLATE_TYPE.REQUEST, TEMPLATE_TYPE.SECTION] } },
  { query: GET_REQUEST_GROUPS, variables: { includeArchived: true } }
];

export const USER_COMPANIES_REFETCH_QUERIES: InternalRefetchQueriesInclude = [
  ...CURR_COMPANY_REFETCH_QUERIES,
  { query: GET_USER_COMPANIES, variables: { includeArchived: true } }
];
