import { useCallback } from 'react';
import { FetchResult, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  IBlockPackageUpdateResponse,
  ITemplate,
  ITemplateBaseDetails,
  Identifiable,
  TEMPLATE_TYPE
} from '../../../lib/types';
import {
  CLEAR_GLOBAL_TEMPLATE,
  CLEAR_TEMPLATE,
  CREATE_GLOBAL_TEMPLATE,
  CREATE_TEMPLATE,
  DELETE_GLOBAL_TEMPLATE,
  DELETE_TEMPLATE,
  GET_TEMPLATE,
  GET_TEMPLATES,
  RESTORE_TEMPLATE,
  UPDATE_GLOBAL_TEMPLATE,
  UPDATE_TEMPLATE
} from './template.queries';
import { ICreateTemplateProps, IGetTemplatesParams, ITemplateResults, IUpdateTemplateProps } from './template.types';
import { updateTemplateInCache } from './template.utils';
import toast from 'react-hot-toast';
import { showError } from '../../../lib/utils';
import { activeCompanyLoadingVar } from '../companies/company.service';

export const useLazyTemplate = () => {
  const [getTemplate, { data, ...rest }] = useLazyQuery<{ template: ITemplate }, Identifiable>(GET_TEMPLATE, {
    fetchPolicy: 'cache-and-network'
  });

  return { getTemplate, ...rest, ...data };
};

export const useTemplate = (_id: string) => {
  const { data, ...rest } = useQuery<{ template: ITemplate }, Identifiable>(GET_TEMPLATE, {
    fetchPolicy: 'cache-and-network',
    variables: { _id }
  });

  return { ...rest, ...data };
};

export const useTemplates = ({ includeArchived, types }: { includeArchived?: boolean; types: TEMPLATE_TYPE[] }) => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<ITemplateResults, IGetTemplatesParams>(GET_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: { includeArchived, types }
  });

  return { ...rest, ...data, loading: rest.loading || activeCompanyLoading };
};

export const useCreateTemplate = (type: TEMPLATE_TYPE) => {
  const [mutation, rest] = useMutation<{ createTemplate: ITemplateBaseDetails }>(CREATE_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateTemplateInCache({ cache, template: data.createTemplate, type });
    }
  });

  const [globalMutation, globalRest] = useMutation<{ createGlobalTemplate: ITemplateBaseDetails }>(
    CREATE_GLOBAL_TEMPLATE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data) updateTemplateInCache({ cache, template: data.createGlobalTemplate, type });
      }
    }
  );

  const createTemplate = useCallback(
    (
      variables: ICreateTemplateProps
    ): Promise<
      FetchResult<
        | {
            createTemplate?: ITemplateBaseDetails;
            createGlobalTemplate?: ITemplateBaseDetails;
          }
        | null
        | undefined
      >
    > => {
      if (variables.isGlobal) return globalMutation({ variables: { ...variables, type } });
      else return mutation({ variables: { ...variables, type } });
    },
    [globalMutation, mutation, type]
  );

  return {
    createTemplate,
    loading: rest.loading || globalRest.loading
  };
};

export const useDeleteTemplate = () => {
  const [mutation, rest] = useMutation<{ deleteTemplate: ITemplateBaseDetails }, ITemplateBaseDetails>(
    DELETE_TEMPLATE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }, { variables }) => {
        if (data && variables) updateTemplateInCache({ cache, template: data.deleteTemplate, type: variables.type });
      }
    }
  );

  const [globalMutation, globalRest] = useMutation<
    { deleteGlobalTemplate: ITemplateBaseDetails },
    ITemplateBaseDetails
  >(DELETE_GLOBAL_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }, { variables }) => {
      if (data && variables)
        updateTemplateInCache({ cache, template: data.deleteGlobalTemplate, type: variables.type });
    }
  });

  const deleteTemplate = useCallback(
    (
      variables: ITemplateBaseDetails
    ): Promise<
      FetchResult<
        | {
            deleteTemplate?: ITemplateBaseDetails;
            deleteGlobalTemplate?: ITemplateBaseDetails;
          }
        | null
        | undefined
      >
    > => {
      if (!variables.company) return globalMutation({ variables });
      else return mutation({ variables });
    },
    [globalMutation, mutation]
  );

  return { deleteTemplate, loading: rest.loading || globalRest.loading };
};

export const useRestoreTemplate = () => {
  const [mutation, rest] = useMutation<{ restoreTemplate: ITemplateBaseDetails }, ITemplateBaseDetails>(
    RESTORE_TEMPLATE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }, { variables }) => {
        if (data && variables) updateTemplateInCache({ cache, template: data.restoreTemplate, type: variables.type });
      }
    }
  );

  const restoreTemplate = useCallback((variables: ITemplateBaseDetails) => mutation({ variables }), [mutation]);

  return { restoreTemplate, ...rest };
};

export const useUpdateTemplate = ({
  _id,
  lastUpdatedAt,
  type
}: { lastUpdatedAt?: Date; type: TEMPLATE_TYPE } & Identifiable) => {
  const [mutation, rest] = useMutation<{ updateTemplate: IBlockPackageUpdateResponse }>(UPDATE_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateTemplate.template) updateTemplateInCache({ cache, template: data.updateTemplate.template, type });
    }
  });

  const [globalMutation, globalRest] = useMutation<{ updateGlobalTemplate: IBlockPackageUpdateResponse }>(
    UPDATE_GLOBAL_TEMPLATE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data?.updateGlobalTemplate.template)
          updateTemplateInCache({ cache, template: data.updateGlobalTemplate.template, type });
      }
    }
  );

  const updateTemplate = useCallback(
    async (
      variables: IUpdateTemplateProps,
      currTemplate?: ITemplate
    ): Promise<IBlockPackageUpdateResponse | undefined> => {
      // Use global mutation if mutation should be global, or was previously global and is being set as private
      if (variables.isGlobal || (variables.isGlobal === false && currTemplate && !currTemplate.company))
        return (await globalMutation({ variables: { ...variables, lastUpdatedAt, type, _id } })).data
          ?.updateGlobalTemplate;
      else return (await mutation({ variables: { ...variables, lastUpdatedAt, type, _id } })).data?.updateTemplate;
    },
    [_id, globalMutation, lastUpdatedAt, mutation, type]
  );

  return { updateTemplate, loading: rest.loading || globalRest.loading };
};

export const useClearTemplate = ({ _id, company, type }: ITemplateBaseDetails, afterClear?: () => void) => {
  const [mutation, rest] = useMutation<{ clearTemplate: ITemplate }>(CLEAR_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        updateTemplateInCache({ cache, template: data.clearTemplate, type });
        afterClear?.();
      }
    },
    onCompleted: () => toast.success('Template cleared successfully!'),
    onError: (error) => showError('Failed to clear template', error)
  });

  const [globalMutation, globalRest] = useMutation<{ clearGlobalTemplate: ITemplate }>(CLEAR_GLOBAL_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        updateTemplateInCache({ cache, template: data.clearGlobalTemplate, type });
        afterClear?.();
      }
    },
    onCompleted: () => toast.success('Template cleared successfully!'),
    onError: (error) => showError('Failed to clear template', error)
  });

  const clearTemplate = useCallback((): Promise<
    FetchResult<{ clearTemplate?: ITemplate; clearGlobalTemplate?: ITemplate } | null | undefined>
  > => {
    // Use global mutation if mutation should be global, or was previously global and is being set as private
    if (company) return mutation({ variables: { _id } });
    else return globalMutation({ variables: { _id } });
  }, [_id, company, globalMutation, mutation]);

  return { clearTemplate, loading: rest.loading || globalRest.loading };
};
