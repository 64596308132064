import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import { IClientInvite, IClientInviteSendResult, INVITE_STATUS, Identifiable } from '../../../lib/types';
import { IClientInviteQueryParams, IClientInviteResult, IClientInvitesResult } from './client-invite.types';
import { IUpdateCompanyPermissionsVariantParams } from '../../../lib/query.types';
import { wipeCurrCompanyCache } from '../companies/company.utils';
import { mergeCacheLists } from '../../../lib/utils';
import { USER_COMPANIES_REFETCH_QUERIES } from '../companies/company.const';
import { readClientInvitesCache, writeClientInvitesCache } from './client-invite.utils';
import {
  ACCEPT_CLIENT_INVITE,
  GET_CLIENT_INVITE,
  GET_CLIENT_INVITES,
  REMOVE_CLIENT_INVITE,
  RESEND_CLIENT_INVITE,
  SEND_CLIENT_INVITE,
  UPDATE_CLIENT_INVITE
} from './client-invite.queries';
import { IInviteQueryParams } from '../invite/invite.types';
import { updateClientCache } from '../clients/client.utils';
import { updateEntityMemberCache } from '../entity-members/entity-members.utils';
import { activeCompanyLoadingVar } from '../companies/company.service';

export const useAcceptClientInvite = () => {
  const [mutation, rest] = useMutation<{ acceptClientInvite: IClientInvite }, IInviteQueryParams>(
    ACCEPT_CLIENT_INVITE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache) => wipeCurrCompanyCache(cache),
      awaitRefetchQueries: true,
      refetchQueries: USER_COMPANIES_REFETCH_QUERIES
    }
  );

  const acceptClientInvite = useCallback(
    (variables: IInviteQueryParams) => {
      activeCompanyLoadingVar(true);
      mutation({ variables }).finally(() => activeCompanyLoadingVar(false));
    },
    [mutation]
  );

  return { acceptClientInvite, ...rest };
};

export const useClientInvites = (status: INVITE_STATUS = INVITE_STATUS.PENDING) => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);
  const { data, ...rest } = useQuery<IClientInvitesResult, IClientInviteQueryParams>(GET_CLIENT_INVITES, {
    fetchPolicy: 'cache-and-network',
    variables: { status }
  });

  return { ...rest, ...data, loading: rest.loading || activeCompanyLoading };
};

export const useLazyClientInvite = () => {
  const [query, { data, ...rest }] = useLazyQuery<IClientInviteResult, IInviteQueryParams>(GET_CLIENT_INVITE, {
    fetchPolicy: 'cache-and-network'
  });

  return { getClientInvite: query, ...data, ...rest };
};

export const useRemoveClientInvite = () => {
  const [mutation, rest] = useMutation<{ removeClientInvite: IClientInvite }, Identifiable>(REMOVE_CLIENT_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const clientInvites = readClientInvitesCache({ cache });

        if (clientInvites)
          writeClientInvitesCache({
            cache,
            clientInvites: clientInvites?.filter((v) => v._id !== data.removeClientInvite._id)
          });
      }
    }
  });

  const removeClientInvite = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { removeClientInvite, ...rest };
};

export const useResendClientInvite = () => {
  const [mutation, rest] = useMutation<{ resendClientInvite: IClientInvite }, Identifiable>(RESEND_CLIENT_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        const currInvites = readClientInvitesCache({ cache });
        if (currInvites)
          writeClientInvitesCache({ cache, clientInvites: mergeCacheLists(currInvites, [data.resendClientInvite]) });
      }
    }
  });

  const resendClientInvite = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { resendClientInvite, ...rest };
};

export const useSendClientInvite = () => {
  const [mutation, rest] = useMutation<{ sendClientInvite: IClientInviteSendResult }>(SEND_CLIENT_INVITE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }, { variables }) => {
      if (data) {
        updateClientCache({ cache, clients: [data.sendClientInvite.client] });
        if (variables?.entityId && data.sendClientInvite.entityMember)
          updateEntityMemberCache({
            cache,
            entityMembers: [data.sendClientInvite.entityMember],
            variables: { entityId: variables.entityId }
          });
      }
    }
  });

  const sendClientInvite = useCallback(
    (variables: { department?: string[]; email: string; entityId?: string; location?: string[]; name?: string }) =>
      mutation({ variables }),
    [mutation]
  );

  return { sendClientInvite, ...rest };
};

export const useUpdateClientInvite = () => {
  const [mutation, rest] = useMutation<{ updateClientInvite: IClientInvite }, IUpdateCompanyPermissionsVariantParams>(
    UPDATE_CLIENT_INVITE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data) {
          const currInvites = readClientInvitesCache({ cache });
          if (currInvites)
            writeClientInvitesCache({ cache, clientInvites: mergeCacheLists(currInvites, [data.updateClientInvite]) });
        }
      }
    }
  );

  const updateClientInvite = useCallback(
    (variables: IUpdateCompanyPermissionsVariantParams) => mutation({ variables }),
    [mutation]
  );

  return { updateClientInvite, ...rest };
};
