import _ from 'lodash';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import toast from 'react-hot-toast';

// Queries
import { DELETE_USER, GET_CURR_USER, REGISTER_USER, SET_ACTIVE_COMPANY, UPDATE_USER } from './user.queries';
import { useCallback } from 'react';
import { IUser } from '../../../lib/types';
import { useLogout } from '../auth/auth.service';
import { ICurrUserResults, ISetActiveCompanyProps, IUpdateUserParams } from './user.types';
import { logError } from '../../../lib/utils';
import { wipeCurrCompanyCache } from '../companies/company.utils';
import { CURR_COMPANY_REFETCH_QUERIES, USER_COMPANIES_REFETCH_QUERIES } from '../companies/company.const';
import { activeCompanyLoadingVar } from '../companies/company.service';

export const useFetchCurrUser = () => {
  const { data, ...rest } = useQuery<ICurrUserResults>(GET_CURR_USER, {
    fetchPolicy: 'cache-and-network'
  });

  const user = _.get(data, 'loggedInUser');
  return { ...rest, user, isGlobalAdmin: user?.isAdmin };
};

export const useRegisterUser = ({
  authId,
  joiningExistingCompany
}: {
  authId?: string;
  joiningExistingCompany?: boolean;
}) => {
  const [mutation, rest] = useMutation<{ register: IUser }>(REGISTER_USER, {
    context: { serializationKey: 'MUTATION', tracked: true, skip: !authId },
    update: (cache, { data }) => {
      // Update logged in user query with newly created user
      if (data?.register)
        cache.writeQuery({ query: GET_CURR_USER, data: { loggedInUser: data.register }, overwrite: true });
    },
    refetchQueries: USER_COMPANIES_REFETCH_QUERIES,
    // Only await refetch queries if we know that user will be guaranteed to have existing company.
    // Otherwise the refetch queries will fail and the user register will be considered a failure on the client side.
    awaitRefetchQueries: !!joiningExistingCompany
  });

  const registerUser = useCallback(
    (inviteCodeId: string) => {
      activeCompanyLoadingVar(true);
      return mutation({ variables: { authId, inviteCodeId, createPersonalCompany: false } }).finally(() =>
        activeCompanyLoadingVar(false)
      );
    },
    [authId, mutation]
  );

  return { registerUser, ...rest };
};

export const useDeleteUser = () => {
  const { handleLogout, loading } = useLogout();
  const [mutation, rest] = useMutation(DELETE_USER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    onCompleted: () => handleLogout(),
    onError: (err) => {
      const errMsg = 'Unable to delete user';
      logError(errMsg, (err as Error).message);
      toast.error(errMsg);
    }
  });

  return { deleteUser: mutation, ...rest, loading: rest.loading || loading };
};

export const useSetActiveCompany = () => {
  const activeCompanyLoading = useReactiveVar(activeCompanyLoadingVar);

  const [mutation, rest] = useMutation<ISetActiveCompanyProps>(SET_ACTIVE_COMPANY, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      // Update curr user
      if (data?.setActiveCompany) {
        wipeCurrCompanyCache(cache);
        cache.writeQuery({ query: GET_CURR_USER, data: { loggedInUser: data.setActiveCompany }, overwrite: true });
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: CURR_COMPANY_REFETCH_QUERIES
  });

  const setActiveCompany = useCallback(
    async (companyId: string) => {
      activeCompanyLoadingVar(true);
      return mutation({ variables: { companyId } }).finally(() => activeCompanyLoadingVar(false));
    },
    [mutation]
  );

  return { ...rest, setActiveCompany, loading: activeCompanyLoading };
};

export const useUpdateUser = () => {
  const [mutation, rest] = useMutation<{ updateUser: IUser }, IUpdateUserParams>(UPDATE_USER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateUser)
        cache.writeQuery({ query: GET_CURR_USER, data: { loggedInUser: data.updateUser }, overwrite: true });
    }
  });

  const updateUser = useCallback((variables: IUpdateUserParams) => mutation({ variables }), [mutation]);

  return { updateUser, ...rest };
};
