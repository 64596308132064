import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ISendClientInvitesFormParams } from '../invite.types';
import { FormInfo } from '../../../_core/form/form-info.component';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { IClientOverview } from '../../../../lib/types';
import { useState } from 'react';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { CreateInviteUserContainer } from '../create-invite-user/create-invite-user-form.container';

interface ISendClientInvitesFormProps extends IRIFormProps<ISendClientInvitesFormParams> {
  clients: IClientOverview[];
  hasEntity: boolean; // Default entity, if present then don't allow changing of selected entity
  onSuccess?: (_?: string | null) => void;
}

export const SendClientInvitesForm = ({
  clients,
  hasEntity,
  onBack,
  onSkip,
  onSubmit,
  onSuccess
}: ISendClientInvitesFormProps) => {
  const [newContactData, setNewContactData] = useState('');

  const validateForm = (values: ISendClientInvitesFormParams) => {
    const errors: FormikErrors<ISendClientInvitesFormParams> = {};
    if (!values.invites.length) errors.invites = 'At least one invite is required';
    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{ invites: [] } as ISendClientInvitesFormParams}
        onSubmit={onSubmit}
        validate={validateForm}
        validateOnBlur={false}
      >
        {({ values, isSubmitting, setFieldValue }: FormikProps<ISendClientInvitesFormParams>) => (
          <>
            <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
              {!hasEntity && (
                <FormInfo
                  title={'Add Contacts:'}
                  messages={[
                    'Add some contacts for your organization below.',
                    'You can also access the contacts list in your settings.'
                  ]}
                />
              )}
              <FormInputWithLabel
                name="invites"
                label="Contacts"
                type="multiselect"
                placeholder="Contacts"
                multiSelectProps={{
                  options: clients.map((c) => ({
                    value: c._id,
                    label: c.name ?? c.user.name,
                    secondaryLabel: c.user.email
                  })),
                  nullable: true,
                  canAdd: true,
                  onAdd: setNewContactData
                }}
              />
              <FormActionsRow>
                {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
                {!!onSkip && <FormButton onPress={onSkip} text="Skip" type="button" variant="secondary" />}
                {hasEntity && (
                  <FormButton size={!onBack && !onSkip ? 'medium' : undefined} loading={isSubmitting} text="Submit" />
                )}
              </FormActionsRow>
            </Form>
            <RIDialog open={!!newContactData} setOpen={(o) => setNewContactData((d) => (o ? d : ''))} locked>
              <CreateInviteUserContainer
                email={newContactData}
                close={() => setNewContactData('')}
                afterSuccess={(newClient) => {
                  setFieldValue(
                    'invites',
                    hasEntity
                      ? [
                          ...values.invites,
                          {
                            value: newClient._id,
                            label: newClient.name ?? newClient.user.name,
                            secondaryLabel: newClient.user.email
                          }
                        ]
                      : []
                  );

                  onSuccess?.(
                    'Created new contact: ' + (newClient.name ?? newClient.user.name ?? newClient.user.email)
                  );
                  setNewContactData('');
                }}
              />
            </RIDialog>
          </>
        )}
      </Formik>
    </>
  );
};
