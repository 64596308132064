import { useClients } from '../../clients/client.service';
import { ContactsTableNestedContainer } from './contacts-table.nested.container';
import { Loader } from '../../../_core/loader.component';
import { useCurrCompany } from '../../companies/company.service';
import { IAdminProps } from '../../../_core/core.types';
import { useEntitiesMembers } from '../../entity-members/entity-members.service';
import { ITableModeConfig } from '../../../_core/table/table.types';

interface IContactsTableContainer extends IAdminProps {
  modeConfig?: ITableModeConfig;
}

export const ContactsTableContainer: React.FC<IContactsTableContainer> = ({ isAdmin, modeConfig }) => {
  const { clients, loading } = useClients();
  const { isPersonal } = useCurrCompany();
  const { entitiesMembers } = useEntitiesMembers();

  if (!clients) return <Loader />;

  return (
    <ContactsTableNestedContainer
      isAdmin={isAdmin}
      isPersonalCompany={isPersonal}
      clients={clients}
      entitiesWithMembers={entitiesMembers}
      loading={!clients || (!clients.length && loading)}
      modeConfig={modeConfig}
    />
  );
};
