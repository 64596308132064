import { PaperClipIcon } from '@heroicons/react/20/solid';
import { MutableRefObject, PropsWithChildren } from 'react';

import { IAsset, IRequestTokenParams } from '../../../lib/types';
import { showError } from '../../../lib/utils';
import { useUploadAsset } from '../../domains/assets/asset.service';
import { Button, ButtonSize } from '../../_core/button/button.component';
import { EditorSaveResult, SaveProps } from '../_core/types';

interface IUploadInput extends IRequestTokenParams, PropsWithChildren {
  blockId?: string;
  disabled?: boolean;
  multiple?: boolean;
  onSave: (_?: SaveProps) => Promise<EditorSaveResult>;
  onUploaded: (_: IAsset) => void;
  size?: ButtonSize;
  uid: string;
  uploadRef: MutableRefObject<HTMLInputElement | null>;
}

export const UploadInput = ({
  blockId,
  children,
  disabled,
  multiple,
  onSave,
  onUploaded,
  size = 'medium',
  uid,
  requestToken,
  uploadRef
}: IUploadInput) => {
  const { uploadAsset } = useUploadAsset(requestToken);

  const onDrop = (e: DragEvent) => {
    e.preventDefault();
    onAddFiles(e.dataTransfer?.files);
  };

  const onAddFiles = (files?: FileList | null) => {
    if (files) {
      for (let i = 0; i < files.length; i++) {
        uploadAsset(files[i])
          .then(({ data }) => {
            onSave({ forceFetchLatestData: true });
            onUploaded(data.asset);
          })
          .catch((err) => showError('Failed to upload file.', err));
      }
    }
  };

  const inputId = 'upload-input-' + uid;
  return (
    <label
      htmlFor={inputId}
      className={`flex flex-col justify-center w-full ${disabled ? '' : 'cursor-pointer'}`}
      onDrop={(e) => onDrop(e.nativeEvent)}
      onDrag={(e) => e.preventDefault()}
    >
      {children ?? (
        <Button
          hideEndMargin
          className="hover:opacity-80"
          id={inputId}
          icon={<PaperClipIcon width={16} height={16} className="mr-2" />}
          size={size}
          type="button"
          variant="gray"
          onClick={() => uploadRef.current?.click()}
          disabled={disabled}
          text="Upload"
          textClass="text-xs"
        />
      )}
      <input
        accept="application/msword, .xls, .csv, .txt, .pdf, image/*, .heic, .xlsx, .numbers, .pages, .doc, .docx"
        multiple={multiple}
        type="file"
        className={`hidden ${blockId ? 'input-' + blockId : ''}`}
        id={inputId}
        onChange={(e) => onAddFiles(e.target.files)}
        disabled={disabled}
        ref={uploadRef}
      />
    </label>
  );
};
