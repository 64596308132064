import { Identifiable, IInvite, IInviteOverview, INVITE_STATUS, Nameable } from '../../../lib/types';
import { IMultiSelectOption } from '../../_core/input/multiselect-input.component';

export interface IBaseSendInvitesProps {
  department: IMultiSelectOption[];
  location: IMultiSelectOption[];
  roles?: IMultiSelectOption[];
}

export interface ISendClientInvitesProps extends IBaseSendInvitesProps {
  emails: string[];
}

export interface InviteData extends Identifiable, Nameable {}

export interface ISendStaffInvitesProps extends IBaseSendInvitesProps {
  inviteData: IMultiSelectOption[];
}

export interface ISendClientInvite {
  email: string;
  name?: string;
  entity?: IMultiSelectOption[];
}

export interface ISendClientInvitesFormParams {
  invites: IMultiSelectOption[];
}

export interface ICompanyInviteResult {
  invites: IInviteOverview[];
}

export interface ICompanyInviteQueryParams {
  status?: string;
}

export interface IInviteResult {
  invite: IInvite;
}

export interface IInviteQueryParams {
  code: string;
  email?: string;
  name?: string;
  status?: INVITE_STATUS;
}

export enum FetchInviteErrors {
  COMPANY_DELETED = 'Organization has been deleted.',
  DOES_NOT_EXIST = 'Invite does not exist.',
  EXPIRED = 'Invite has expired.'
}
